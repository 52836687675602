import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import TextInput from '../ui/TextInput';
import Button from '../ui/Button';
//import { PWs } from '../data/PWs';
import { Md5 } from 'ts-md5';
import { useStore } from "../stores/Store";
import { observer } from 'mobx-react-lite';
import './../../SASS/LoginForm.scss';
import emailjs from '@emailjs/browser';
import { PW } from '../models/PW';
import ReactGA from 'react-ga4';


interface Props {
    returnLink?: string;
    isModal?: boolean;
}

export default observer(function LoginForm({returnLink=undefined, isModal=true}:Props){
    const {loginStore, modalStore, snackbarStore} = useStore();
    const {setLogin, setIsAdmin} = loginStore;
    const {setModalVariant} = modalStore;
    const {setSnackbar} = snackbarStore;


    const [pw, setPW] = useState<string>("");
    const [pws, setPWs] = useState<PW[]>([]);
    const [isPWError, setIsPWError] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    let userName = "";
    let pwAddedDate = "";

    const getPWs = () =>{
        fetch(
            'pws.json',
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(function(response){
            return response.json();
        }).then(function(pwjson) {
            setPWs(pwjson.PWs);
        });
    }

    useEffect(() => {
        getPWs();
        ReactGA.event({
            category: "login",
            action: 'open login',
          });
    },[])


    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let hashedPW = Md5.hashStr(pw);
        if (pws.find(p => p.what === hashedPW) !== undefined) {
            setLogin(true);
            userName = pws.find(p => p.what === hashedPW)?.who || "";
            pwAddedDate = pws.find(p => p.what === hashedPW)?.when || "";
            if (pws.find(p => p.what === hashedPW)?.isAdmin) {
                setIsAdmin(true);
                handleSuccessfulSubmit(isModal);
                ReactGA.event({
                    category: "login",
                    action: 'login submitted',
                    label: 'is admin',
                  });
            } else {
                setIsAdmin(false);
                
                if (process.env.REACT_APP_EMAILJS_SERVICE_ID != undefined && process.env.REACT_APP_LOGIN_TEMPLATE_ID != undefined) {
                    setIsSending(true);
                    emailjs.send(
                        process.env.REACT_APP_EMAILJS_SERVICE_ID,
                        process.env.REACT_APP_LOGIN_TEMPLATE_ID,
                        {
                            pw: pw,
                            who: userName,
                            when: pwAddedDate
                        },
                        process.env.REACT_APP_EMAIL_KEY
                    ).then(() => {
                        //do something if this is successful!
                        // console.log('Email sent!');
                        handleSuccessfulSubmit(isModal);
                    }, (err) => {
                        //do something if the form couldn't be sent successfully.
                        //console.log(JSON.stringify(err));
                        setIsError(true);
                        setIsSending(false);
                    });
                } else {
                    //do something if they keys couldn't be fetched!
                    //console.log("Keys couldn't be fetched.")
                    setIsError(true);
                }
            }
            ReactGA.event({
                category: "login",
                action: 'login submitted',
                label: 'not admin',
              });
        } else {
            setIsPWError(true);
        }
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {value} = event.target;
        setPW(value);
    }

    function handleCancel(isInModal:boolean) {
        if (isInModal) {
            setModalVariant(undefined)
        }

        ReactGA.event({
            category: "login",
            action: 'cancel login',
          });
        //do something else if the form isn't in a modal
    }

    function handleSuccessfulSubmit(isInModal:boolean) {
        if (isInModal) {
            setModalVariant(undefined)
        } 
        if (userName != "") {
            setSnackbar("success", "Hi, " + userName + "! You've successfully logged in!");
        } else {
            setSnackbar("success", "Hi, you've successfully logged in!");
        }
        setIsSending(false);

        //do something else if the form isn't in a modal
    }

    let loginForm = <></>;

    if (isModal) {
        loginForm = 
            <div className='login-form-modal'>
                <h2 className='type-heading-2 margin-b-2'>Login</h2>
                <p className='type-body margin-b-3'>Please enter the password you were given or contact me to request one.</p>
                {(isError)?(
                    <div className='small-12 margin-b-3'>
                        <div className='form-error-message'>
                            <p className='type-body margin-b-1'>There seems to be a problem under the hood here. Apologies!</p>
                        </div>
                    </div>
                ):(<></>)}
                <form id='LoginForm' noValidate onSubmit={(e) => handleSubmit(e)} autoComplete='off'>
                    <TextInput 
                        id='password' 
                        name='password' 
                        onChange={handleInputChange} 
                        value={pw} 
                        placeholder='Password, please' 
                        label='Password' 
                        isRequired 
                        errorMessage='Please enter a valid password.' 
                        isInvalid={isPWError} 
                        addClasses='margin-b-4'
                        autoFocus
                        />
                    <div className='button-group'>
                        <Button text='Submit' type='submit' variant='filled' isLoading={isSending}/>
                        <Button text='Cancel' type='button' variant='outline' onClick={() => handleCancel(isModal)}/>
                    </div>
                </form>
            </div>
    } else {
        <>
            <h2 className='type-heading-2'>Login</h2>
            <p className='type-body'>Please enter the password you were given or contact me to request one.</p>
            <form id='LoginForm' noValidate onSubmit={handleSubmit} autoComplete='off'>
                <TextInput id='password' name='password' onChange={handleInputChange} placeholder='Password, please' label='Password' isRequired errorMessage='Please enter a valid password.' isInvalid={isPWError} />
                <div className='button-group'>
                    <Button text='Submit' type='submit' variant='filled' isLoading={isSending} />
                    <Button text='Cancel' type='button' variant='outline' onClick={() => handleCancel(isModal)} isLoading={isSending}/>
                </div>
            </form>
        </>
    }
    
    return(loginForm)
})