import React from 'react';
import { Image } from '../models/Image';
import './../../SASS/ImageGrid.scss';
import { useStore } from '../stores/Store';
import { observer } from 'mobx-react-lite';
import ReactGA from 'react-ga4';

interface Props{
    images: Image[] | undefined;
    size: 'small' | 'medium' | 'large' | 'modal-grid';
    notExpandable?:boolean;
    addClasses?: string;
}

export default observer(function ImageGrid({images, size = "small", notExpandable=false, addClasses = ""}:Props){
    const {imageStore, modalStore} = useStore();
    const {selectedImage, setSelectedImage, setImageGroup} = imageStore;
    const {setModalVariant} = modalStore;

    let imgClass = 'image-' + size;

    function handleImageClick (imgKey:string, images:Image[]){
        setImageGroup(images);
        setSelectedImage(imgKey);
        setModalVariant('image');
        ReactGA.event({
            category: "image enlarge",
            action: 'view image',
            label: images?.find(img => img.key === selectedImage)?.imgLarge || "image undefined",
        });
    }

    let classes = "image-grid-container";
    if (addClasses !== "") {
        classes = classes + " " + addClasses;
    }

    if (images === undefined) {
        return(<></>)
    } else {
        return(
            <div className={classes}>
                {images.map(image => (
                    <div 
                    key={image.key}
                    className={imgClass + ((selectedImage === image.key) ? (" is-selected"):((notExpandable) ? (" not-expandable"):(" img-is-clickable")))} 
                    onClick={() => handleImageClick(image.key, images)}>
                        <img key={image.key} src={image.imgThumb} alt={image.imgAltText} />
                    </div>
                ))}
            </div>
        )
    }
    
})