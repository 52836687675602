import React from "react";
import '../../SASS/Footer.scss';
import Button from "../ui/Button";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/Store";
import NLink from "../ui/NLink";

function Footer(){
    const { modalStore, settingsStore } = useStore();
    const { setModalVariant } = modalStore;
    const {mode} = settingsStore;

    function handleContactButtonClick() {
        setModalVariant('contact');
    }

    return (
        <footer className={"footer-nmcd" + ((mode === 'darkmode')?(" dark-mode"):(""))}>
            <p className="type-body copywrite-text">Designed & built by Nick @ NMChivers Design © All Rights Reserved.</p>
            <div className="footer-button-group">
                <Button text='Contact me' variant='hollow' icon='bi-envelope-at' iconOnly onClick={() => handleContactButtonClick()} />
                <NLink variant="linkedin" content="Connect on LinkedIn" to={'https://www.linkedin.com/in/nmchivers/'}/>
                {/* <Button text='View my LinkedIn' variant='hollow' icon='bi-linkedin' iconOnly /> */}
            </div>
        </footer>
    )
}

export default observer(Footer);