import React, { ReactNode } from 'react';
import './../../SASS/NGrid.scss';

interface GridProps{
    direction: "row" | "column";
    hasGutters?: boolean;
    addClasses?:string;
    children: ReactNode | ReactNode[];
}

export function NGrid({direction = "row", hasGutters = true, addClasses = "", children}:GridProps){
    let gridContainer = <></>;
    if (direction = "row") {
        gridContainer = 
        <div className={'nmcd-grid-row' + ((hasGutters)?("-has-gutters " + addClasses):(" " + addClasses))}>
            {children}
        </div>
    } else {
        gridContainer = 
        <div className={'nmcd-grid-column' + ((hasGutters)?("-has-gutters " + addClasses):(" " + addClasses))}>
            {children}
        </div>
    }

    return(gridContainer)
}

interface CellProps {
    small?:"1"|"2"|"3"|"4"|"5"|"6"|"7"|"8"|"9"|"10"|"11"|"12"|"hide"|"hide-up"|"hide-down"|"";
    medium?:"1"|"2"|"3"|"4"|"5"|"6"|"7"|"8"|"9"|"10"|"11"|"12"|"hide"|"hide-up"|"hide-down"|"";
    large?:"1"|"2"|"3"|"4"|"5"|"6"|"7"|"8"|"9"|"10"|"11"|"12"|"hide"|"hide-up"|"hide-down"|"";
    addClasses?:string;
    children: ReactNode | ReactNode[];
}

export function NGridCell({small = "", medium = "", large = "", addClasses = "", children}:CellProps){
    let gridCell = <></>;
    let cellClasses = "";

    if (small !== "") {
        if (small === "hide") {
            cellClasses = cellClasses + " hide-at-small";
        } else {
            cellClasses = cellClasses + " ngrid-small-" + small;
        }  
    }
    if (medium !== "") {
        if (medium === "hide") {
            cellClasses = cellClasses + " hide-at-medium";
        } else {
            cellClasses = cellClasses + " ngrid-small-" + medium;
        }  
    }
    if (large !== "") {
        if (large === "hide") {
            cellClasses = cellClasses + " hide-at-large";
        } else {
            cellClasses = cellClasses + " ngrid-small-" + large;
        }  
    }

    if (addClasses !== "") {
        
    }
    gridCell = 
    <div className={addClasses}>
        {children}
    </div>

    return(gridCell)
}