import React, { InputHTMLAttributes, useState } from 'react';
import './../../SASS/Inputs.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id:string;
    placeholder:string;
    label?:string;
    icon?:string;
    iconPosition?: "left" | "right";
    isRequired?:boolean;
    errorMessage?:string;
    isInvalid?:boolean;
    addClasses?:string;
}

export default function TextInput({id, placeholder=" ", label="", icon="", iconPosition, isInvalid=false, addClasses="", isRequired=true, errorMessage = "", ...props}: Props){
    let validationClasses = "";
    if (isInvalid) {
        validationClasses = " error-state";
    }

    let labelElement = <></>;
    if (label !== "" && !isRequired) {
        labelElement = <label className={'input-text-label' + validationClasses} htmlFor={id}>{label}<span>(optional)</span></label>;
    } else if (label !== "") {
        labelElement = <label className={'input-text-label' + validationClasses} htmlFor={id}>{label}</label>;
    }

    let errorMessageElement = <></>;
    if (errorMessage !== "") {
        errorMessageElement = <p className={'input-text-errormessage'+ validationClasses}>{errorMessage}</p>;
    }

    if (addClasses !== "") {
        addClasses = " " + addClasses;
    }

    return(
        <div className={'input-text-container' + addClasses}>
            {labelElement}
            <input className={validationClasses} id={id} type='text' placeholder={placeholder} {...props} />
            {errorMessageElement}
        </div>
    )
}