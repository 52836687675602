import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import MedAdherenceImages from '../../data/projects/MedAdherenceImages';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alert from '../../ui/Alert';

export default observer(function MedAdherenceProjectPage(){
    const {loginStore, snackbarStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setSnackbar} = snackbarStore;
    const navigate = useNavigate();
    const {settingsStore} = useStore();
    const {contentLevel} = settingsStore;

    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'medicationadherence')


    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        if (projectinfo?.requiresLogin) {
            if (isLoggedIn) {
                window.scrollTo(0, 0);
                handleContentSections();
            } else {
                navigate('/work');
                setSnackbar('error', "You need to be logged in to view that case study.")
            }
        } else {
            window.scrollTo(0, 0);
            handleContentSections();
        }
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/MedAdherence/MedAdherenceHero.webp" />
            </Helmet>
            {(contentLevel === 'summary')?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(
                <></>
            )}
            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>

            <div className='grid-x-has-gutters'>
                <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                    <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                            <h2 className='type-heading-2 margin-b-2'>Background</h2>
                            <p className='type-body'>Within Rally’s user population exists a set of users that take medication on a regular basis to manage chronic conditions such as HIV and Diabetes. It is common knowledge that taking the medications prescribed for these conditions as directed drastically increases the user’s chances of living a long, healthy and happy life. However, there are several barriers that can impede this adherence such as the cost of medication, side effects, and remembering to take the medication.</p>
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                            <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                            <p className='type-body'>We wanted to create an activity that would reward the user for taking the medication to manage their chronic condition as prescribed. This would mainly focus on reminding the user to take their medication, enabling them to check-in once they had taken their medication, and providing them a reward for staying on track.</p>
                        </div>

                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE">
                            <h2 className='type-heading-2 margin-b-2'>User Flow Design</h2>
                            <p className='type-body margin-b-3'>In breaking down the user flow for this activity we had to first figure out how we would know that a user had a chronic condition since we only wanted to offer this activity to those users initially. The Product Manager determined we would do this based on the user’s claims data and the medications found within it. The challenge here though is that claims data can be up to a month out of date. So, for example, if a user is prescribed a medication for a new chronic condition, we wouldn’t know to provide them this new activity until possibly a month later. Another challenge this raised was around changes in a user’s medication regimen. If a user with an existing chronic condition was placed on a new or different medication by their healthcare provider, the claims data would not reflect that change for up to a month later.</p>
                            <p className='type-body'>For users with new chronic conditions the Product Manager assumed the risk of missing that first month was acceptable. To address the challenge presented by user’s with new or different medications I designed an onboarding workflow to not only ensure we were enabling them to track the right prescriptions but to set up reminders and gather additional information about any impediments they might be facing with regards to adhering to their medication regimen. The idea being that we could then create additional activities later on that would address those impediments. Further empowering them to stay on track with their health.</p>
                        </div>

                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframes">
                            <h2 className='type-heading-2 margin-b-2'>Wireframes</h2>
                            <p className='type-body margin-b-4'>Since this was a brand-new feature we were creating at a time when the company didn’t have a defined design system in place, I began with wireframes. I feel this approach allows your reviewers to focus on the information being conveyed and the structure of the layout rather than focusing in on color usage and if they prefer one color over another.</p>
                            <MedAdherenceImages imageGroup='wireframes1' />
                            <p className='type-body margin-t-4'>As this was one of my first projects at Rally, I quickly learned that while this activity would be new to the platform, the interaction pattern of checking-in, tracking progress, and earning rewards was not new. This provided an opportunity to revise and standardize this pattern across the other activities on the platform that utilized it. A quick fix would have been to simply leverage what those other activities did to facilitate this pattern.  However, I noticed that each activity handled it a bit differently and there was no one solution that could scale to handle them all in a clean and forward-looking manner.</p>
                        </div>

                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                            <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                            <p className='type-body margin-b-4'>Based on the timeline of this project, I had to quickly move on to creating high fidelity mockups of the ideal solution. This included working with various other designers on the team to understand the design language of the platform as I was still new to the company and a robust design system was still at least a year away. I digested all of their provided knowledge and got to work bringing my wireframes to life.</p>
                            <MedAdherenceImages imageGroup='visd1' />
                            <p className='type-body margin-b-4 margin-t-4'>During this phase I was able to begin the work of testing the design against the other activities on the platform that utilized a similar check-in and track pattern.</p>
                            <MedAdherenceImages imageGroup='visd2' />
                            <p className='type-body margin-b-4 margin-t-4'>Eventually though it was decided by the creative director for the engagement platform that we wanted to go in a different direction. Designs for another section of the platform focused on pooling content on the page into modular cards. This would allow for pieces of the design to then be reused in other designs. With this in mind I created the following designs.</p>
                            <MedAdherenceImages imageGroup='visd3' />
                        </div>

                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Next Steps">
                            <h2 className='type-heading-2 margin-b-2'>Next Steps</h2>
                            <p className='type-body'>At this point in the process the project was placed on hold in order to focus on immediate changes as a result of the Covid-19 pandemic. As 2021 planning kicked off though, this project was slated to start back up in the second half of the year! Stay tuned!</p>
                        </div>
                    </div>
                </div>
                <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                    <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                </div>
            </div>

            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE"></div>
//<h2 className='type-heading-2 margin-b-2'></h2>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />