import { RouteObject, createBrowserRouter } from "react-router-dom";
import App from "../layout/App";
import Home from "../pages/Home";
import MyWork from "../pages/MyWork";
import NMCDDesignSystem from "../pages/NMCDDesignSystem";
import AboutMe from "../pages/AboutMe";
import Pipeline from "../pages/projects/Pipeline";
import SyncUps from "../pages/projects/SyncUps";
import Choice from "../pages/projects/Choice";
import MedAdherence from "../pages/projects/MedAdherence";
import InsuranceShopping from "../pages/projects/InsuranceShopping";
import MarketingSiteBCBS from "../pages/projects/MarketingSiteBCBS";
import SocialRetailApp from "../pages/projects/SocialRetailApp";
import DesignSystemHCP from "../pages/projects/DesignSystemHCP";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {path: '', element: <Home />},
            {path: '/work', element: <MyWork />},
            {path: '/nmcd-designsystem', element: <NMCDDesignSystem />},
            {path: '/nick', element: <AboutMe />},
            {path: '/work/pipeline', element: <Pipeline />},
            {path: '/work/sync-ups', element: <SyncUps />},
            {path: '/work/choice', element: <Choice />},
            {path: '/work/medication-adherence', element: <MedAdherence />},
            {path: '/work/insurance-shopping', element: <InsuranceShopping />},
            {path: '/work/marketing-site-bcbs', element: <MarketingSiteBCBS />},
            {path: '/work/social-retail-app', element: <SocialRetailApp />},
            {path: '/work/design-system-hcp', element: <DesignSystemHCP />},
            //add additional routes here
        ]
    }
]

export const router = createBrowserRouter(routes);