import { Recommendation } from "../models/Recommendation";

export const Recommendations: Recommendation[] = [
    {
        key: 'acosta',
        recText:'“... Nick is also an excellent mentor. He was always available to answer my questions and provide me with feedback, and he helped me to grow as a product designer. Nick is great team player, and he is always willing to help out his colleagues.”',
        name:'Santiago Acosta',
        role:'Product Designer at HouseCall Pro',
        relationship:'Santi reported directly to me.',
        url:'https://www.linkedin.com/in/nmchivers/details/recommendations/?detailScreenTabIndex=0',
        imageURL:'./IMGS/Recommendations/SAcosta.webp',
    },
    {
        key: 'scronce',
        recText:'“Nick is a skilled designer and developer with a niche for solving problems. He brings a fresh perspective to projects with an experienced eye for detail...”',
        name:'Evan Scronce',
        role:'Founder at VIOLET',
        relationship:'Evan was my manager on multiple, high complexity projects',
        url:'https://www.linkedin.com/in/nmchivers/details/recommendations/?detailScreenTabIndex=0',
        imageURL:'./IMGS/Recommendations/EScronce.webp',
    },
    {
        key: 'lochhead',
        recText:'“Nick is an exceptional designer and design mentor who positively impacts not only his direct team but company culture at large. He is able to untangle and find structure in complex user problems while clearly communicating key decisions and rational along the way...”',
        name:'Amelia Lochhead',
        role:'Product Designer at HouseCall Pro',
        relationship:'I was senior to Amelia but didn’t manage them directly.',
        url:'https://www.linkedin.com/in/nmchivers/details/recommendations/?detailScreenTabIndex=0',
        imageURL:'./IMGS/Recommendations/ALochhead.webp',
    },
    {
        key: 'hahn',
        recText:'"... While he is skilled at managing teams and concepts through the development process, it is his ability to connect all of the moving parts to execute on a vision that drives exceptional value. His design approach and work product reflect this depth, agility, and commitment."',
        name:'Jordan Hahn',
        role:'Director of Product Management at Azavar Technologies',
        relationship:'Jordan and I worked projects together.',
        url:'https://www.linkedin.com/in/nmchivers/details/recommendations/?detailScreenTabIndex=0',
        imageURL:'./IMGS/Recommendations/JHahn.webp',
    },
]