import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import ChoiceImages from '../../data/projects/ChoiceImages';
import { useStore } from '../../stores/Store';
import Alert from '../../ui/Alert';

export default observer(function ChoiceProjectPage(){
    const {settingsStore} = useStore();
    const {contentLevel} = settingsStore;
    
    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'choicerewards')

    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleContentSections();
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/ChoiceRewards/ChoiceHero.webp" />
            </Helmet>
            {((contentLevel === 'summary') && (projectinfo.hasSummary === false))?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(
                <></>
            )}
            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>

            {(contentLevel === 'summary')?(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>The users of the WeRally platform are able to complete healthy activities to earn coins, gift card credits, and various other reward currencies based on what their employer has elected to offer them. However, they’ve been limited in what they can redeem those currencies for. Additionally, each currency had a different marketplace experience for the user to interact with. This created confusion for the user as most employers offered at least two currencies, coins and gift card credits. The focus of this effort was to create a new redemption experience that will support today’s redemption offerings in one place while enabling future offerings.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>WeRally user’s often have multiple currencies available to them in the platform for completing various health based activities. (Coins, gift card credits, HSA dollars, etc.)</li>
                                    <li>Each currency had a different redemption experience that offered different types of rewards which led to user confusion and frustration.</li>
                                    <li>According to our NPS surveys, users also wanted more concrete rewards options which created the challenge of determining which redemption experience to place them on.</li>
                                    <li>Our initial MVP for the Choice Rewards experience focused on enabling the user to earn a singular currency simply called “rewards dollars” and allowing them to spend it on gift cards, HSA contributions, or a Health Plan Premium Reduction/Discount.</li>
                                    <li>I started by creating user flow diagrams for the various requirements my Product Manager had defined in order to get clarity on several aspects of the experience before I began creating designs.</li>
                                    <li>This project was designated as an early adopter of the new design system that was being created.</li>
                                    <li>I created high fidelity designs for all aspects of the experience at large and small/mobile breakpoints to ensure user’s had the best experience regardless of the device they connected to us with.</li>
                                    <li>I discovered and proposed a modification to the new design system’s color palette to correct a potential accessibility issue and ensure we met AA level requirements under the WCAG.</li>
                                    <li>I helped plan user testing that was executed by our research team and implemented changes based on their findings.</li>
                                    <li>I planned and executed additional usability testing which was not typically done by my team in order to meet tight timelines.</li>
                                    <li>I worked with my product manager and lead engineer to get access to a staging environment so that I could test all front-end code and document any deviations from the approved designs.</li>
                                    <li>On 1/1/2021, this brand-new redemption marketplace experience went live on WeRally.com and started showing value with users exploring and redeeming almost immediately.</li>
                                </ul>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="User Flow Design">
                                <h2 className='type-heading-2 margin-b-2'>User Flow Design</h2>
                                <ChoiceImages imageGroup="alluserflow" />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <ChoiceImages imageGroup="allvisd" />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA">
                                <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                                <ChoiceImages imageGroup="allqa" />
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>The users of the WeRally platform are able to complete healthy activities to earn coins, gift card credits, and various other reward currencies based on what their employer has elected to offer them. However, they’ve been limited in what they can redeem those currencies for. Additionally, each currency had a different marketplace experience for the user to interact with. This created confusion for the user as most employers offered at least two currencies, coins and gift card credits. The focus of this effort was to create a new redemption experience that will support today’s redemption offerings in one place while enabling future offerings.</p>
                            </div>
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                            <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                            <p className='type-body margin-b-3'>On the WeRally platform, user’s have the ability to complete healthy activities, such as getting a flu shot, to earn rewards. This is all based on what a user’s employer has elected to offer them. Those rewards earned are usually a type of currency such as coins, gift card credits, HSA dollars, etc. What the user can spend those on has historically depended on the currency themselves. Coins could only be redeemed in one marketplace experience for discount codes while gift card credits could only be redeemed in a different marketplace experience for a gift card. Since most employers offer at least coins and gift card credits, users had to interact with different redemption experiences for each currency within the same platform.</p>
                            <p className='type-body margin-b-3'>From our NPS surveys, we learned that our users also wanted more concrete reward options. Discount codes were nice but being able to redeem their coins for a product directly would be better. Even better than that would be to provide multiple options such as products, memberships, and direct deposits of cash. Essentially, they wanted choices.</p>
                            <p className='type-body margin-b-3'>Our challenge was set then. We needed to create the marketplace of the future. One marketplace that could accommodate multiple currencies (coins, gift card credits, etc.) and multiple reward types (gift cards, discounts, products, etc). To do this though we needed to narrow the scope a bit and then plan future iterations to get to that end state.</p>
                            <p className='type-body'>For our initial MVP, we narrowed our focus to enabling the user to earn a singular currency simply called “rewards dollars” and allowing them to spend them on gift cards, HSA contributions, or a Health Plan Premium Reduction/Discount. This would enable us to create a new, singular currency that would later replace existing currencies and set up a marketplace to spend them in which would replace the various other marketplace experiences on the platform as we moved clients off of them in time.</p>
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="User Flow Design">
                            <h2 className='type-heading-2 margin-b-2'>User Flow Design</h2>
                            <p className='type-body margin-b-4'>With this more focused scope, I set about creating the user workflows. I used Lucid Chart for this and shared the workflows with my Product Managers. This allowed me to have more meaningful conversations about steps in the process with them before diving into pixels on the page. Ultimately, getting answers to my questions at this stage saved me time later in the process.</p>
                            <ChoiceImages imageGroup="userflow1" />
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                            <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                            <p className='type-body margin-b-3'>As I was beginning to do the design for this project, the company was starting to roll out pieces of the new design system. At this early stage of the design system though it only included typography, colors, buttons, and a few other odds and ends. Because this new rewards system was set to go live on 1/1/2021, the decision was made that this project would be an early adopter of the new design system and one of the first to implement it.</p>
                            <p className='type-body margin-b-4'>Implementing a new design system that was still in the process of being designed was not a smooth process. It wasn’t until most of the rewards solution was designed and approved that the design system team even became part of the design approval process. Further, there were a vast array of elements in my designs that were not even on the design system’s roadmap for another 6+ months. To best handle this situation I used what was available and worked with other early adopters to agree certain patterns that we would follow with the hopes that these patterns would later become part of the design system. With this approach agreed among the early adopters, the design system team, and team leadership, I created high fidelity designs for the entire redemption experience at both large and small/mobile breakpoints.</p>
                            <ChoiceImages imageGroup="visd1" />
                            <p className='type-body margin-t-4'>As I worked on these designs, I noticed some aspects of the design system needed modification. The perk of being an early adopter meant that I had the opportunity to work closely with the system creators. Some aspects of the design system I even directly contributed to, such as the color system. The initial color palette the design system team had defined created some potential accessibility issues. To address this I created a full accessibility assessment of the color palette and proposed modifications to ensure we met AA level requirements under the WCAG. My proposal was accepted and the color palette was updated.</p>
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Usability Testing">
                            <h2 className='type-heading-2 margin-b-2'>Usability Testing</h2>
                            <p className='type-body margin-b-3'>As this was a brand-new experience within the Rally platform, and one that would scale and grow over time, I wanted to make doubly sure that the designs were tested rigorously. The Product Manager and I worked with our user testing team early in the process to create a detailed testing plan that they would execute. This included walking through the experience to complete certain tasks, responding to various verbiage choices, and providing feedback on design decisions.</p>
                            <p className='type-body'>The output of this testing provided direction on a number of changes that I worked diligently to implement. However, throughout the course of making these updates and adjusting certain elements of the design, I noticed a few areas I wanted to run new tests on. The challenge was that the research team had a lengthy backlog that would push my tests out weeks. I had experience planning and executing my own usability studies but my team didn’t usually run our own tests. I discussed it with my manager and they gave me special permission to run a few new tests on my own. Using User Zoom, I created the tests, ran them, and reviewed the data. I consulted with another designer to mitigate any potential biases I might be reading into the results and made decisions from there.</p>
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA">
                            <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                            <p className='type-body margin-b-4'>Once the development of the experience was underway I was anxious to participate in QA’ing the experience. This was another aspect of the project execution process my team didn’t typically get involved in though. I, however, worked with the Product Manager and the lead engineer to get credentials to a staging environment. Then, every time a developer completed work on a front end ticket, they would ping me to let me know it was ready for review. I then reviewed what had been done and documented any and all deviations from the approved design. I compiled all of these into a document that I supplied to the Product Manager who went about creating the necessary bug tickets for development to take action on.</p>
                            <ChoiceImages imageGroup="qa1" />
                        </div>
                        <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Launch">
                            <h2 className='type-heading-2 margin-b-2'>Launch</h2>
                            <p className='type-body'>On 1/1/2021, this brand-new redemption marketplace experience went live on WeRally.com! While not everyone has access to the experience, since it is based on what a user’s employer has chosen to offer them, those that have access to it have responded positively! Not only that, but we can see from tracking metrics that users were almost immediately exploring the experience and starting to redeem rewards!</p>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>
            )}

            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTIONTITLE"></div>
//<h2 className='type-heading-2 margin-b-2'></h2>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />