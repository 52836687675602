import { createContext, useContext } from "react";
import ImageStore from "./ImageStore";
import ViewStore from "./ViewStore";
import NavStore from "./NavStore";
import ModalStore from "./ModalStore";
import LoginStore from "./LoginStore";
import BrainStore from "./BrainStore";
import SnackbarStore from "./SnackbarStore";
import SettingsStore from "./SettingsStore";

interface Store {
    imageStore: ImageStore;
    viewStore: ViewStore;
    navStore: NavStore;
    modalStore: ModalStore;
    loginStore: LoginStore;
    brainStore: BrainStore;
    snackbarStore: SnackbarStore;
    settingsStore: SettingsStore;
}

export const store: Store = {
    imageStore: new ImageStore(),
    viewStore: new ViewStore(),
    navStore: new NavStore(),
    modalStore: new ModalStore(),
    loginStore: new LoginStore(),
    brainStore: new BrainStore(),
    snackbarStore: new SnackbarStore(),
    settingsStore: new SettingsStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}