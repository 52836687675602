import { makeAutoObservable } from "mobx";
import { Image } from "../models/Image";

export default class ImageStore {
    selectedImage: string | undefined = undefined;
    imageGroup: Image[] | undefined = undefined;

    constructor() {
        makeAutoObservable(this)
    }

    setSelectedImage = (imgKey:string) => {
        this.selectedImage = this.imageGroup?.find(img => img.key === imgKey)?.key;
    }

    cancelSelectedImage = () => {
        this.selectedImage = undefined;
    }

    setImageGroup = (imgGroup:Image[]) => {
        this.imageGroup = imgGroup;
    }

    cancelImageGroup = () => {
        this.imageGroup = undefined;
    }
}