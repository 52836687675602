import { ProjectInfo } from "../models/ProjectInfo";

export const AllProjects: ProjectInfo[] = [
  {
    key: "dshcp",
    title: "Design System for Housecall Pro",
    company: "Housecall Pro",
    product: "Design System",
    category: "Design Systems",
    blurb:
      "How I led the design of the new Housecall Pro Design System that matured the company’s design aesthetic, addressed key usability and accessibility issues, and greatly improved the design to engineering connection.",
    pageUrl: "/work/design-system-hcp",
    pageDesc:
      "Case Study: How I led the design of the new Housecall Pro Design System that matured the company’s design aesthetic, addressed key usability and accessibility issues, and greatly improved the design to engineering connection.",
    heroImg: {
      key: "dshcp",
      imgThumb: "./IMGS/Projects/HCPDesignSystem/HCPDesignSystemHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/HCPDesignSystem/HCPDesignSystemHero@2x.webp",
      imgAltText: "Design System for Housecall Pro",
    },
    myRoles: "Lead Product Designer & Manager",
    tools: "Figma, Miro, Google Sheets, Google Forms",
    techniques:
      "Competitive Research, Visual Design, Design Documentation, Design QA",
    color: "blue",
    hasSummary: true,
    requiresLogin: false,
    featured: true,
  },
  {
    key: "pipeline",
    title: "Pipeline for Housecall Pro",
    company: "Housecall Pro",
    product: "Pipeline",
    category: "New Product/Feature Design",
    blurb:
      "How I designed an integrated workflow management tool for Housecall Pro that enabled users to immediately capture lost revenue and delivered over $1.9 million in annual recurring revenue for the enterprise.",
    pageUrl: "/work/pipeline",
    pageDesc:
      "Case Study: How I designed an integrated workflow management tool for Housecall Pro that enabled users to immediately capture lost revenue and delivered over $1.9 million in annual recurring revenue for the enterprise.",
    heroImg: {
      key: "pipeline",
      imgThumb: "./IMGS/Projects/HCPPipeline/PipelineHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/HCPPipeline/PipelineHero@2x.webp",
      imgAltText: "Pipeline for Housecall Pro",
    },
    externalURL: "https://www.housecallpro.com/features/pipeline/",
    myRoles: "Senior Product Designer",
    tools: "Figma, Maze, Zoom, Miro",
    techniques:
      "Competitive Research, User Flow Design, Wireframing, Information Architecture Design, Visual Design Definition, Usability Testing, Design QA",
    color: "green",
    hasSummary: true,
    requiresLogin: false,
    featured: true,
  },
  {
    key: "syncups",
    title: "Sync-Ups for Kazoo HR",
    company: "Kazoo HR (now WorkTango)",
    product: "Sync-Ups",
    category: "Feature Redesign & New Feature Design",
    blurb:
      "How I redesigned an underperforming meeting management tool for Kazoo HR (now WorkTango), incorporated new features, and closed critical usability and accessibility issues to exceed user expectations.",
    pageUrl: "/work/sync-ups",
    pageDesc:
      "Case Study: How I redesigned an underperforming meeting management tool for Kazoo HR (now WorkTango), incorporated new features, and closed critical usability and accessibility issues to exceed user expectations.",
    heroImg: {
      key: "suncups",
      imgThumb: "./IMGS/Projects/SyncUps/SyncUpsHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/SyncUps/SyncUpsHero@2x.webp",
      imgAltText: "Sync-Ups for Kazoo HR",
    },
    myRoles: "Senior Product Designer",
    tools: "Figma, Zoom",
    techniques:
      "User Flow Design, Information Architecture Design, Visual Design Definition, Usability Testing, Design QA",
    color: "purple",
    hasSummary: true,
    requiresLogin: false,
    featured: true,
  },
  {
    key: "choicerewards",
    title: "Choice Rewards for Rally Health",
    company: "Rally Health, a subsidiary of UnitedHealthcare",
    product: "Choice Rewards",
    category: "New Product/Feature Design",
    blurb:
      "How I designed a consolidated marketplace experience for Rally Health, a subsidiary of UnitedHealth Group, that enabled users to redeem points earned for healthy activities in an easy-to-use experience.",
    pageUrl: "/work/choice",
    pageDesc:
      "Case Study: How I designed a consolidated marketplace experience for Rally Health, a subsidiary of UnitedHealth Group, that enabled users to redeem points earned for healthy activities in an easy-to-use experience.",
    heroImg: {
      key: "choicerewards",
      imgThumb: "./IMGS/Projects/ChoiceRewards/ChoiceHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/ChoiceRewards/ChoiceHero@2x.webp",
      imgAltText: "Choice Rewards for Rally Health",
    },
    myRoles: "Product Designer",
    tools: "Sketch, InVision, User Zoom, Lucid Chart",
    techniques:
      "User Flow Design, Information Architecture Design, Visual Design Definition, Usability Testing, Design QA",
    color: "blue",
    hasSummary: true,
    requiresLogin: false,
    featured: true,
  },
  {
    key: "medicationadherence",
    title: "Medication Adherence Activity for Rally Health",
    company: "Rally Health, a subsidiary of UnitedHealthcare",
    product: "Medication Adherence Activity",
    category: "New Product/Feature Design",
    blurb:
      "How I designed an engaging customer facing experience to help those that take daily medications track their adherence and receive rewards in this Medication Adherence Rewards Activity designed for Rally Health, a subsidiary of UnitedHealth Group.",
    pageUrl: "/work/medication-adherence",
    pageDesc:
      "Case Study: How I designed an engaging customer facing experience to help those that take daily medications track their adherence and receive rewards in this Medication Adherence Rewards Activity designed for Rally Health, a subsidiary of UnitedHealth Group.",
    heroImg: {
      key: "medicationadherence",
      imgThumb: "./IMGS/Projects/MedAdherence/MedAdherenceHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/MedAdherence/MedAdherenceHero@2x.webp",
      imgAltText: "Medication Adherence Activity for Rally Health",
    },
    myRoles: "Product Designer",
    tools: "Sketch, InVision, Lucid Chart",
    techniques:
      "Wireframing, User Flow Design, Information Architecture Design, Visual Design Definition",
    color: "orange",
    hasSummary: false,
    requiresLogin: true,
    featured: false,
  },
  {
    key: "insuranceshopping",
    title: "Insurance Shopping Experience for BlueCross BlueShield",
    company: "BlueCross BlueShield of IL, TX, NM, MT, OK (HCSC)",
    product: "Health Insurance Retail Experience",
    category: "Retail Redesign",
    blurb:
      "How the team and I redesigned the B2C retail experience to help consumers learn about and purchase the right insurance plan for them from Blue Cross Blue Shield of IL, NM, TX, OK, and MT.",
    pageUrl: "/work/insurance-shopping",
    pageDesc:
      "Case Study: How the team and I redesigned the B2C retail experience to help consumers learn about and purchase the right insurance plan for them from Blue Cross Blue Shield of IL, NM, TX, OK, and MT.",
    heroImg: {
      key: "insuranceshopping",
      imgThumb:
        "./IMGS/Projects/BCBSInsShopExp/InsuranceShoppingHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/BCBSInsShopExp/InsuranceShoppingHero@2x.webp",
      imgAltText: "Insurance Shopping Experience for BlueCross BlueShield",
    },
    myRoles: "Product Designer",
    tools: "Sketch, InVision",
    techniques:
      "Information Architecture Design, User Flow Design, Wireframing, Visual Design Definition, Interaction Design",
    color: "red",
    hasSummary: true,
    requiresLogin: true,
    featured: false,
  },
  {
    key: "marketingsitebcbs",
    title: "Marketing and Information Site Redesign for BlueCross BlueShield",
    company: "BlueCross BlueShield of IL, TX, NM, MT, OK (HCSC)",
    product: "Health Insurance Marketing Site",
    category: "Marketing Site Design",
    blurb:
      "How the team and I redesigned the public facing health insurance marketing experience for Blue Cross Blue Shield of IL, NM, TX, OK, and MT ahead of open enrollment.",
    pageUrl: "/work/marketing-site-bcbs",
    pageDesc:
      "Case Study: How the team and I redesigned the public facing health insurance marketing experience for Blue Cross Blue Shield of IL, NM, TX, OK, and MT ahead of open enrollment.",
    heroImg: {
      key: "marketingsitebcbs",
      imgThumb:
        "./IMGS/Projects/BCBSPublicSite/InsuranceMarketingHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/BCBSPublicSite/InsuranceMarketingHero@2x.webp",
      imgAltText:
        "Marketing and Information Site Redesign for BlueCross BlueShield",
    },
    externalURL: "https://bcbsil.com",
    myRoles: "UX Designer",
    tools: "Sketch, InVision",
    techniques:
      "Design Research, User Flow Definition, Wireframing, Prototyping, Font-End Development",
    color: "yellow",
    hasSummary: true,
    requiresLogin: false,
    featured: false,
  },
  {
    key: "socialshopping",
    title: "Social Shopping App for Mavely",
    company: "My Favorite Things",
    product: "Mavely Android & iOS App",
    category: "Android & iOS App Design",
    blurb:
      "How I helped design a brand new Android & iOS app geared at rewarding users for partnering with the brands and products they already know and love while discovering new ones called Mavely.",
    pageUrl: "/work/social-retail-app",
    pageDesc:
      "Case study: How I helped design a brand new Android & iOS app geared at rewarding users for partnering with the brands and products they already know and love while discovering new ones called Mavely.",
    heroImg: {
      key: "socialshopping",
      imgThumb: "./IMGS/Projects/Mavely/MavelyHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/Mavely/MavelyHero@2x.webp",
      imgAltText: "Social Shopping App for Mavely",
    },
    externalURL: "https://joinmavely.com",
    myRoles: "Product Designer",
    tools: "UXPin, Adobe XD, Excel",
    techniques:
      "Requirements Definition, User Flow Design, Wireframing, Low-Fi Prototyping, Visual Design, High-Fi Prototyping, Usability Testing",
    color: "purple",
    hasSummary: true,
    requiresLogin: false,
    featured: false,
  },
  // {
  //   key: 'mosaic',
  //   title: 'Mosaic Marketing Site for Monotype',
  //   company: 'Monotype',
  //   product: 'Mosaic Marketing Site',
  //   category: 'Marketing Site Design',
  //   blurb: 'An industry leading digital typesetting and typeface design company, Monotype, recently launched a new feature to their Mosaic application and needed a site designed to showcase this new feature.',
  //   pageUrl: "",
  //   pageDesc:'An industry leading digital typesetting and typeface design company, Monotype, recently launched a new feature to their Mosaic application and needed a site designed to showcase this new feature.',
  //   heroImg: {
  //       key: 'mosaic',
  //       imgThumb: './IMGS/Projects/Mosaic/MosaicHero@0.5x.webp',
  //       imgLarge: './IMGS/Projects/Mosaic/MosaicHero@2x.webp',
  //       imgAltText: 'Mosaic Marketing Site for Monotype'
  //   },
  //   myRoles: 'UX Designer, UI Designer, Web Developer',
  //   tools: 'Sketch, Visual Studio, Zurb Foundation, JQuery',
  //   techniques: 'Wireframing, Visual Design Definition, Front-End Development',
  //   color: 'blue',
  //   hasSummary: false,
  //   requiresLogin: false,
  //   featured: false
  // },
  {
    key: "stamp",
    title: "STAMP Reportal",
    company: "Stonegate Advisors",
    product: "Strategic Account Management Platform (STAMP)",
    category: "Product Redesign",
    blurb:
      "As soon as I took the role as UX Designer and Front-End Developer at Stonegate, I was introduced to the existing STAMP application. My main focus in this role would be to completely redesign this application starting with the reporting produced by the application.",
    pageUrl: "",
    pageDesc:
      "As soon as I took the role as UX Designer and Front-End Developer at Stonegate, I was introduced to the existing STAMP application. My main focus in this role would be to completely redesign this application starting with the reporting produced by the application.",
    heroImg: {
      key: "stamp",
      imgThumb: "./IMGS/Projects/STAMP/STAMPHero@0.5x.webp",
      imgLarge: "./IMGS/Projects/STAMP/STAMPHero@2x.webp",
      imgAltText: "STAMP Reportal",
    },
    externalURL: "https://stampcsm.com/",
    myRoles: "UX Designer, UI Designer, Front-End Web Developer",
    tools:
      "Type Form, Excel, PowerPoint, XMind, Sketch, Adobe Illustrator, UXPin, Visual Studios",
    techniques:
      "Generative Research, Requirements Definition, User Flow Creation, Site Map Creation, Wireframing, Low-Fi Prototyping, Usability Testing, Visual Design Definition, Preference Testing, High-Fi Prototyping, Front-End Development, ASP.Net Web Forms Development (C#), QA Testing",
    color: "red",
    hasSummary: true,
    requiresLogin: false,
    featured: false,
  },
  {
    key: "guestpointer",
    title: "GuestPointer",
    company: "GuestPointer, LLC",
    product: "GuestPointer Web App",
    category: "New Product Design",
    blurb:
      "This project was a joint effort between Jordan Hahn, an excellent product manager with years of experience in the hospitality industry, and me. I lead the effort to research, design, and eventually develop the GuestPointer application.",
    pageUrl: "",
    pageDesc:
      "This project was a joint effort between Jordan Hahn, an excellent product manager with years of experience in the hospitality industry, and me. I lead the effort to research, design, and eventually develop the GuestPointer application.",
    heroImg: {
      key: "guestpointer",
      imgThumb: "./IMGS/Projects/GuestPointer/HeaderImage2@0,5x.png",
      imgLarge: "./IMGS/Projects/GuestPointer/HeaderImage2@2x.png",
      imgAltText: "GuestPointer",
    },
    myRoles: "Product Designer, Full-Stack Developer",
    tools:
      "Type Form, Excel, PowerPoint, Sketch, Adobe Illustrator, Invision, Visual Studios",
    techniques:
      "Generative Research, User Interviews, Competitive Analysis, Requirements Definition, User Flow Creation, Site Map Creation, Wireframing, Low-Fi Prototyping, Usability Testing, Visual Design Definition, Preference Testing, High-Fi Prototyping, Brand Design, Front-End Development, ASP.Net MVC Development (C#), SQL Database Development, QA Testing",
    color: "green",
    hasSummary: true,
    requiresLogin: false,
    featured: false,
  },
  // {
  //   key: 'tmportfolio',
  //   title: 'Portfolio Redesign for Twisted Media',
  //   company: 'Twisted Media, Inc',
  //   product: 'Portfolio Site',
  //   category: 'Marketing Site Design',
  //   blurb: 'I was asked to review the portfolio site for Twisted Media, Inc which is a premier playback graphics and visual effects company. For this project I reviewed their site, created specific and actionable recommendations, and provided visual mock ups for a new site design.',
  //   pageUrl: "",
  //   pageDesc:'I was asked to review the portfolio site for Twisted Media, Inc which is a premier playback graphics and visual effects company. For this project I reviewed their site, created specific and actionable recommendations, and provided visual mock ups for a new site design.',
  //   heroImg: {
  //       key: 'tmportfolio',
  //       imgThumb: './IMGS/Projects/TMPortfolio/HeaderImage@0,5x.png',
  //       imgLarge: './IMGS/Projects/TMPortfolio/HeaderImage@2x.png',
  //       imgAltText: 'Portfolio Redesign for Twisted Media'
  //   },
  //   myRoles: 'UX/UI Designer',
  //   tools: 'Excel, PowerPoint, Sketch, Adobe Illustrator, Adobe Photoshop, Adobe After Effects, Adobe XD',
  //   techniques: 'Heuristic Analysis, Wireframing, Low-Fi Prototyping, Visual Design Definition, Preference Testing, High-Fi Prototyping',
  //   color: 'yellow',
  //   requiresLogin: false,
  //   featured: false
  // },
  // {
  //   key: 'ndandroid',
  //   title: 'Nondescript Android UI for Twisted Media',
  //   company: 'Twisted Media, Inc',
  //   product: 'Playback Graphics for TV and Film',
  //   category: 'Native Mobile Design',
  //   blurb: 'Because Twisted Media, Inc rapidly delivers high quality graphics for loads of shows, they rely on up-to-date templates for things such as phone interfaces. Being an android user myself, I got to create the template.',
  //   pageUrl: "",
  //   pageDesc:'Because Twisted Media, Inc rapidly delivers high quality graphics for loads of shows, they rely on up-to-date templates for things such as phone interfaces. Being an android user myself, I got to create the template.',
  //   heroImg: {
  //       key: 'ndandroid',
  //       imgThumb: './IMGS/Projects/NDAndroid/HeroImg@0,5x.png',
  //       imgLarge: './IMGS/Projects/NDAndroid/HeroImg@2x.png',
  //       imgAltText: 'Nondescript Android UI for Twisted Media'
  //   },
  //   myRoles: 'UI Designer',
  //   tools: 'Adobe Illustrator',
  //   techniques: 'Pattern Research, Visual Design Definition',
  //   color: 'green',
  //   requiresLogin: false,
  //   featured: false
  // },
  // {
  //   key: '',
  //   title: '',
  //   company: '',
  //   product: '',
  //   category: '',
  //   blurb: '',
  //   pageUrl: "/",
  //   pageDesc:'',
  //   heroImg: {
  //       imgThumb: './IMGS/Projects-Design/HCPPipeline/PipelineHero.png',
  //       imgLarge: './IMGS/Projects-Design/HCPPipeline/PipelineHero@2x.png',
  //       imgAltText: 'Image of Pipeline for Housecall Pro'
  //   },
  //   externalURL: '',
  //   myRoles: 'Senior Product Designer',
  //   tools: '',
  //   techniques: '',
  //   color: '',
  //   requiresLogin: false,
  //   featured: true
  // }
];