import React, { useEffect, useState } from "react";
import '../../SASS/EnlargedImageModal.scss';
import { useStore } from "../stores/Store";
import { observer } from "mobx-react-lite";
import ImageGrid from "./ImageGrid";
import Button from "../ui/Button";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";

function EnlargedImageModal() {
    let location = useLocation();
    useEffect(()=>{
        ReactGA.event({
            category: "image enlarge",
            action: 'open image enlarge',
            label: location.pathname,
          });
    },[])
    
    const {imageStore} = useStore();
    const {selectedImage, imageGroup, setSelectedImage} = imageStore;

    const [zoomLevel, setZoom] = useState<number>(0);

    function handleZoom(direction:"up" | "down") {
        if (direction === "up") {
            setZoom(zoomLevel+1);
            ReactGA.event({
                category: "image enlarge",
                action: 'zoom in',
                label: imageGroup?.find(img => img.key === selectedImage)?.imgLarge || "image undefined",
              });
        } else {
            setZoom(zoomLevel-1);
            ReactGA.event({
                category: "image enlarge",
                action: 'zoom out',
                label: imageGroup?.find(img => img.key === selectedImage)?.imgLarge || "image undefined",
              });
        }
    }

    function handlePagination(direction: "next" | "previous"){
        if (direction === 'next' && imageGroup !== undefined) {
            position = position + 1;
            setSelectedImage(imageGroup[position].key)
        } else if(imageGroup !== undefined) {
            position = position - 1;
            setSelectedImage(imageGroup[position].key)
        }
        ReactGA.event({
            category: "image enlarge",
            action: 'view image',
            label: imageGroup?.find(img => img.key === selectedImage)?.imgLarge || "image undefined",
        });
    }


    let modalContent = <></>;
    let position = 0;

    if (selectedImage !== undefined && imageGroup !==undefined) {
        let focusedImg = imageGroup?.find(img => img.key === selectedImage);
        if (imageGroup.length > 1) {
            for (let index = 0; index < imageGroup.length; index++) {
                const element = imageGroup[index];
                if (element === focusedImg) {
                    position = index;

                }
            }
        }

        modalContent = 
        <div className="enlarged-img-modal-content">
            <h2 className="type-heading-2 margin-b-3">{focusedImg?.imgAltText}</h2>
            <div className="grid-x-has-gutters enlarged-img-body">
                <div className="small-12 large-8 enlarged-img-container-and-controls">
                    <div className="enlarged-img-container">
                        <img className={"enlarged-img zoom-" + zoomLevel} src={focusedImg?.imgLarge} alt={focusedImg?.imgAltText} />
                    </div>
                    <div className="grid-x-has-gutters action-row">
                        <div className="small-6 medium-6 large-12">
                            <div className="zoom-group">
                                <Button 
                                    variant="outline" 
                                    iconOnly 
                                    icon="bi-zoom-out" 
                                    text="Zoom out" 
                                    onClick={() => handleZoom('down')}
                                    disabled={(zoomLevel === 0) ? (true):(false)}/>
                                <p className="type-body">Zoom</p>
                                <Button 
                                    variant="outline" 
                                    iconOnly 
                                    icon="bi-zoom-in" 
                                    text="Zoom in" 
                                    onClick={() => handleZoom('up')} 
                                    disabled={(zoomLevel === 4) ? (true):(false)}/>
                            </div>
                        </div>
                        <div className="small-6 medium-6 hide-on-large-up">
                            {(imageGroup.length > 1) ? (
                                <div className="pagination-group">
                                    <Button 
                                        variant="outline" 
                                        iconOnly 
                                        icon="bi-chevron-left" 
                                        text="Previous image" 
                                        onClick={() => handlePagination('previous')}
                                        disabled={(position === 0) ? (true):(false)}/>
                                    <p className="type-body">{(position + 1) + " of " + imageGroup.length}</p>
                                    <Button 
                                        variant="outline" 
                                        iconOnly 
                                        icon="bi-chevron-right" 
                                        text="Next image" 
                                        onClick={() => handlePagination('next')} 
                                        disabled={(position === (imageGroup.length - 1)) ? (true):(false)}/>
                                </div>
                            ):(
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hide-on-small hide-on-medium large-4 images-group-container">
                    <ImageGrid size="modal-grid" images={imageGroup} />
                </div>
            </div>
        </div>
    }
    

    return (modalContent)
}

export default observer(EnlargedImageModal);