import { makeAutoObservable } from "mobx";
import { ReactNode } from "react";


export default class ModalStore {
    //modalIsOpen: boolean = false;
    modalVariant: 'contact' | 'login' | 'settings' | 'image' | undefined = undefined;

    constructor() {
        makeAutoObservable(this)
    }

    // setModalState = (isOpen:boolean) => {
    //     this.modalIsOpen = isOpen;
    //     if (!isOpen) {
    //         this.modalVariant = undefined;
    //     }
    // }
    setModalVariant = (variant: 'contact' | 'login' | 'settings' | 'image' | undefined) => {
        this.modalVariant = variant;

        // if (variant === undefined) {
        //     this.modalIsOpen = false;
        // }
    }
}