import { makeAutoObservable } from "mobx";

export default class ViewStore {
    scrollY:number = window.scrollY;
    viewWidth:number = window.innerWidth;
    viewHeight:number = window.innerHeight;

    constructor() {
        makeAutoObservable(this)
    }

    // setScrollY = (newScrollY:number) => {
    //     this.scrollY = newScrollY;
    // }

    setScrollY = () => {
        this.scrollY = window.scrollY;
    }

    setViewWidth = () => {
        this.viewWidth = window.innerWidth;
    }
    
    setViewHeight = () => {
        this.viewHeight = window.innerHeight;
    }

}