import React from 'react';
import './../../SASS/AwardCardList.scss';
import Card from '../ui/Card';
import { Awards } from '../data/Awards';

export default function AwardCardList(){
    return(
        <div className='grid-x-has-gutters award-card-list-container'>
            {Awards.map( award => (
                <div key={award.key} className='small-12 medium-6 award-card-cell'>
                    <Card color={award.color} addClasses='' >
                        <div className='award-container'>
                            <div className='award-containder-subcontainer'>
                                <p className='type-subhead-1 margin-b-1'>{award.title}</p>
                                <p className='type-caption'>{award.year} - {award.company}</p>
                            </div>
                            <img className='award-company-logo' src={award.companyLogo} alt={award.company + " logo"} />
                        </div>
                        <p className='type-body'>{award.description}</p>
                    </Card>
                </div>
            ))}
        </div>
    )
    
}