import React from 'react'
import '../../SASS/ProjectHighlight.scss';
import { useStore } from '../stores/Store';
import { observer } from 'mobx-react-lite';
import NLink from '../ui/NLink';
import { ProjectInfo } from '../models/ProjectInfo';
import Card from '../ui/Card';
import { Image } from '../models/Image';
import { NavLink } from 'react-router-dom';

interface Props {
    project: ProjectInfo;
}

export default observer(function ProjectHighlight({project}:Props) {
    const {imageStore, loginStore, modalStore} = useStore();
    const {setSelectedImage, setImageGroup} = imageStore;
    const {setModalVariant} = modalStore;
    const {isLoggedIn} = loginStore;

    function handleImageClick (imgKey:string, images:Image[]){
        setImageGroup(images);
        setSelectedImage(imgKey);
        setModalVariant('image');
    }

    let link = <></>;
    if (project.pageUrl === "") {
        link = <NLink variant='coming-soon' content='This case study is coming soon!' to={''} />;
    } else if (project.requiresLogin && isLoggedIn) {
        link = <NLink variant='locked' content='Read the full case study' to={project.pageUrl} />;
    } else if (project.requiresLogin && !isLoggedIn) {
        link = <NLink variant='locked' content='Login for the full case study' to={project.pageUrl} />;
    } else {
        link = <NLink content='Read the full case study' to={project.pageUrl} />;
    }

 return (
    <div className='project-highlight'>
        <div className='project-highlight-img-container'>
            {/* <img className='project-highlight-img' src={project.heroImg.imgThumb} alt={project.heroImg.imgAltText} onClick={() => setSelectedImage(project.heroImg)}></img> */}
            <img className='project-highlight-img' src={project.heroImg.imgThumb} alt={project.heroImg.imgAltText} onClick={() => handleImageClick(project.heroImg.key, [project.heroImg])}></img>
        </div>
        <Card color={project.color} addClasses='project-highlight-card'>
            <div>
                <h3 className='type-heading-3 margin-b-1'>{project.title}</h3>
                <p className='type-caption margin-b-2'>Category: {project.category}</p>
                <p className='type-body margin-b-3'>{project.blurb}</p>
            </div>
            {link}
            {/* <NLink variant='coming-soon' content='This case study is coming soon!' to={''} /> */}
            {/* {project.requiresLogin ? (
                isLoggedIn ? (
                    <NLink variant='locked' content='Read the full case study' to={project.pageUrl} />
                ) :(
                    <NLink variant='locked' content='Login for the full case study' to={project.pageUrl} />
                )
            ) : (
                <NLink content='Read the full case study' to={project.pageUrl} />
            )} */}
        </Card>
    </div>
  )
})
