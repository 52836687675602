import { makeAutoObservable } from "mobx";

export default class BrainStore {
    brainCloseAt:number = window.scrollY;

    constructor() {
        makeAutoObservable(this)
    }

    setBrainCloseAt = (topOfCloseSection:number, viewHeight:number) => {
        this.brainCloseAt = topOfCloseSection - (viewHeight*.5);
    }

}