import React, { ReactNode } from "react";
import "../../SASS/Card.scss";
import { NavLink, To } from "react-router-dom";

interface Props {
    children: ReactNode | ReactNode[];
    color?: "neutral" | "red" | "orange" | "yellow" | "green" | "blue" | "purple";
    isClickable?: boolean;
    to?: To;
    onClick?: Function;
    addClasses?: string;
}

export default function Card({children, color = "neutral", isClickable = false, to, onClick, addClasses}:Props){
    let classes:string = "cardbase " + color + "-card " + addClasses;
    if (isClickable) {
        classes = classes + " is-clickable"
        if (to !== undefined) {
            return(
                <NavLink className={classes} to={to}>
                    {children}
                </NavLink>
            )
        } else {
            return(
                <div className={classes} onClick={onClick && (() => onClick())}>
                    {children}
                </div>
            )
        }
    } else {
        return(
            <div className={classes}>
                {children}
            </div>
        )
    }
}