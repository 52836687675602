import React, { useEffect } from "react";
import ProjectHighlightList from "../components/ProjectHighlightList";
import { AllProjects } from "../data/ProjectHighlights";
import { ProjectInfo } from "../models/ProjectInfo";
import './../../SASS/Work.scss';
import Button from "../ui/Button";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/Store";
import ReactGA from 'react-ga4';

export default observer(function MyWork(){
    useEffect(() => {
      window.scrollTo(0, 0);
      // ReactGA.send({ hitType: "pageview", page: "/work", title: "Work" });
    }, [])

    const {modalStore} = useStore();
    const {setModalVariant} = modalStore;

    function handleContactButtonClick() {
      setModalVariant('contact');
    }
    
    const allProjects: ProjectInfo[] = AllProjects;
    return (
      <>
        <Helmet>
            <title>Work | NMChivers Design</title>
            <meta name="og:title" content="Work | NMChivers Design" />
            <meta name="description" content="With almost a decade of experience as a designer, I've created many user-centric experiences for large and small companies that have led to delighted customers and profitable enterprises." />
            <meta name="og:description" content="With almost a decade of experience as a designer, I've created many user-centric experiences for large and small companies that have led to delighted customers and profitable enterprises." />
            <meta name="og:url" content="https://nmchivers.design/work" />
            <meta name="og:image" content="https://nmchivers.design/IMGS/Work/WorkHeroWithBackground@0.5x.png" />
        </Helmet>
        <div className="grid-x-has-gutters work-hero-block">
          <div className="hide-for-small medium-1 large-1"></div>
          <div className="small-12 medium-10 large-10">
            <h1 className="type-heading-1">Work</h1>
          </div>
          <div className="hide-for-small medium-1 large-1"></div>

          <div className="hide-for-small medium-1 large-1"></div>
          <div className="small-12 medium-10 large-5">
            <p className="type-subhead-2">I take pride in the many user-centric experiences I’ve crafted that have led to delighted customers and profitable enterprises.</p>
          </div>
          <div className="hide-for-small medium-1 large-6"></div>

          <div className="hide-for-small medium-1 large-3"></div>
          <div className="small-12 medium-10 large-6">
            <img src="./IMGS/Work/WorkHero.webp" alt="A Variety of My Work" />
          </div>
          <div className="hide-for-small medium-1 large-3"></div>
        </div>
        <div className="grid-x-has-gutters">
          <div className="hide-for-small large-1"></div>
          <div className="small-12 large-10">
            <ProjectHighlightList projects={allProjects} />
          </div>
          <div className="hide-for-small large-1"></div>
        </div>
        <div className="work-together-block margin-t-10">
          <h3 className="type-heading-3 margin-b-5">Interested in working together?</h3>
          <Button text="Get In Touch" variant="filled" onClick={()=>handleContactButtonClick()} addClasses="stretch-at-small"/>
        </div>
      </>
    )
})