import React from 'react';
import Card from '../ui/Card';
import './../../SASS/EducationCard.scss';
import NLink from '../ui/NLink';

interface Props {
    variant: 'millikin' | 'design-lab';
}

export default function EducationCard({variant}:Props){
    let edCard = <></>;

    if (variant === 'millikin') {
        edCard =
        <Card color='blue' addClasses='education-card'>
            <div className='institution-container'>
                <img src='./IMGS/Education/mu.png' alt='Millikin Emblem' />
                <p className='type-subhead-1'>Millikin University</p>
            </div>
            <div className='body-container'>
                <div className='body-row  margin-b-1'>
                    <p className='data-label type-body'>Degree:</p>
                    <p className='data-value type-body'>Bachelor of Science in Accounting</p>
                </div>
                
                <div className='body-row'>
                    <p className='data-label'>Honors:</p>
                    <div className='data-group'>
                        <p className='data-value type-body'>James S Kemper Foundation Scholar</p>
                        <p className='data-description margin-b-2 type-caption'>One of 19 students selected nationally for a business leadership program.</p>
                        <p className='data-value type-body'>Millikin Presidential Scholar</p>
                        <p className='data-description type-caption'>One of 10 students selected for leadership and academics.</p>
                    </div>
                </div>
            </div>
        </Card>
    } else if (variant === 'design-lab') {
        edCard =
        <Card color='purple' addClasses='education-card'>
            <div className='institution-container'>
                <img src='./IMGS/Education/dl.png' alt='DesignLab Logo' />
                <p className='type-subhead-1'>UX Academy</p>
            </div>
            <div className='body-container'>
                <div className='body-row  margin-b-1'>
                    <p className='type-body'>UX Academy by Design Lab is an intensive program covering all aspects of UX and UI design with an emphasis on practice and mentorship.</p>
                </div>
                
                <div className='body-row'>
                    <NLink content='Learn more about UX Academy at DesignLab' variant='external' to={"https://designlab.com/ux-academy/"} target='_blank' />
                </div>
            </div>
        </Card>
    }

    return (edCard)
}