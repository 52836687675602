import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function DesignSystemHCPImages({imageGroup}: Props){
    let challenge1 = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/ProjectProcessPreDUET.jpg",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/ProjectProcessPreDUET.webp",
            imgAltText: "The Design to Engineering Workflow Before",
        },
    ];
    let research1 = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/ResearchPlanDoc_Page_1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/ResearchPlanDoc_Page_1.webp",
            imgAltText: "Page One of the Research Plan",
        },
        {
            key: "1.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/ResearchPlanDoc_Page_5.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/ResearchPlanDoc_Page_5.webp",
            imgAltText: "Page Five of the Research Plan",
        },
        {
            key: "1.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/ResearchPlanDoc_Page_6.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/ResearchPlanDoc_Page_6.webp",
            imgAltText: "Page Six of the Research Plan",
        },
    ];
    let research2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/UserResponses1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/UserResponses1.webp",
            imgAltText: "User Feedback Page One",
        },
        {
            key: "2.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/UserResponses2.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/UserResponses2.webp",
            imgAltText: "User Feedback Page Two",
        },
    ];
    let research3 = [
        {
            key: "3.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignersResponses1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignersResponses1.webp",
            imgAltText: "Designer Feedback Page One",
        },
        {
            key: "3.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignersResponses2.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignersResponses2.webp",
            imgAltText: "Designer Feedback Page Two",
        },
        {
            key: "3.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/EngineersResponses1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/EngineersResponses1.webp",
            imgAltText: "Engineer Feedback",
        },
        {
            key: "3.4",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/EandDResponses1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/EandDResponses1.webp",
            imgAltText: "Designer and Engineer Feedback Page One",
        },
        {
            key: "3.5",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/EandDResponses2.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/EandDResponses2.webp",
            imgAltText: "Designer and Engineer Feedback Page Two",
        },
    ];
    let planning1 = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/FigmaInventory.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/FigmaInventory.webp",
            imgAltText: "An Inventory of Housecall Pro's Figma Libraries",
        },
    ];
    let planning2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DUETIntroWorkshop.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DUETIntroWorkshop.webp",
            imgAltText: "The Team Introduction Workshop",
        },
    ];
    let planning3 = [
        {
            key: "3.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/FigmaToCodeInventory.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/FigmaToCodeInventory.webp",
            imgAltText: "Comparing Component Coverage Between Figma and Code",
        },
        {
            key: "3.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DUETRoadmap.jpg",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DUETRoadmap.webp",
            imgAltText: "The Roadmap Derived from the Inventory",
        },
    ];
    let design1 = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/OriginalColorPalette.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/OriginalColorPalette.webp",
            imgAltText: "The Original Color Palette",
        },
        {
            key: "1.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Color Testing.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Color Testing.webp",
            imgAltText: "Color Testing the Original Color Palette with Minor Adjustments",
        },
        {
            key: "1.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/NewColors.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/NewColors.webp",
            imgAltText: "The New Color Palette",
        },
        {
            key: "1.4",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Updated Color Testing.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Updated Color Testing.webp",
            imgAltText: "Color Combination Testing the New Palette",
        },
    ];
    let design2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/OriginalTypeComparison.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/OriginalTypeComparison.webp",
            imgAltText: "The Original Typography Across the Various Systems",
        },
        {
            key: "2.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/NewTypography.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/NewTypography.webp",
            imgAltText: "The New Typography System",
        },
        {
            key: "2.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Type Pairings.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Type Pairings.webp",
            imgAltText: "Type Pairing Examples",
        },
        {
            key: "2.4",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/MobileTypeTesting.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/MobileTypeTesting.webp",
            imgAltText: "Type Testing in Android and iOS Experiences",
        },
    ];
    let design3 = [
        {
            key: "3.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/CoreTokenSystem.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/CoreTokenSystem.webp",
            imgAltText: "The Core Token System",
        },
    ];
    let design4 = [
        {
            key: "4.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Buttons - Spacing and Anatomy.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Buttons - Spacing and Anatomy.webp",
            imgAltText: "Base Specifications of the Button Component",
        },
        {
            key: "4.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Buttons - Icons on Standard Variant.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Buttons - Icons on Standard Variant.webp",
            imgAltText: "Specifications for Buttons with Icons",
        },
        {
            key: "4.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Buttons - Transitions.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Buttons - Transitions.webp",
            imgAltText: "Specifications for Button State Transitions",
        },
        {
            key: "4.4",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Text Input - General Anatomy & Spacing.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Text Input - General Anatomy & Spacing.webp",
            imgAltText: "Base Specifications of the Text Input Component",
        },
        {
            key: "4.5",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Text Input - Helper Text.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Text Input - Helper Text.webp",
            imgAltText: "Specifications for Helper Text on Text Input Components",
        },
        {
            key: "4.6",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Text Input - Adornments.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Text Input - Adornments.webp",
            imgAltText: "Specifications for Adornments on Text Input Components",
        },
    ];
    let dqa1 = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-iOSButtons.gif",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-iOSButtons.gif",
            imgAltText: "Testing New Buttons in iOS",
        },
        {
            key: "1.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-iOSButtons.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-iOSButtons.webp",
            imgAltText: "Design QA Results from iOS Testing",
        },
        {
            key: "1.3",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-AndButtons.gif",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-AndButtons.gif",
            imgAltText: "Testing New Buttons in Android",
        },
        {
            key: "1.4",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-AndButtons.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-AndButtons.webp",
            imgAltText: "Design QA Results from Android Testing",
        },
        {
            key: "1.5",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-WebCheckboxes.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-WebCheckboxes.webp",
            imgAltText: "Design QA Results from Web Testing",
        },
        {
            key: "1.6",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-Jira1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-Jira1.webp",
            imgAltText: "Providing Feedback to Engineers on Jira Tickets",
        },
        {
            key: "1.7",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DesignReview-Jira2.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DesignReview-Jira2.webp",
            imgAltText: "Approving Tickets Once Defects are Corrected",
        },
    ];
    let dqa2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/SprintRetroComments.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/SprintRetroComments.webp",
            imgAltText: "Feedback from the Team During Retro",
        },
    ];
    let building1 = [
        {
            key: "1.01",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-Colors.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-Colors.webp",
            imgAltText: "The New Colors Palette in Figma",
        },
        {
            key: "1.02",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-Typography.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-Typography.webp",
            imgAltText: "The New Typography System in Figma",
        },
        {
            key: "1.03",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-Grid.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-Grid.webp",
            imgAltText: "The New Grid System in Figma",
        },
        {
            key: "1.04",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-Checkbox.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-Checkbox.webp",
            imgAltText: "The New Checkbox Component in Figma",
        },
        {
            key: "1.05",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-CompPropertiesEX2.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-CompPropertiesEX2.webp",
            imgAltText: "The New Checkbox Component’s Properties in Figma",
        },
        {
            key: "1.06",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-RadioButton.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-RadioButton.webp",
            imgAltText: "The New Radio Button Component in Figma",
        },
        {
            key: "1.07",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-Text Field.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-Text Field.webp",
            imgAltText: "The New Text Input Component in Figma",
        },
        {
            key: "1.08",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/DS-CompPropertiesEX1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/DS-CompPropertiesEX1.webp",
            imgAltText: "The New Text Input Component’s Properties in Figma",
        },
    ];
    let building2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Docs-screenshot1.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Docs-screenshot1.webp",
            imgAltText: "Design System Documentation in Confluence",
        },
        {
            key: "2.2",
            imgLarge: "./../IMGS/Projects/HCPDesignSystem/Docs-ColorFull.png",
            imgThumb: "./../IMGS/Projects/HCPDesignSystem/Thumb/Docs-ColorFull.webp",
            imgAltText: "The Full Color Document",
        },
    ];

    if (imageGroup === "challenge1") {
        return (<ImageGrid size='medium' images={challenge1} />)
    }
    if (imageGroup === "allchallenge") {
        return (<ImageGrid size='small' images={challenge1} />)
    }

    if (imageGroup === "research1") {
        return (<ImageGrid size='small' images={research1} />)
    }
    if (imageGroup === "research2") {
        return (<ImageGrid size='medium' images={research2} />)
    }
    if (imageGroup === "research3") {
        return (<ImageGrid size='medium' images={research3} />)
    }
    if (imageGroup === "allresearch") {
        return (<ImageGrid size='small' images={research1.concat(research2.concat(research3))} />)
    }

    if (imageGroup === "planning1") {
        return (<ImageGrid size='small' images={planning1} />)
    }
    if (imageGroup === "planning2") {
        return (<ImageGrid size='small' images={planning2} />)
    }
    if (imageGroup === "planning3") {
        return (<ImageGrid size='small' images={planning3} />)
    }
    if (imageGroup === "allplanning") {
        return (<ImageGrid size='small' images={planning1.concat(planning2.concat(planning3))} />)
    }

    if (imageGroup === "design1") {
        return (<ImageGrid size='small' images={design1} />)
    }
    if (imageGroup === "design2") {
        return (<ImageGrid size='small' images={design2} />)
    }
    if (imageGroup === "design3") {
        return (<ImageGrid size='small' images={design3} />)
    }
    if (imageGroup === "design4") {
        return (<ImageGrid size='small' images={design4} />)
    }
    if (imageGroup === "alldesign") {
        return (<ImageGrid size='small' images={design1.concat(design2.concat(design3.concat(design4)))} />)
    }

    if (imageGroup === "dqa1") {
        return (<ImageGrid size='small' images={dqa1} />)
    }
    if (imageGroup === "dqa2") {
        return (<ImageGrid size='small' images={dqa2} />)
    }
    if (imageGroup === "alldqa") {
        return (<ImageGrid size='small' images={dqa1.concat(dqa2)} />)
    }

    if (imageGroup === "building1") {
        return (<ImageGrid size='small' images={building1} />)
    }
    if (imageGroup === "building2") {
        return (<ImageGrid size='small' images={building2} />)
    }
    if (imageGroup === "allbuilding") {
        return (<ImageGrid size='small' images={building1.concat(building2)} />)
    }

    // if (imageGroup === "qa1") {
    //     return (<ImageGrid size='small' images={qa1} />)
    // }
    // if (imageGroup === "allqa") {
    //     return (<ImageGrid size='small' images={qa1} />)
    // }
    return(<></>);
}