import React from 'react';
import './../../SASS/FeaturedWork.scss';
import { AllProjects } from '../data/ProjectHighlights';
import Card from '../ui/Card';
import NLink from '../ui/NLink';
import { NavLink } from 'react-router-dom';

interface Props{
    activeProjectKey?: string;
}

export default function FeaturedWork({activeProjectKey}:Props) {
    let featured = AllProjects.filter(proj => (proj.featured === true) && (proj.key != activeProjectKey));

    let featuredCards = <></>;

    for (let index = 0; index < 2; index++) {
        featuredCards = 
        <>
            {featuredCards}
            <NavLink className='small-12 medium-6 featured-card-container' to={featured[index].pageUrl}>
                <Card color={featured[index].color} isClickable >
                    <h3 className='type-subhead-1 margin-b-1'>{featured[index].title}</h3>
                    <p className='type-caption margin-b-2'>{"Category: " + featured[index].category}</p>
                    <p className='type-body'>{featured[index].blurb}</p>
                </Card>
            </NavLink>
        </>
    }

    return (
        <div className='grid-x-has-gutters major-content-section featured-section-container'>
            <div className='small-12 medium-10 medium-offset-left-1 featured-section-header'>
                <h2 className='type-heading-2'>Featured Work</h2>
                <NLink content='See all of my work' to={'/work'} />
            </div>
            <div className='small-12 medium-10 medium-offset-left-1 featured-section-card-group'>
                <div className='grid-x-has-gutters'>
                    {featuredCards}
                </div>
            </div>
        </ div>
    )
}