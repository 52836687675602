import { makeAutoObservable } from "mobx";
import ReactGA from 'react-ga4';

export default class SettingsStore {
    mode:string = localStorage.getItem('mode') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'darkmode' : 'lightmode') || "lightmode";
    contentLevel: string = localStorage.getItem('contentLevel') || "detail";
    setBy: string = localStorage.getItem('setBy') || "system";

    constructor() {
        makeAutoObservable(this)
    }

    setMode = (newMode:string) => {
        if (newMode !== this.mode) {
            ReactGA.event({
                category: "mode",
                action: 'mode set',
                label: "user set " + newMode,
              });
        }
        this.mode = newMode;
        localStorage.setItem('mode', newMode);

        this.setBy = 'user';
        localStorage.setItem('setBy', 'user');

        
    }

    setContentLevel = (newCL:string) => {
        if (newCL !== this.contentLevel) {
            ReactGA.event({
                category: "content level",
                action: 'content level set',
                label: "user set " + newCL,
              });
        }

        this.contentLevel = newCL;
        localStorage.setItem('contentLevel', newCL);

        this.setBy = 'user';
        localStorage.setItem('setBy', 'user');
    }

}