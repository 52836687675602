import React from 'react';
import { Link, NavLink, To } from 'react-router-dom';
import "../../SASS/NavItem.scss";

interface Props {
    text: string;
    type: "link-internal" | "link-external" | "button" | "download" ;
    icon?: string;
    to?: To;
    href?: string;
    onClick?: Function;
    download?: string;
}

export default function NavItem({text, type = 'link-internal', icon= undefined, to = "", href = "", onClick, download}: Props) {

    let addClasses = " ";
    if (icon !== undefined) {
        addClasses = addClasses + " has-icon";
    }

    if (type === 'link-internal') {
        return (
            <NavLink to={to} className={"navitem-link" + addClasses}  onClick={onClick && (() => onClick())}>
                {text}
                {icon && <i className={icon}></i>}
            </NavLink>
        )
    } else if (type === 'download') {
        return (
            <a href={to.toString()} className={"navitem-link download " + addClasses} download={download} onClick={onClick && (() => onClick())}>
                {text}
                {icon && <i className={icon}></i>}
            </a>
        )
    } else if (type === 'link-external') {
        return (
            <Link to={href} className={"navitem-link" + addClasses} target="_blank" rel="noopener noreferrer">
                {text}
                {icon && <i className={icon}></i>}
            </Link>
        )
    } else if (onClick !== undefined) {
        return (
            <button className={"navitem-button" + addClasses} role='button' type='button' onClick={() => onClick()}>
                {text}
                {icon && <i className={icon}></i>}
            </button>
        )
    } else {
        return (
            <span>somethings broken here.</span>
        )
    }
}