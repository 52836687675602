import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import SyncUpsImages from '../../data/projects/SyncUpsImages';
import { useStore } from '../../stores/Store';

export default observer(function SyncUpsProjectPage(){
    const {settingsStore} = useStore();
    const {contentLevel} = settingsStore;
    
    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'syncups')

    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleContentSections();
      }, []);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        if (contentLevel === "summary") {
            page =
                <>
                    <Helmet>
                        <title>{projectinfo.title} | NMChivers Design</title>
                        <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                        <meta name="description" content={projectinfo.pageDesc} />
                        <meta name="og:description" content={projectinfo.pageDesc} />
                        <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                        <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/SyncUps/SyncUpsHero.webp" />
                    </Helmet>
                    <div className='grid-x-has-gutters project-page-hero'>
                        <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                            <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                        </div>
                        <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                            <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                            <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                        </div>
                        <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                            <ProjectPageInfoCard project={projectinfo} />
                        </div>
                    </div>

                    <div className='grid-x-has-gutters'>
                        <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                            <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                    <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                    <p className='type-body'>Kazoo HR, now part of WorkTango, was an employee experience platform consisting of various modules that would allow companies to run incentive programs, set goals, and run talent reviews. One module that I had the opportunity to not only work on but use in my day to day, was known as Sync-Ups. This module was a meeting management tool that had only a few customer orgs signed up for and incredibly low adoption and usage due to it’s lack of basic functionality and desired features.</p>
                                </div>

                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                    <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                    <ul className='summary-list type-body'>
                                        <li>The starting point for the Sync-Ups module when I joined the team lacked half of the basic CRUD functionality: Update and Delete.</li>
                                        <li>The creation form had accessibility and usability issues such as form fields not being keyboard accessible and information in the field being hidden by multiple icons.</li>
                                        <li>Usage wise, only one company other than Kazoo had created more than a handful of Sync-Ups in three months.</li>
                                        <li>Using the existing design system, Confetti, I redesigned the Sync-Up card from the feature’s landing page to enable edit, delete, and copy and improve the overall information architecture of the card.</li>
                                        <li>I redesigned the create form to follow the same information architecture as Google Calendar to provide the user with familiarity and I ensured the edit form was built to match the create form’s experience.</li>
                                        <li>I designed a new rich text editing input field and made it available for cross platform use by adding it to the company’s design system.</li>
                                        <li>I designed a scalable agenda building experience to support the current scope of adding text based items and items from the Goals module while ensuring the design was adaptive enough to support future content types.</li>
                                        <li>I designed a new file import component and made it available for cross platform use by adding it to the company’s design system.</li>
                                        <li>I used this new file import component to add an attachments section Sync-Ups.</li>
                                        <li>I enabled attendee collaboration on the agenda section by reusing designs and patterns I created for the create/edit form.</li>
                                        <li>I designed a full history experience for past Sync-Ups to enable the long term use of the Sync-Ups module.</li>
                                        <li>I thoughtfully designed a new note taking experience for attendees.</li>
                                        <li>I designed a proof of concept Follow-Ups mini-module that enables attendees to create tasks and follow-up items for themselves and track them to completion.</li>
                                        <li>I created clickable prototypes of the entire experience for different use cases which I used for internal and external communications.</li>
                                    </ul>
                                </div>

                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                    <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                    <SyncUpsImages imageGroup='allchallenge' />
                                </div>
                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Redesign Sync-Ups">
                                    <h2 className='type-heading-2 margin-b-2'>Redesign Sync-Ups</h2>
                                    <SyncUpsImages imageGroup='allredesign' />
                                </div>
                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design New Features">
                                    <h2 className='type-heading-2 margin-b-2'>Design New Features</h2>
                                    <SyncUpsImages imageGroup='alldesign' />
                                </div>
                                <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Prototypes">
                                    <h2 className='type-heading-2 margin-b-2'>Prototypes</h2>
                                    <SyncUpsImages imageGroup='allprototype' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FeaturedWork activeProjectKey={projectinfo.key} />
                </>
        } else {
            page = 
            <>
                <Helmet>
                    <title>{projectinfo.title} | NMChivers Design</title>
                    <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                    <meta name="description" content={projectinfo.pageDesc} />
                    <meta name="og:description" content={projectinfo.pageDesc} />
                    <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                    <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/SyncUps/SyncUpsHero.webp" />
                </Helmet>
                <div className='grid-x-has-gutters project-page-hero'>
                    <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                        <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                    </div>
                    <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                        <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                        <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                    </div>
                    <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                        <ProjectPageInfoCard project={projectinfo} />
                    </div>
                </div>

                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Kazoo HR, now part of WorkTango, was an employee experience platform consisting of various modules that would allow companies to run incentive programs, set goals, and run talent reviews. One module that I had the opportunity to not only work on but use in my day to day, was known as Sync-Ups. This module was a meeting management tool that had only a few customer orgs signed up for and incredibly low adoption and usage due to it’s lack of basic functionality and desired features.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body margin-b-4'>When I first joined the team and started getting to know the platform, I tried out the meeting management feature called Sync-Ups. In fact, I was specifically told to use it for every one-on-one I booked with my manager, every coaching session I had with the junior designer on the team, and every skip-level meeting I had with senior leadership. Right away I noticed a glaring issue though: I could create a Sync-Up and see what I created but I could not edit or delete it. They had only implemented half of the basic CRUD functionality. On top of that, the usability of the feature was lacking. Some of the form elements were difficult to use when creating a Sync-Up, the order of the fields did not feel intuitive, and the information architecture of the view experience did not align with the use case of the feature.</p>
                                <SyncUpsImages imageGroup='challenge1' />
                                <p className='type-body margin-b-3 margin-t-4'>Digging into the data, I learned that aside from Kazoo themselves, only seven customer orgs on the platform had Sync-Ups active on their accounts. Of those seven, only three had even used the feature in the first quarter of the year. Looking further, two of those three had only created a handful of Sync-Ups to test the feature out, early in the quarter.</p>
                                <p className='type-body margin-b-4'>Thankfully, updating the Sync-Ups module was on one of my team’s roadmaps for the quarter. Seeing this, I immediately connected with my product manager to understand the scope of the project. They were new to the company but agreed that just adding the missing basic functionality and fixing the usability issues was a good start and that the module could be even more impactful to users if we incorporated functionality we were seeing in competitors and hearing requested by users. We discussed and vetted various ideas but ultimately agreed that enabling participants in the meeting to collaborate on the agenda, integrating with other modules in the platform, adding a way to create and track next steps, and adding a more robust note taking function would be a great start!</p>
                                <SyncUpsImages imageGroup='challenge2' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Redesign Sync-Ups">
                                <h2 className='type-heading-2 margin-b-2'>Redesign Sync-Ups</h2>
                                <p className='type-body margin-b-3'>Thankfully, Kazoo already had a design system, known as Confetti, in place when I began working on this project. That meant I could work in pseudo high fidelity as I worked out challenging UX and UI problems. My goals for this design effort were to improve the features accessibility and usability while enabling future scalability.</p>
                                <p className='type-body margin-b-3'>One of the earliest problems I knew I needed to address was how to incorporate the missing half of the basic CRUD functionality: update and delete. The scope indicated that we also wanted to support quickly copying an existing Sync-Up into a new one as well. This meant introducing three additional actions to the Sync-Up card. Pretty quickly I decided that a common UI pattern of placing an more/overflow button in the top right that would open a drop down with options would be the best bet.</p>
                                <p className='type-body margin-b-4'>This presented an issue though as the time and date of the Sync-Up already lived in the top right corner of the card. Together, the time and date and the more icon from the overflow button looked as if the time and date were being truncated with an ellipsis. Discussing this with my manager, I learned that placing the time and date in the top right was a hold over from other modules that placed the creation timestamp in the top right. Because this wasn’t the creation time and date, rather it was the time and date the Sync-Up was to occur, I explored how I could move this information into the flow of the card’s content. This would better align it with its level of importance to the Sync-Up. Exploring the information architecture further I arrived at the best design for the card. Not only was I able to incorporate the missing CRUD functionality but I was able to incorporate user requests such as a location/Zoom link and the description of the Sync-Up.</p>
                                <SyncUpsImages imageGroup='redesign1' />
                                <p className='type-body margin-b-3 margin-t-4'>The next big set of challenges to tackle focused on the create and edit experiences. I opted to focus on keeping these experiences as close to the same as possible because there was no information that couldn’t be edited by the creator once the Sync-Up was created and because it would help us as a team optimize our development efforts.</p>
                                <p className='type-body margin-b-4'>First, I reorganized the top portion of the form consisting of the title, date and time, attendees, location, and description. I purposefully laid these elements out to create familiarity with the user between us and Google Calendar. Based on our data, this app had the greatest usage rate by our customers so it made the most sense to align with it from an information architecture perspective.</p>
                                <SyncUpsImages imageGroup='redesign2' />
                                <p className='type-body margin-b-3 margin-t-4'>Next came the agenda section. This section proved to be quite a challenge as it would need to handle the most future scalability. In our initial scope we wanted to enable users to add text based agenda items, items from the Goals module, and items from the Feedback module with every other module in the application on the board for future integrations. This meant we needed a mechanism to add multiple content types to a single section that would then support displaying multiple content types.</p>
                                <p className='type-body margin-b-4'>Initially I landed on a sort of button bar at the top of the input field similar to Slack’s button bar. The challenges with this design were that it might be easy to miss the buttons, the inactive buttons didn’t feel clickable, and the icon readability relied heavily on the user’s recognition in connection with our modules. So, I went in search of inspiration. I looked around the application and found a few different components that existed in Confetti that might help me solve this problem. I iterated over my design and broke down the positives and negatives of each pattern.</p>
                                <SyncUpsImages imageGroup='redesign3' />
                                <p className='type-body margin-b-3 margin-t-4'>While I had a strong preference for one iteration over the others, shifting priorities caused adding items from the Feedback module to be descoped for this initial redesign. This left us with only text based agenda items and items from the Goals module. With only two options I decided to flatten the agenda sections and provide separate inputs for them. I called this my “linear” approach. This would align with the older design and reduce development time with the goal to fully explore and test my agenda section explorations in a future project when we could add in Feedback and other modules.</p>
                                <p className='type-body margin-b-3'>For the text based agenda items, I wanted to add rich text editing. Many users asked for this and it was highlighted as a gap between Sync-Ups and our competitor’s tools. I personally felt the need for it as I’m the type of person that will add multiple sub-bullets under a line item. My goals being to ensure participants understand what we’re going to talk about and have any context needed for the conversation. Additionally, other teams had been wanting to incorporate rich text editing into their features as well. However, this was not a component that we had in our code base yet and it was not something we had in Confetti as a ready to use design component. This allowed me to explore and design a brand new component that would be used in Sync-Ups first but would be added to Confetti and our front-end development libraries for other teams to implement in their modules.</p>
                                <p className='type-body margin-b-4'>I started by exploring the component from two angles. The first was your standard design pattern research. I looked at Jira’s description box, Slack’s message input, and others distilling down from them what I liked and didn’t like. The second angle was more technical. I partnered with my lead engineer to review different pre-built libraries they could use to implement the rich text field. I paid particular attention to the level of effort for each one to be implemented and the level of effort to be customized to match my design. In the end, I created a design that fit our platform’s aesthetic, was flexible enough for use throughout the platform, and was achievable by engineering based on the library we selected. I added this component to our design system, Confetti, and worked with engineering to ensure it was built to match design.</p>
                                <SyncUpsImages imageGroup='redesign4' />
                                <p className='type-body margin-b-4 margin-t-4'>Displaying the various content types in the agenda sections was pretty straightforward. I just needed to create a scalable line item design. This design needed to handle basic information about the item such as whether it was a text item or a Goals item, who added it to the agenda, and enable it to be edited and/or removed. It also needed to have a scalable content section that would allow for the rich text input by the user or the structured data from the Goals module to be displayed in an usable manner. Taking this approach would also allow the design to adapt to future content types such as items from the Feedback module and the Recognition module. I also used this line item design in the expandable agenda section on the summary card shown on the Sync-Ups landing page.</p>
                                <SyncUpsImages imageGroup='redesign5' />
                                <p className='type-body margin-b-4 margin-t-4'>The last section of the create and edit form that I needed to address was the attachments section. In the design system we had a pattern for dragging and dropping images into text fields which included an overlay to illustrate the dropzone. However, this component was never implemented in code. It did, however, give me a good starting point for creating a dedicated file import/attachment component. I created a design leveraging the dropzone design as a starting point, worked with engineering to ensure it was feasible, and then went through the process of adding it to the design system. This component was then used in several other modules throughout the application such as in user management where it was used to allow administrators to import lists of new users.</p>
                                <SyncUpsImages imageGroup='redesign6' />
                                <p className='type-body margin-b-4 margin-t-4'>With these components in place, the create and edit forms were complete. And with the agenda section inputs, line items, and attachments section designed and built in a reusable manner, I was able to reused these components to enable collaboration amongst the attendees. Once an attendee clicked into the detailed view of a Sync-Up, they would see options to add topics to the agenda or add attachments as well as have the ability to edit or delete items and attachments.</p>
                                <SyncUpsImages imageGroup='redesign7' />
                                <p className='type-body margin-b-4 margin-t-4'>The last bit of basic functionality that the old Sync-Ups experience lacked, was the ability of a user to see and interact with past Sync-Ups. The old experience would only show you your last ten Sync-Ups on the feature’s landing page in a Past Sync-Ups card on the right side. There was no ability to see anything beyond those last ten. I adjusted the design of that card, added a “View All” button to the top of it, and created a separate page to display all of the past Sync-Ups the user created or attended. I leveraged our common table pattern to create similarity between this page and other experiences in the application and to save the team a bit of time and effort.</p>
                                <SyncUpsImages imageGroup='redesign8' />
                                <p className='type-body margin-t-4'>With the create, edit, delete, and view experiences in place for new, upcoming, present, and past Sync-Up, the module was well on its way to being a stand-out feature on the platform.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design New Features">
                                <h2 className='type-heading-2 margin-b-2'>Design New Features</h2>
                                <p className='type-body margin-b-3'>The next big hurdles to cross focused on adding completely new features to the Sync-Ups module that went beyond the basic CRUD functionality to enhance the attendee experience. One new feature focused on enabling attendees to take notes on the Sync-Up. With the rich text input created for the text based agenda item, the creating and editing experience was pretty straightforward.</p>
                                <p className='type-body margin-b-3'>I started with designing an empty state for the new Notes section of the Sync-Up Details page. This empty state would prompt attendees to start taking notes using a simple, friendly language. Clicking the link would then open a rich text input field for them to capture their thoughts before saving them to the Sync-Up. For the filled state of the Notes card I wanted to make sure an attendee was able to quickly find the notes they took, edit them if necessary, and then read the notes taken by other attendees. To accomplish this, I designed a “Your notes” section at the top of the card. I worked with my engineering team to make sure it would always show the signed in user’s notes if they had taken any or the empty state if they hadn’t. This section included an edit button to enable the attendee to quickly update their notes.</p>
                                <p className='type-body margin-b-4'>Below the “Your notes” section, an attendee would see all other attendees’ notes in chronological order based on when they were added. I even worked with my engineers to create a pattern to display if an attendee’s notes had been updated and, on hover, when it was updated. This gave all attendees equal ability to document the Sync-Up and provided transparency around everyone’s experience.</p>
                                <SyncUpsImages imageGroup='design1' />
                                <p className='type-body margin-b-3 margin-t-4'>The next new feature I wanted to bring to Sync-Ups was a new Follow-Ups mini-module for them to capture and track follow-up items. Strategically, we were treating this a bit like a proof of concept for a new module to the platform. The idea being that if it worked as we imagined it, and it was well received by users, it would eventually become a standalone module that could be integrated into other modules such as Goals, Feedback, and Talent Review.</p>
                                <p className='type-body margin-b-4'>At one point during the project, I even explored how this stand alone module would look fully integrated with other modules. For example, you could have a task created to update your goals from the Goals module or you could have a task to provide feedback to a direct report from the Feedback module. You would then have a single page to go to from the primary navigation which would show you your open action items with a search and filter ability.</p>
                                <SyncUpsImages imageGroup='design2' />
                                <p className='type-body margin-b-3 margin-t-4'>For this proof of concept though, I took a fairly simple, straightforward approach to the experience and design. As part of a Sync-Up, attendees could add tasks and next steps to a list. After the meeting, an attendee would be able to access a list of their tasks from all Sync-Ups they had attended and check them off as they completed them. They would also be able to go back to a past Sync-Up and see all of the tasks and next steps that had been captured there for any attendee.</p>
                                <p className='type-body margin-b-3'>For Follow-Ups on Sync-Ups, I designed a new section on the details page similar to the Notes section. This section included a simple text field with a plus button and any items that had been created. The idea of having the input to create items always available came from the fact that tasks and next steps could come up in conversation quickly and I wanted to enable users to capture them just as quickly.</p>
                                <p className='type-body margin-b-4'>As far as assigning tasks, I considered having the assignee as a selection and even explored the design of the component with that available. However, in conversation with my team, we felt that would be too much work to try and accomplish for this proof of concept. So, I kept the assignee locked to the logged in user. This would also put the responsibility on the user to capture their own tasks and next steps which we felt was a good behavior to promote. The challenge I gave my engineers then was that a user would also only be able to edit, delete, or complete their own tasks and next steps. This created consistency in the user’s mental model around Follow-Ups and ensured the responsibility rested with them for their items.</p>
                                <SyncUpsImages imageGroup='design3' />
                                <p className='type-body margin-b-4 margin-t-4'>To enable a user to see all of their Follow-Ups, I added a section at the top of the Sync-Ups landing page. This section would provide five of the most recent items to the user with a link in the top right to view all of their items. Clicking this link would take them to a new page I designed to show all of their action items with pagination and a search capability to support users with many open items. Completed Follow-Ups would be removed from the main section of the page and added to a narrow section on the right side following the pattern from the Sync-Ups experience. In order to keep this proof of concept as light as possible, we agreed to only display the ten most recently completed items in this section. A future effort would be scheduled to create an experience for viewing and handling completed items.</p>
                                <SyncUpsImages imageGroup='design4' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Prototypes">
                                <h2 className='type-heading-2 margin-b-2'>Prototypes</h2>
                                <p className='type-body margin-b-4'>In order to best communicate the various experiences covered by this project, I created a series of prototypes. In the first, I act as the creator and facilitator of a Sync-Up. In the second I articulate the attendees’ experience. The prototypes were used to communicate the updates and new features across the company and with customers. Feedback on the experiences was incredibly positive across all audiences.</p>
                                <SyncUpsImages imageGroup='prototype1' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Implementation">
                                <h2 className='type-heading-2 margin-b-2'>Implementation</h2>
                                <p className='type-body'>I departed for another opportunity before the project was completed, however I kept in touch with my product manager to see how things were going. In the end, they had to adjust scope due to shifting priorities impacting the company. Shortly thereafter, Kazoo HR became WorkTango.</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>

                <FeaturedWork activeProjectKey={projectinfo.key} />
            </>
                    
        }
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE"></div>
//<h2 className='type-heading-2 margin-b-2'></h2>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />