import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alert from '../../ui/Alert';
import InsuranceShoppingImages from '../../data/projects/InsuranceShoppingImages';

export default observer(function InsuranceShopping(){
    const {loginStore, snackbarStore, settingsStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setSnackbar} = snackbarStore;
    const {contentLevel} = settingsStore;
    const navigate = useNavigate();

    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'insuranceshopping')


    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        if (projectinfo?.requiresLogin) {
            if (isLoggedIn) {
                window.scrollTo(0, 0);
                handleContentSections();
            } else {
                navigate('/work');
                setSnackbar('error', "You need to be logged in to view that case study.")
            }
        } else {
            window.scrollTo(0, 0);
            handleContentSections();
        }
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/BCBSInsShopExp/InsuranceShoppingHero.webp" />
            </Helmet>

            {((contentLevel === 'summary') && (projectinfo.hasSummary === false))?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(<></>)}

            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>
            
            {((contentLevel === 'summary') && (projectinfo.hasSummary))?(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Health Care Services Corporation (HCSC), the largest customer-owned health insurance company in the US, operates the BlueCross BlueShield plans in IL, TX, NM, MT, and OK. Because of this broad reach, changes to shared experiences can often be slow. Each state and line of business has different needs, legal requirements, and regulatory concerns. One such experience is the retail platform for health insurance plans purchased outside of employer offered plans. Individuals and families who purchase their own insurance plans as well as Medicare and Medicaid shoppers utilize this experience at least once a year; usually during the busiest time of the year for health insurance companies: open enrollment.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>This project had originally been kicked off months before my engagement with it and had suffered many stops and restarts along the way.</li>
                                    <li>I was chosen as part of a four person design team that was sequestered in a war room to focus only on this effort and complete the redesign in a matter of months.</li>
                                    <li>The company’s design department was organized by discipline with UX/interaction design being separate from visual design.</li>
                                    <li>As a full-stack product designer myself, I advocated for us to operate outside of our team disciplines in order for us to move quicker and reduce handoff delays between designers.</li>
                                    <li>I lead the effort to research the existing experience and define the user stories for this project which we placed on a real-world kanban board in the war room.</li>
                                    <li>We organized the user stories into epics and created a page level workflow that helped us track and communicate progress to leadership.</li>
                                    <li>We defined a review process to make the most of the time we had with our Subject Matter Experts.</li>
                                    <li>I led the effort to create a real-world walkable prototype by printing out each screen and organizing them into a workflow on the walls of the war room. This helped our SMEs establish context when reviewing new work and approving it.</li>
                                    <li>We utilized the real-world walkable prototype for our final presentation to senior leadership.</li>
                                    <li>Feedback was unanimously positive and the project was considered a huge success.</li>
                                </ul>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Research">
                                <h2 className='type-heading-2 margin-b-2'>Research</h2>
                                <InsuranceShoppingImages imageGroup='allresearch' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design">
                                <h2 className='type-heading-2 margin-b-2'>Design</h2>
                                <InsuranceShoppingImages imageGroup='alldesign' />
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Health Care Services Corporation (HCSC), the largest customer-owned health insurance company in the US, operates the BlueCross BlueShield plans in IL, TX, NM, MT, and OK. Because of this broad reach, changes to shared experiences can often be slow. Each state and line of business has different needs, legal requirements, and regulatory concerns. One such experience is the retail platform for health insurance plans purchased outside of employer offered plans. Individuals and families who purchase their own insurance plans as well as Medicare and Medicaid shoppers utilize this experience at least once a year; usually during the busiest time of the year for health insurance companies: open enrollment.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body margin-b-3'>This project was not only exciting in the sheer scale of it but also because of the number of challenges the team and I had to address and overcome.</p>
                                <p className='type-body margin-b-3'>The effort to completely redesign the health insurance retail experience had originally kicked off several months before I was brought into the project. It had started, stalled, stopped, and restarted a number of times due to the complexities of the project and the organization. For example, we were working with subject matter experts (SMEs) in five different states across various lines of business, legal, and regulatory departments that all had work to do to keep the business running in addition to providing input to this project. The design team on this project had also seen a bit of turn over throughout the starting, stopping, and restarting cycle.</p>
                                <p className='type-body margin-b-3'>In an effort to get the project back on track, the leadership of the departments involved sat down and came to an agreement. That agreement was to set up a home base, a war room if you will, and move resources in to focus on this project. The goal they set was to get the entire health insurance retail experience redesigned within a few short months.</p>
                                <p className='type-body margin-b-3'>To that end, four designers, myself included, along with a front-end developer/scrum master packed up our monitors, keyboards, and mice and moved to a conference room on a different floor. SMEs from the various lines of business were brought in to spend as much time in the room with us; some even coming in from the different states we were working with.</p>
                                <p className='type-body margin-b-3'>That wasn’t the end of our challenges though. As a newly formed team we had our share of new challenges as well. The design department was organized by discipline and I was coming from the interaction design team whereas the other three designers were coming from the visual design team. Since we were going to be moving as quickly as we could through the process, I advocated that we should work in a more full-stack design capacity rather than creating delays due to hand-offs between interaction and visual designer. This would allow us to work on different aspects of the experience simultaneously. It did. however. force the team out of our discipline specific roles. Being a full-stack product designer myself, I was comfortable working on all aspects of the design process but some of my team were not quite as versatile. To help them and ensure we were putting the best experience forward that we could, we agreed that one visual designer would have final say on visual design choices while I would have final say on all interaction design choices.</p>
                                <p className='type-body'>With the team set, roles figured out, and everyone in the room, we got to work.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Research">
                                <h2 className='type-heading-2 margin-b-2'>Research</h2>
                                <p className='type-body margin-b-4'>Coming into a project with the kind of history this project had, there was a lot of work that had been started that I hadn’t seen and a lot about the existing experience I didn’t know yet. To remedy this, I set out to learn about the experience by walking through the existing experience with a SME. Side by side, we went through each step a typical user would take in purchasing an individual health insurance plan. As we went, I asked questions to gain an understanding behind why certain steps worked the way they did, where different footnotes and explanation text came from, and to learn about some ambiguous concepts of health insurance. Each aspect of the experience led to a new requirement we needed to address in our redesign.</p>
                                <InsuranceShoppingImages imageGroup='research1' />
                                <p className='type-body margin-b-4 margin-t-4'>To keep track of our requirements we documented them as user stories. We used the typical ‘As a _, I want to _ so that I can _’ to ensure we all understood the reason behind the requirement. Our team wasn’t given access to any digital tools to track these requirements so we instead created a physical kanban board in the room utilizing part of the wall. Our user stories were then written on post-it notes and placed on the wall/board. This would later become the spot for our daily stand-ups. After the first few stacks of post-its had been placed on the wall, it was abundantly clear that this process was complicated. It wasn’t a surprise anymore why this dated experience had yet to be redesigned.</p>
                                <InsuranceShoppingImages imageGroup='research2' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design">
                                <h2 className='type-heading-2 margin-b-2'>Design</h2>
                                <p className='type-body margin-b-3'>Based on the requirements we had generated from our walkthroughs with the SMEs, we created a user flow diagram to aid us in the design process. We walked this through and validated the steps in the process with the SMEs to ensure we were accounting for as many edge cases as we could. Typically, when I create user flows, I pay very close attention to the flow of the experience and try not to specify which page a step will take place on right away. In this instance though, it was beneficial to organize the flow around the pages involved in order to help us track and communicate progress and facilitate sign offs as we moved forward.</p>
                                <p className='type-body margin-b-4'>As we worked, we ensured that all designs were fully responsive to help users purchase insurance from any device. We also created a common ‘learn more’ pattern that we could easily reuse throughout the experience to explain parts of the process and aspects of health insurance. This information had cluttered the old experience and had been surfaced in inconsistent ways throughout it.</p>
                                <InsuranceShoppingImages imageGroup='design1' />
                                <p className='type-body margin-b-3 margin-t-4'>As we completed each story on the board, we moved them into the review phase. Here we had agreed to review each page with our SMEs to get their sign off and mark them as done in our page focused workflow. However, as the project progressed, our SMEs had less and less time to spend with us. To reduce this bottleneck, we defined a cadence for reviews. Every morning during the daily scrum we would highlight to our SMEs what screens or features were ready for review. Then, after the daily scrum, the SMEs in the room would work on getting any additional SMEs needed from other lines of business, states, legal, or regulatory on a video call with us so that we could share our screens, walk through what we had done the previous day, and get sign-off on our work.</p>
                                <p className='type-body margin-b-3'>During these reviews, confusion would often set in as more and more screens were added to the flow and more and more edge cases were worked on. Often, our SMEs in the room would get stuck on where our designs fit into the process since the four of us were designing different parts of the experience, out of order. What we needed was some sort of prototype that could help us contextualize the screens for our SMEs. We were constrained though since the four of us were working from separate, local sketch files and InVision did not make it easy at the time to quickly sync multiple files and create one clickable prototype.</p>
                                <p className='type-body margin-b-4'>Looking at our real-world kanban board, I had an idea. We could go low tech here and create a real-world ‘walkable’ prototype. Looking around the room, the walls were either empty or white boards and we already had a printer in the room. So, after a quick trip down to the design department for an arm full of extra-long paper and a handful of tape, I set about printing out all of the screens we had designed thus far and began constructing a full user flow diagram on the walls of the room.</p>
                                <InsuranceShoppingImages imageGroup='design2' />
                                <p className='type-body margin-t-4'>This was a huge success. Now a SME could contextualize where they were at in the process as they were looking at a new screen and give relevant feedback much more easily. Eventually, this life-size user flow became a tool for explaining the experience not only to the team but to senior leadership as they came in periodically to check on progress.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Final Product">
                                <h2 className='type-heading-2 margin-b-2'>Final Product</h2>
                                <p className='type-body margin-b-3'>The final review with senior leadership came right at the end of our allotted time. We had made it. We opted to use the life-size user flow as the main presentation media as most of the stakeholders were joining us in the war room for the presentation. We did have some stakeholders joining remotely though so another designer followed along sharing each screen over WebEx as the presentation progressed.</p>
                                <p className='type-body margin-b-3'>The feedback was immensely positive. Everyone agreed that our new experience was a vast improvement over the old one not just visually but in the flow of the experience as well. Our front-end developer was even able to show the progress they had made on building components of the design.</p>
                                <p className='type-body'>After the presentation we packed up the room and headed back to our desks in the design department with many stories and laughs to share with our colleagues.</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>
            )}
            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE">
//<h2 className='type-heading-2 margin-b-2'></h2>
//</div>

//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />