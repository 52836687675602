import React, { useEffect, useRef, useState } from "react";
import './../../SASS/Snackbar.scss';
import Button from "./Button";
import { useStore } from "../stores/Store";

export default function SnackBar(){
    const {snackbarStore, settingsStore} = useStore();
    const {variant, content, addClasses, setSnackbar} = snackbarStore;
    const {mode} = settingsStore;
    const [countdown, setCountdown] = useState<number>(100);
    const snackBar = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setCountdown(countdown - 10);
            if (countdown === 0) {
                snackBar.current?.classList.add("exit-screen");
                const timeout = setTimeout(() => {
                    setSnackbar(undefined, "");
                }, 500)
            }
        }, 500);
  
        //Clearing the interval
        return () => {
            clearInterval(interval);
        }
    },[countdown])

    function handleDismiss() {
        snackBar.current?.classList.add("exit-screen");
        const timeout = setTimeout(() => {
            setSnackbar(undefined, "");
        }, 500)
    }

    let snackbar = <></>;
    let sbClasses = "";

    if (variant === "success") {
        sbClasses = "snackbar-success " + addClasses + ((mode === 'darkmode')?(" dark-mode"):(""));
        snackbar = 
        <div ref={snackBar} className={sbClasses}>
            <i className="bi-check2-circle"></i>
            <p className="type-body snackbar-contents">{content}</p>
            <Button variant="hollow" iconOnly icon="bi-x" text="Dismiss Snackbar" addClasses="button-color-green" onClick={()=>handleDismiss()} />
            <div className="snackbar-countdown" style={{width:countdown+"%"}}></div>
        </div>
    } else if (variant === "info") {
        sbClasses = "snackbar-info " + addClasses + ((mode === 'darkmode')?(" dark-mode"):(""));
        snackbar = 
        <div ref={snackBar} className={sbClasses}>
            <i className="bi-info-circle"></i>
            <p className="type-body snackbar-contents">{content}</p>
            <Button variant="hollow" iconOnly icon="bi-x" text="Dismiss Snackbar" addClasses="button-color-blue" onClick={()=>handleDismiss()} />
            <div className="snackbar-countdown" style={{width:countdown+"%"}}></div>
        </div>
    } else if (variant === "error") {
        sbClasses = "snackbar-error " + addClasses + ((mode === 'darkmode')?(" dark-mode"):(""));
        snackbar = 
        <div ref={snackBar} className={sbClasses}>
            <i className="bi-exclamation-octagon"></i>
            <p className="type-body snackbar-contents">{content}</p>
            <Button variant="hollow" iconOnly icon="bi-x" text="Dismiss Snackbar" addClasses="button-color-red" onClick={()=>handleDismiss()} />
            <div className="snackbar-countdown" style={{width:countdown+"%"}}></div>
        </div>
    } else if (variant === "warning") {
        sbClasses = "snackbar-warning " + addClasses + ((mode === 'darkmode')?(" dark-mode"):(""));
        snackbar = 
        <div ref={snackBar} className={sbClasses}>
            <i className="bi-exclamation-triangle"></i>
            <p className="type-body snackbar-contents">{content}</p>
            <Button variant="hollow" iconOnly icon="bi-x" text="Dismiss Snackbar" addClasses="button-color-yellow" onClick={()=>handleDismiss()} />
            <div className="snackbar-countdown" style={{width:countdown+"%"}}></div>
        </div>
    }



    return(snackbar);
}