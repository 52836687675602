import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../stores/Store';
import Modal from '../ui/Modal';
import LoginForm from './LoginForm';
import EnlargedImageModal from './EnlargedImageModal';
import ContactForm from './ContactForm';
import SettingsForm from './SettingsForm';
import ReactGA from 'react-ga4';

interface Props {
    selectedModalVariant: 'contact' | 'login' | 'settings' | 'image' | undefined;
}

export default observer(function Modals({selectedModalVariant = undefined}: Props){
    const {modalStore, imageStore} = useStore();
    const {cancelSelectedImage, cancelImageGroup} = imageStore;
    const {modalVariant, setModalVariant} = modalStore;

    useEffect(()=>{
        let htmlElement = document.getElementsByTagName("html");
        if (modalVariant === undefined) {
            htmlElement[0].classList.remove("modal-stop-scroll");
        } else {
            htmlElement[0].classList.add("modal-stop-scroll");
        }

        return() => {
            htmlElement[0].classList.remove("modal-stop-scroll");
        }
    },[])

    function handleOnClose(){
        if (modalVariant === "login") {
            setModalVariant(undefined);
            ReactGA.event({
                category: "login",
                action: 'close login',
              });
        } else if (modalVariant === "image") {
            setModalVariant(undefined);
            cancelSelectedImage();
            cancelImageGroup();
            ReactGA.event({
                category: "image enlarge",
                action: 'close image enlarge',
              });
        } else if (modalVariant === "contact") {
            setModalVariant(undefined);
            ReactGA.event({
                category: "contact",
                action: 'close contact',
              });
        } else if (modalVariant === "settings") {
            setModalVariant(undefined);
            ReactGA.event({
                category: "settings",
                action: 'close settings',
              });
        }
    }

    let modalElement = <></>;

    if (selectedModalVariant === 'login') {
        setModalVariant('login');
        modalElement = 
        <Modal size='small' onClose={handleOnClose}>
            <LoginForm isModal/>
        </Modal>
    } else if (selectedModalVariant === 'image') {
        setModalVariant('image');
        modalElement = 
        <Modal size='large' onClose={handleOnClose}>
            <EnlargedImageModal />
        </Modal>
    } else if (selectedModalVariant === 'contact') {
        setModalVariant('contact');
        modalElement = 
        <Modal size='small' onClose={handleOnClose}>
            <ContactForm isModal />
        </Modal>
    } else if (selectedModalVariant === 'settings') {
        setModalVariant('settings');
        modalElement = 
        <Modal size='small' onClose={handleOnClose}>
            <SettingsForm isModal />
        </Modal>
    }

    return(modalElement)
})