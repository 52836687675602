import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alert from '../../ui/Alert';
import MarketingSiteBCBSImages from '../../data/projects/MarketingSiteBCBSImages';

export default observer(function MarketingSiteBCBS(){
    const {loginStore, snackbarStore, settingsStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setSnackbar} = snackbarStore;
    const {contentLevel} = settingsStore;
    const navigate = useNavigate();
    
    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'marketingsitebcbs')


    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        if (projectinfo?.requiresLogin) {
            if (isLoggedIn) {
                window.scrollTo(0, 0);
                handleContentSections();
            } else {
                navigate('/work');
                setSnackbar('error', "You need to be logged in to view that case study.")
            }
        } else {
            window.scrollTo(0, 0);
            handleContentSections();
        }
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/BCBSPublicSite/InsuranceMarketingHero.webp" />
            </Helmet>

            {((contentLevel === 'summary') && (projectinfo.hasSummary === false))?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(<></>)}

            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>
            
            {((contentLevel === 'summary') && (projectinfo.hasSummary))?(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Health Care Services Corporation (HCSC) operates the BlueCross BlueShield plans in Illinois, Texas, New Mexico, Montana, and Oklahoma. The public websites for these plans each follow the same structure and design patterns. Therefore, whenever one site is changed, all five are affected. So, when HCSC decided to move forward with updating their public facing web presence for their BCBS plans, it was no small feat.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>At first, we were only to work on the sections of the public facing websites for bcbsil.com, bcbstx.com, bcbsnm.com, bcbsmt.com, and bcbsok.com that contained marketing and plan information for individual and family plans.</li>
                                    <li>This project began only a few months ahead of open enrollment and was to be launched in time for open enrollment, the busiest time of the year for individual and family plan sales.</li>
                                    <li>Design leadership wanted to ensure we were evolving the design language for the company and ushering in a more modern experience for the user as well.</li>
                                    <li>Components shared across the site, beyond the individual and family plans section, were not to be touched and created additional constraints on the project.</li>
                                    <li>About a month into this project, scope was expanded to include redesigning the main home page across all five web properties.</li>
                                    <li>I led the information architecture and wireframing portions of the project and guided a junior designer that focused on less dynamic portions of the experience.</li>
                                    <li>I designed a pathing concept that integrated with the home page to help a user get to the right information faster and more efficiently based on the type of plan they were interested in.</li>
                                    <li>I assisted my visual designer in exploring different visual concepts and organized a storyboard for the project to help leadership understand what we had accomplished and where we were heading.</li>
                                    <li>I designed a secondary navigation system for the individual and family plan section of the site that connected with the primary navigation system but was grounded in the new visual style of the site.</li>
                                    <li>When it looked like we would not be able to get the secondary navigation build and implemented on time for launch, I stepped in and coded the component.</li>
                                    <li>The redesigned home page and individual and family section went live in time for open enrollment.</li>
                                </ul>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframing">
                                <h2 className='type-heading-2 margin-b-2'>Wireframing</h2>
                                <MarketingSiteBCBSImages imageGroup='allwireframing' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <MarketingSiteBCBSImages imageGroup='allvisd' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Secondary Navigation">
                                <h2 className='type-heading-2 margin-b-2'>Secondary Navigation</h2>
                                <MarketingSiteBCBSImages imageGroup='allsecnav' />
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Health Care Services Corporation (HCSC) operates the BlueCross BlueShield plans in Illinois, Texas, New Mexico, Montana, and Oklahoma. The public websites for these plans each follow the same structure and design patterns. Therefore, whenever one site is changed, all five are affected. So, when HCSC decided to move forward with updating their public facing web presence for their BCBS plans, it was no small feat.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body margin-b-3'>For this project, it felt like the challenges never seemed to stop coming. At first, the business team that prioritized this project wanted to focus solely on the sections of the public facing websites for bcbsil.com, bcbstx.com, bcbsnm.com, bcbsmt.com, and bcbsok.com that contained marketing and plan information for individual and family plans.</p>
                                <p className='type-body margin-b-3'>This alone was a significant challenge due to the amount of information contained in these sections, the wide audience of user’s these sections would impact, as well as the timeline for these changes. This project began only a few months ahead of open enrollment and our business partners set the goal to have it all designed, developed, and launched before open enrollment began. In the health insurance space, open enrollment is the busiest time of the year for the individual and family plan market.</p>
                                <p className='type-body margin-b-3'>Additionally, leadership of the design department wanted to ensure we were evolving the design language for the company and ushering in a more modern experience for the user. This meant that in addition to reconfiguring the information architecture of these sections to create a better information hierarchy for each page, but also defining a new visual style and identity for the five web properties.</p>
                                <p className='type-body margin-b-3'>As we began digging into the project though another challenge arose. Since we were only working on the individual and family plan’s sections of the site, shared components were not to be touched. This included the main navigation of the websites as well as the footer. One of the largest constraints this placed on us was content width. The main site navigation was a blue ribbon at the top of the site that only reached a maximum width of 1,000 pixels at its widest breakpoint. Meaning that in order to keep the body of the page in sync with the header and footer, we needed to stay within this width for the content of the pages we were designing.</p>
                                <p className='type-body'>About a month into this project though, an additional challenge was introduced. Our design was coming along so well that the business decided that they wanted a more unifying experience between the individual and family plans section and the remainder of the site. So, the decision was made that we would expand our scope to include the main home page for the five web properties. The timeline was not adjusted for this additional scope though.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframing">
                                <h2 className='type-heading-2 margin-b-2'>Wireframing</h2>
                                <p className='type-body margin-b-4'>Keeping in mind the content width constraints and the design of the page header and footer, I set about sketching out different ideas on a whiteboard. Initially I did this for only the individual and family plans section and added to it when the home page was brought into scope. Working with a content strategist, they developed a draft content outline which I used to guide my wireframes. This allowed me to evaluate the hierarchy I was creating.</p>
                                <MarketingSiteBCBSImages imageGroup='wireframing1' />
                                <p className='type-body margin-b-4 margin-t-4'>As I established this hierarchy, I communicated it to the junior designer I was guiding and working with on this project. I gave them the task of focusing on the more content heavy interior pages that the user would navigate to from the home and landing pages I was working on. To ensure we were creating a consistent, harmonious experience, I made sure to sync regularly with them. Then, as the wireframes came together, we began reviewing with business stakeholders. We utilized InVision for these presentations creating clickable prototypes to help the stakeholders get a better sense of how the user would navigate the site. We showed these wireframes at both a large breakpoint and a smaller, mobile breakpoint to showcase how we were improving the experience for all users, regardless of how they access the site.</p>
                                <MarketingSiteBCBSImages imageGroup='wireframing2' />
                                <p className='type-body margin-b-4 margin-t-4'>As the project evolved, we started working on a concept of pathing the user to the right information based on what type of insurance they were looking for, be it individual and family plans, medicare, or medicaid. This really resonated with the business stakeholders and was the catalyst for them to agree to expand the scope of the project to include the home page. Prototyping early in the project allowed us to get to this evolution faster since it made it obvious how suboptimal the experience of going from a legacy home page design to an updated individual and family plan design was. I then worked the pathing pattern into a variety of home page wireframes that I shared with our business partners. They were excited by the new concepts and the cohesive feeling the site was going to have and their feedback allowed me to continue refining the wireframes.</p>
                                <MarketingSiteBCBSImages imageGroup='wireframing3' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <p className='type-body margin-b-3'>While I was leading the charge on the wireframes, my partner from the visual design team was leading the work to define a new visual identity for the site. This started with compiling various sites for inspirations into mood boards for the design team and leadership to discuss. This took a few iterations and ultimately led to additional requirements. With these new requirements, I worked on additional iterations to the wireframes as some of them impacted structure and hierarchy.</p>
                                <p className='type-body margin-b-4'>One major issue we started noticing at this point in the project was the backtracking and rediscussing designs, ideas, and research we had already covered and moved on from. To address this and help keep the progress we had made evident, I put together a storyboard to illustrate the work the team had done, the feedback we had gotten, and the path we were currently on.</p>
                                <MarketingSiteBCBSImages imageGroup='visd1' />
                                <p className='type-body margin-t-4'>With everyone on the same page, we continued through the design process with my partner visual designer creating high fidelity mocks. These were then reviewed by design and business leadership and moved into front-end development.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Secondary Navigation">
                                <h2 className='type-heading-2 margin-b-2'>Secondary Navigation</h2>
                                <p className='type-body margin-b-4'>The individual and family plan section of the site was incredibly dense with information but we were constrained to leave the main navigation as it was. To aid the user in navigating this dense section of the site, I set out to create a secondary navigation system that would feel connected to the main navigation but enable the user to quickly get to where they needed to go in the individual and family plan section. To create a separate but connected navigation system, I went through what felt like hundreds of revisions.</p>
                                <MarketingSiteBCBSImages imageGroup='secnav1' />
                                <p className='type-body margin-b-4 margin-t-4'>I reviewed these ideas with design leadership and iteratively incorporated feedback until I struck on a design that felt right. It aligned with the new visual style of the section but also connected nicely with the site’s primary navigation. I even used UXPin to create a clickable prototype of the secondary navigation so that it could be implemented the way I had envisioned it.</p>
                                <MarketingSiteBCBSImages imageGroup='secnav2' />
                                <p className='type-body margin-b-4 margin-t-4'>The next hurdle would be to get the component developed as the deadline loomed on the horizon. The challenge here was that the front-end developer for our project didn’t have the bandwidth to build the secondary navigation in time. So, I stepped up and offered to build it for them. I have built several websites and web apps, including the one you are on at this moment, so it was fully within my capabilities to do so. It took some convincing of the front-end manager who was surprised by a designer having engineering experience but in the end he agreed. I was then able to code and test the component quickly before I sent it over to the main front-end engineer that implemented it on the site in time for the go live.</p>
                                <MarketingSiteBCBSImages imageGroup='secnav3' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Launch">
                                <h2 className='type-heading-2 margin-b-2'>Launch</h2>
                                <p className='type-body'>In the end, the new design went live on time. There were a few things we needed to tidy-up after the launch, but all-in-all the redesign was a huge success. Not only were we able to deliver a new experience in a tight timeframe, but we were able to move the visual identity of the brand forward.</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>
            )}
            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE"></div>
//<h2 className='type-heading-2 margin-b-2'></h2>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />