import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import './../../SASS/Inputs.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    name: string;
    label: string;
    value: string;
    explanation?: string;
    addClasses?: string;
}
export default function RadioButton({id, name, label="", value, explanation="", addClasses="", ...props}: Props){
    return (
        <label className={'input-radio-container' + ((addClasses != "")?(" " + addClasses):(""))}>
            <input className='input-radio-control' type='radio' id={id} name={name} value={value} {...props}/>
            <div className='input-radio-label-explanation-container'>
                <p className='input-radio-label'>{label}</p>
                {(explanation != '')?(
                    <p className='input-radio-explanation'>{explanation}</p>
                ):(
                    <></>
                )}
            </div>
        </label>
    )
}
