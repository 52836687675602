import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "../ui/Button";
import NLink from "../ui/NLink";
import Card from "../ui/Card";
import TextInput from "../ui/TextInput";
import AwardCardList from "../components/AwardCardList";
import SkillCardList from "../components/SkillCardList";
import { Helmet } from "react-helmet";
import { NGrid } from "../ui/NGrid";
import ScrollOn from "../ui/ScrollOn";
import TextArea from "../ui/TextArea";
import NSelect, { SelectOption } from "../ui/NSelect";
import { useStore } from "../stores/Store";
import RadioButton from "../ui/RadioButton";
import RadioGroup from "../ui/RadioGroup";
import ReactGA from 'react-ga4';

export default function NMCDDesignSystem() {
    useEffect(() => {
        window.scrollTo(0, 0);
        // ReactGA.send({ hitType: "pageview", page: "/nmcd-designsystem", title: "NMCD Design System" });
      }, []);

    const {snackbarStore, settingsStore} = useStore();
    const {setSnackbar} = snackbarStore;
    const {mode} = settingsStore;

    const [testSelect, setTestSelect] = useState<SelectOption | String>("");
    const [testRadio, setTestRadio] = useState<string>("");

    function handleTestRadioChange(event: ChangeEvent<HTMLInputElement>){
        const {value} = event.target;
        setTestRadio(value);
    }



    // function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    //     console.log("The change event fired");
    //     const {value, name} = event.target;
    //     if (name === "testSelect") {
    //         setTestSelect(value);
    //     }
    // }

    useEffect(() => {
        setSnackbar("success", "Check out this really awesome snackbar! test");
    },[])
    return(
        <>
            <Helmet>
                <title>Design System | NMChivers Design</title>
                <meta name="og:title" content="Design System | NMChivers Design" />
                <meta name="description" content="Something Here" />
                <meta name="og:description" content="Something Here" />
                <meta name="og:url" content="https://nmchivers.design/nmcd-designsystem" />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Brain/Brain.png" />
            </Helmet>
            <div className='grid-x' style={{margin:"200px 50px 80px"}}>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Filled Button' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Filled Button' icon='bi-envelope-at' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Filled Button' icon='bi-envelope-at' iconOnly />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Outline Button' variant='outline' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Outline Button' variant='outline' icon='bi-envelope-at' addClasses='stretch-at-small'/>
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Outline Button' variant='outline' icon='bi-envelope-at' iconOnly />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow' icon='bi-envelope-at' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow' icon='bi-envelope-at' iconOnly />
                </div>
                <div className='small-12' style={{marginBottom:"25px"}}>
                    <NLink content='Read the full case study' to={""} />
                </div>
                <div className='small-12' style={{marginBottom:"25px"}}>
                    <NLink content='Login for the full case study' to={""} variant='locked' />
                </div>
                <div className='small-12' style={{marginBottom:"25px"}}>
                    <NLink content='Read the full recommendation' to={""} variant='external' />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Loading Button' variant='filled' isLoading />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow-square' addClasses="button-color-green" />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow-square' icon='bi-envelope-at' addClasses="button-color-blue" />
                </div>
                <div className='small-6' style={{marginBottom:"25px"}}>
                    <Button text='Hollow Button' variant='hollow-square' icon='bi-x' iconOnly addClasses="button-color-red" />
                </div>
            </div>
            <div className="nmcd-page-container">
                <div className="grid-x-has-gutters">
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card >
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="red">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="red" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="orange">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="orange" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="yellow">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="yellow" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="green">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="green" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="blue">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="blue" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="purple">
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                    <div className="small-6" style={{marginBottom:"24px"}}>
                        <Card color="purple" isClickable onClick={() => {}}>
                            <h2 className="type-heading-3">Check this card out!</h2>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="grid-x-container">
                <div className="small-12 medium-10 medium-offset-left-1">
                    <ScrollOn offsetBy={200}>
                        <Card >
                            <p>This is a test scroll on card</p>
                        </Card>
                    </ScrollOn>
                </div>
            </div>
            <div className="nmcd-page-container">
                <div className="grid-x-has-gutters">
                    <div className="small-6">
                        <TextInput id="TestInput" label="Test Input" placeholder="This is a test" isRequired={false} />
                    </div>
                    <div className="small-6">
                        <TextInput id="TestInput" label="Test Input" placeholder="This is a test" isRequired required form="TestForm" errorMessage="Please fill this in!" isInvalid/>
                    </div>
                </div>
            </div>

            <div className="grid-x-has-gutters" style={{marginBottom:"40px", marginTop: "40px"}}>
                <div className="small-6" style={{marginBottom:"24px", marginTop: "24px"}}>
                    <TextArea id="testtextarea" label="Message" placeholder="Tell me about it!" />
                </div>
                <div className="small-6" style={{marginBottom:"24px", marginTop: "24px"}}>
                    <TextArea id="testtextarea" label="Message" placeholder="Tell me about it!" errorMessage="Please fill this in!" isInvalid/>
                </div>
            </div>

            <div className="grid-x-has-gutters" style={{marginBottom:"40px", marginTop: "40px"}}>
                <div className="small-6" style={{marginBottom:"24px", marginTop: "24px"}}>
                    <NSelect 
                        id="testSelect" 
                        label="Reason" 
                        isRequired={false} 
                        placeholder="Let's talk about..."
                        valueUpdate={setTestSelect}
                        options={[
                            {
                                value: "work-together",
                                text: "Let's work together!",
                                selected: false,
                            },
                            {
                                value: "general",
                                text: "Just getting in touch.",
                                selected: false,
                            }
                        ]}
                    />
                </div>
                <div className="small-6" style={{marginBottom:"24px", marginTop: "24px"}}>
                    <RadioButton id="test" name="testname" label="This is a test!" value="test" explanation="This is an explanation of the radio." checked/>
                </div>
                <div className="small-6" style={{marginBottom:"24px", marginTop: "24px"}}>
                    <RadioGroup id="grouptest" label="This is a test radio group!" explanation="This is a great explanation!" onChange={handleTestRadioChange}>
                        <RadioButton id="test" name="NewTest" label="This is a test!" value="test1" explanation="This is an explanation of the radio." checked={testRadio === "test1"}/>
                        <RadioButton id="test2" name="NewTest" label="2. This is a test!" value="test2" explanation="This is an explanation of the radio." checked={testRadio === "test2"}/>    
                    </RadioGroup>
                </div>
            </div>

            <div className="grid-x-has-gutters">
                <div className="small-6" style={{height:"32px", backgroundColor:"pink"}}></div>
                <div className="small-6" style={{height:"32px", backgroundColor:"pink"}}></div>
                <div className="hide-on-small medium-1" style={{height:"32px", backgroundColor:"pink"}}></div>
                <div className="small-12 medium-10" style={{height:"32px", backgroundColor:"pink"}}></div>
                <div className="hide-on-small medium-1" style={{height:"32px", backgroundColor:"pink"}}></div>
            </div>
            <div className="grid-x-has-gutters">
                <div className="small-12">
                    <AwardCardList />
                </div>
            </div>

            <div className="grid-x-has-gutters">
                <div className="small-12">
                    <SkillCardList darkMode={(mode === 'darkmode')}/>
                </div>
            </div>
            <div className="grid-x-has-gutters">
                <div className="small-12 medium-10 medium-offset-left-1 medium-offset-right-1" style={{backgroundColor: "pink"}}>
                    This is a test of medium offset 1 on left and right
                </div>
                <div className="small-12 medium-7 medium-offset-left-1" style={{backgroundColor: "pink"}}>
                    This is a test of medium offset 1 on left and another column on the right
                </div>
                <div className="small-12 medium-3 medium-offset-right-1" style={{backgroundColor: "pink"}}>
                    This is a test of medium offset 1 on left and another column on the right
                </div>
            </div>
            <div className="grid-x-has-gutters">
                <div className="medium-1"></div>
                <div className="small-12 medium-10" style={{backgroundColor: "pink"}}>
                    This is a test
                </div>
                <div className="medium-1"></div>
            </div>

            <NGrid direction="row">
                This is a test
            </NGrid>

            

            {/* <Modal size="large">
                <h2 className="type-heading-2">This is the title of the modal</h2>
                <div className="grid-x-has-gutters">
                    <div className="small-6" style={{height:"32px", backgroundColor:"pink"}}></div>
                    <div className="small-6" style={{height:"32px", backgroundColor:"pink"}}></div>
                    <div className="hide-on-small medium-1" style={{height:"32px", backgroundColor:"pink"}}></div>
                    <div className="small-12 medium-10" style={{height:"32px", backgroundColor:"pink"}}></div>
                    <div className="hide-on-small medium-1" style={{height:"32px", backgroundColor:"pink"}}></div>
                </div>
            </Modal> */}
        </>
        
    )
}