import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import './../../SASS/ContactForm.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/Store';
import TextInput from '../ui/TextInput';
import NSelect, { SelectOption } from '../ui/NSelect';
import TextArea from '../ui/TextArea';
import Button from '../ui/Button';
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga4';


interface Props {
    isModal?:boolean;
}

export default observer(function ContactForm({isModal = true}: Props){
    let contactForm = <></>;

    const {modalStore, snackbarStore} = useStore();
    const {setModalVariant} = modalStore;
    const {setSnackbar} = snackbarStore;

    const [firstName, setFirstName] = useState<string>("");
    const [isErrorFirstName, setIsErrorFirstName] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>("");
    const [isErrorLastName, setIsErrorLastName] = useState<boolean>(false);
    const [eMail, setEMail] = useState<string>("");
    const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
    const [reason, setReason] = useState<SelectOption>();
    const [message, setMessage] = useState<string>("");
    const [isErrorMessage, setIsErrorMessage] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    let sendEmail = false;

    useEffect(()=>{
        ReactGA.event({
            category: "contact",
            action: 'open contact',
          });
    },[])

    function handleInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        const {value, name} = event.target;
        if (name === "firstname") {
            setFirstName(value);
            if (isErrorFirstName && firstName != "") {
                setIsErrorFirstName(false);
            }
        } else if (name === "lastname") {
            setLastName(value);
            if (isErrorLastName && lastName != "") {
                setIsErrorLastName(false);
            }
        } else if (name === "email") {
            setEMail(value);
            if (isErrorEmail && eMail != "") {
                setIsErrorEmail(false);
            }
        } else if (name === "message") {
            setMessage(value);
            if (isErrorMessage && message != "") {
                setIsErrorMessage(false);
            }
        }
    }

    function handleCancel(isInModal:boolean) {
        if (isInModal) {
            setModalVariant(undefined)
        }

        ReactGA.event({
            category: "contact",
            action: 'cancel contact',
          });

        //do something else if the form isn't in a modal
    }

    function handleSuccessfulSubmit(isInModal:boolean) {
        if (isInModal) {
            setModalVariant(undefined)
        } 
        setIsSending(false);
        setSnackbar("success", "Thank you for your message! Nick will be in touch shortly!");
        ReactGA.event({
            category: "contact",
            action: 'contact submitted',
          });
        //do something else if the form isn't in a modal
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        sendEmail = true;
        if (firstName === "" || null) {
            setIsErrorFirstName(true);
            sendEmail = false;
        }
        if (lastName === "" || null) {
            setIsErrorLastName(true);
            sendEmail = false;
        }
        if (eMail === "" || null) {
            setIsErrorEmail(true);
            sendEmail = false;
        }
        if (message === "" || null) {
            setIsErrorMessage(true);
            sendEmail = false;
        }
        if (isError) {
            sendEmail = false;
        }

        if (sendEmail) {
            //for testing, print to console
            //console.log("Send an email to Nick that " + firstName + " " + lastName + " @" + eMail + " would like to get in touch. They said this: '" + message + "'");
            if (process.env.REACT_APP_EMAILJS_SERVICE_ID != undefined && process.env.REACT_APP_CONTACT_FORM_TEMPLATE_ID != undefined) {
                setIsSending(true);
                emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_CONTACT_FORM_TEMPLATE_ID,
                    {
                        from_name: firstName + " " + lastName,
                        from_email: eMail,
                        reason: reason?.text || "no reason specified",
                        message: message
                    },
                    process.env.REACT_APP_EMAIL_KEY
                ).then(() => {
                    //do something if this is successful!
                    // console.log('Email sent!');
                    handleSuccessfulSubmit(isModal);
                }, (err) => {
                    //do something if the form couldn't be sent successfully.
                    //console.log(JSON.stringify(err));
                    setIsError(true);
                    setIsSending(false);
                });
            } else {
                //do something if they keys couldn't be fetched!
                //console.log("Keys couldn't be fetched.")
                setIsError(true);
            }
                        
        } else {
            console.log('Error caught!')
        }
    }

    contactForm = 
    <div className='grid-x-has-gutters' >
        <div className='small-12'>
            <h2 className='type-heading-2 margin-b-3'>Get In Touch</h2>
        </div>
        {(isError)?(
            <div className='small-12 margin-b-3'>
                <div className='form-error-message'>
                    <p className='type-body margin-b-1'>There seems to be a problem under the hood here. Apologies!</p>
                    <p className='type-body'>You can try emailing me directly at <a href='mailto:nick@nmchivers.design'>nick@nmchivers.design</a></p>
                </div>
            </div>
        ):(<></>)}
        <form className='small-12' name='contactForm' onSubmit={(e) => handleSubmit(e)} >
            <div className='grid-x-has-gutters' >
                <div className='small-12 medium-6'>
                    <TextInput 
                        id='firstname' 
                        name='firstname' 
                        onChange={handleInputChange} 
                        value={firstName} 
                        placeholder='Ash' 
                        label='First name' 
                        isRequired 
                        errorMessage='Please enter your first name.' 
                        isInvalid={isErrorFirstName} 
                        addClasses='margin-b-3'
                        autoFocus
                    />
                </div>
                <div className='small-12 medium-6'>
                    <TextInput 
                        id='lastname' 
                        name='lastname' 
                        onChange={handleInputChange} 
                        value={lastName} 
                        placeholder='Ketchum' 
                        label='Last name' 
                        isRequired 
                        errorMessage='Please enter your last name.' 
                        isInvalid={isErrorLastName} 
                        addClasses='margin-b-3'
                    />
                </div>
                <div className='small-12 medium-6'>
                    <TextInput 
                        id='email' 
                        name='email' 
                        onChange={handleInputChange} 
                        value={eMail} 
                        placeholder='a.ketchum@pkmn.email' 
                        label='Email address' 
                        isRequired 
                        errorMessage='Please enter your email address.' 
                        isInvalid={isErrorEmail} 
                        addClasses='margin-b-3'
                    />
                </div>
                <div className='small-12 medium-6'>
                    <NSelect 
                        id='reason'
                        name='reason'
                        valueUpdate={setReason}
                        placeholder="Let's talk about..."
                        label='Reason'
                        isRequired={false}
                        options={[
                            {
                                value: 'workTogether',
                                text: "Let's work together!",
                                selected: false
                            },
                            {
                                value: 'general',
                                text: "Just getting in touch.",
                                selected: false
                            },
                        ]}
                    />
                </div>
                <div className='small-12'>
                    <TextArea
                        id='message' 
                        name='message' 
                        onChange={handleInputChange} 
                        value={message} 
                        placeholder="I'm getting in touch about..." 
                        label='Message' 
                        isRequired 
                        errorMessage='Please let me know what you are getting in touch about.' 
                        isInvalid={isErrorMessage} 
                        addClasses='margin-b-4'
                    />
                </div>
                <div className='small-12'>
                    <div className='button-group'>
                        <Button text='Submit' type='submit' variant='filled' isLoading={isSending} />
                        <Button text='Cancel' type='button' variant='outline' onClick={() => handleCancel(isModal)}/>
                    </div>
                </div>
            </div>
        </form>
        
    </div>

    return(contactForm);
})