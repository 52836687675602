import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function MedAdherenceImages({imageGroup}: Props){
    let wireframes1 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Whiteboard Sketch 1.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Whiteboard Sketch 1.png",
            imgAltText: "Initial Whiteboard Sketches for Activity Page",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Whiteboard Sketch 2.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Whiteboard Sketch 2.png",
            imgAltText: "More Initial Whiteboard Sketches for Activity Page",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page A.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page A.png",
            imgAltText: "Version 1 of Wireframes for Activity Page",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Check In Modal.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Check In Modal.png",
            imgAltText: "Check-In Modal for Activity",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page.png",
            imgAltText: "Version 2 of WIreframes for Activity Page",
        },
        {
            key: "6",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page - Mobile.png",
            imgAltText: "Mobile Wireframe for Version 2 Wireframes",
        },
        {
            key: "7",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page - Expanded.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page - Expanded.png",
            imgAltText: "In-Page Check-In Wireframe",
        },
        {
            key: "8",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page - Expanded - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page - Expanded - Mobile.png",
            imgAltText: "Mobile In-Page Check-In Wireframe",
        },
        {
            key: "9",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page - Checked In.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page - Checked In.png",
            imgAltText: "Completed Check-In Wireframe",
        },
        {
            key: "10",
            imgLarge: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Med Adherence Details Page - Checked In - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/SketchesAndWires/Thumbs/Thumb-Med Adherence Details Page - Checked In - Mobile.png",
            imgAltText: "Mobile Completed Check-In Wireframe",
        },
    ];



    let visd1 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page.png",
            imgAltText: "Initial Visual Design for the Medication Adherence Rewards Activity",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page - Mobile.png",
            imgAltText: "Initial Mobile Visual Design for the Medication Adherence Rewards Activity",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page InProg.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page InProg.png",
            imgAltText: "Initial Visual Design for the In Progress State",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page InProg - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page InProg - Mobile.png",
            imgAltText: "Initial Mobile Visual Design for the In Progress State",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page - Checked In.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page - Checked In.png",
            imgAltText: "Initial Visual Design for the Checked In State",
        },
        {
            key: "6",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Med Adherence Details Page - Checked In - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Med Adherence Details Page - Checked In - Mobile.png",
            imgAltText: "Initial Mobile Visual Design for the Checked In State",
        },
        {
            key: "7",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Multi-Dose Check-In Wizard 1.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Multi-Dose Check-In Wizard 1.png",
            imgAltText: "Check-In Wizard Visual Design",
        },
        {
            key: "8",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Multi-Dose Check-In Wizard 1 - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Multi-Dose Check-In Wizard 1 - Mobile.png",
            imgAltText: "Mobile Check-In Wizard Visual Design",
        },
        {
            key: "9",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Multi-Dose Check-In Wizard 3.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Multi-Dose Check-In Wizard 3.png",
            imgAltText: "Completed Check In Visual Design",
        },
        {
            key: "10",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Multi-Dose Check-In Wizard 3 - Mobile.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Thumbs/Thumb-Multi-Dose Check-In Wizard 3 - Mobile.png",
            imgAltText: "Mobile Completed Check In Visual Design",
        },
    ];



    let visd2 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Med Adherence Details Page - Not Checked In - Gradient 2.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Med Adherence Details Page - Not Checked In - Gradient 2.png",
            imgAltText: "Medication Adherence Check-In Activity Hero",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Mobile - Med Adherence Details Page - Not Checked In - Gradient.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Mobile - Med Adherence Details Page - Not Checked In - Gradient.png",
            imgAltText: "Mobile Medication Adherence Check-In Activity Hero",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Gym Check-in - Dark.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Gym Check-in - Dark.png",
            imgAltText: "Gym Check-In Activity Hero",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Mobile - Gym Check-in - Dark.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Mobile - Gym Check-in - Dark.png",
            imgAltText: "Mobile Gym Check-In Activity Hero",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Stride - Gradient.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Stride - Gradient.png",
            imgAltText: "Stride Activity Hero",
        },
        {
            key: "6",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Mobile - Stride - Gradient.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks1/Heroes/Thumbs/Thumb-Mobile - Stride - Gradient.png",
            imgAltText: "Mobile Stride Activity Hero",
        },
    ];




    let visd3 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page 2.0 - Initial.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page 2.0 - Initial.png",
            imgAltText: "Design for the Medication Adherence Activity",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page - First Check-in.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page - First Check-in.png",
            imgAltText: "First Check-In State for the Medication Adherence Activity",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page - Day 1 Complete.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page - Day 1 Complete.png",
            imgAltText: "First Day Complete State for the Medication Adherence Activity",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page - Day 2 Start.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page - Day 2 Start.png",
            imgAltText: "Start of Day Two for the Medication Adherence Activity",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page - Streak Complete Day Of.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page - Streak Complete Day Of.png",
            imgAltText: "Streak Complete State for the Medication Adherence Activity",
        },
        {
            key: "6",
            imgLarge: "./../IMGS/Projects/MedAdherence/Mocks2/Med Adherence Details Page - Failed Streak.png",
            imgThumb: "./../IMGS/Projects/MedAdherence/Mocks2/Thumbs/Thumb-Med Adherence Details Page - Failed Streak.png",
            imgAltText: "Failed Streak State for the Medication Adherence",
        },
    ];

    if (imageGroup === "wireframes1") {
        return (<ImageGrid size='small' images={wireframes1} />)
    }
    if (imageGroup === "allwireframes") {
        return (<ImageGrid size='medium' images={wireframes1} />)
    }

    if (imageGroup === "visd1") {
        return (<ImageGrid size='small' images={visd1} />)
    }
    if (imageGroup === "visd2") {
        return (<ImageGrid size='small' images={visd2} />)
    }
    if (imageGroup === "visd3") {
        return (<ImageGrid size='small' images={visd3} />)
    }
    let allvisd = visd1.concat(visd2).concat(visd3);
    if (imageGroup === "allvisd") {
        return (<ImageGrid size='medium' images={allvisd} />)
    }
    
    return(<></>);
}