import { Skill } from "../models/Skill";

export const Skills: Skill[] = [
    {
        key: 'leadingpeople',
        name: 'Leading People',
        description: 'I guide creative teams to envision and realize innovative solutions. My leadership nurtures collaboration and empowers designers to flourish, fostering an environment where diverse talents harmonize.',
        icon: './IMGS/Skills/Leadership.webp',
        iconDark: './IMGS/Skills/Leadership Light.webp'
    },
    {
        key: 'solvingproblems',
        name: 'Problem Solving',
        description: 'I excel in analyzing complex challenges, transforming them into elegant solutions that harmonize form and function. My approach marries user-centric insights with creative ingenuity to result in intuitive experiences that meet user’s needs.',
        icon: './IMGS/Skills/Problem Solving.webp',
        iconDark: './IMGS/Skills/Problem Solving Light.webp'
    },
    {
        key: 'designthinking',
        name: 'Design Thinking',
        description: 'This is my compass, guiding me to empathetically uncover user needs, define problems, and ideate innovative solutions.  Through relentless iteration and thorough testing, I ensure they are not only visually compelling but also functionally effective.',
        icon: './IMGS/Skills/Design Thinking.webp',
        iconDark: './IMGS/Skills/Design Thinking Light.webp'
    },
    {
        key: 'userresearch',
        name: 'User Research',
        description: "I'm a huge advocate for user research. I utilize qualitative and quantitative methods to unearth deep insights into user behaviors and preferences. These insights serve as the foundation for creating meaningful and impactful user experiences.",
        icon: './IMGS/Skills/User Research.webp',
        iconDark: './IMGS/Skills/User Research Light.webp'
    },
    {
        key: 'strategy',
        name: 'Strategy',
        description: 'I approach design work with a strategic mindset. I aligning design solutions with overarching business goals and user needs so that design choices are purposeful and impactful. This strategic lens allows me to craft designs that not only captivate users but also contribute to the success of the larger product ecosystem.',
        icon: './IMGS/Skills/Strategy.webp',
        iconDark: './IMGS/Skills/Strategy Light.webp'
    },
    {
        key: 'wireframing',
        name: 'Wireframing',
        description: 'Wireframing is a foundational skill that allows me to outline the structure and functionality of user interfaces with precision and clarity. They enable early dialogue and usability testing and serve as blueprints between conceptualization and execution.',
        icon: './IMGS/Skills/Wireframing.webp',
        iconDark: './IMGS/Skills/Wireframing Light.webp'
    },
    {
        key: 'prototyping',
        name: 'Prototyping',
        description: 'This skill enables me to transform abstract ideas into tangible, interactive experiences. Through prototypes, I can iterate on design concepts, validate user interactions, and refine the usability of a solution before it is built into the broader platform.',
        icon: './IMGS/Skills/Prototyping.webp',
        iconDark: './IMGS/Skills/Prototyping Light.webp'
    },
    {
        key: 'visualdesign',
        name: 'Visual Design',
        description: 'Visual design is the skill I use to take functional experiences and elevate them to create visual harmony for the user. Through careful consideration of typography, color, and layout, I create designs that enhance user engagement and satisfaction.',
        icon: './IMGS/Skills/Visual Design.webp',
        iconDark: './IMGS/Skills/Visual Design Light.webp'
    },
    {
        key: 'coding',
        name: 'Coding',
        description: 'Coding is a unique skill I leverage as a designer to bridge the gap between concepts and their implementation. With development experience, I collaborate effectively with development teams to ensure that designs are faithfully translated into functional interfaces.',
        icon: './IMGS/Skills/Coding.webp',
        iconDark: './IMGS/Skills/Coding Light.webp'
    },
]