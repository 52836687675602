import { makeAutoObservable } from "mobx";
import { ReactNode } from "react";


export default class SnackbarStore {
    variant: "success" | "info" | "error" | "warning" | undefined = undefined;
    content: string = "";
    addClasses?: string = "";

    constructor() {
        makeAutoObservable(this)
    }

    setSnackbar = (variant: "success" | "info" | "error" | "warning" | undefined, content:string, addClasses?:string) => {
        this.variant = variant;
        this.content = content;
        if (addClasses != undefined) {
            this.addClasses = addClasses;
        } else {
            this.addClasses = "";
        }
    }
}