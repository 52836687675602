import React, { useEffect, useState } from "react";
import { useStore } from "../stores/Store";
import { observer } from "mobx-react-lite";
import '../../SASS/ScrollingBackground.scss';

export default observer(function ScrollingBackground() {
    const {viewStore, settingsStore} = useStore();
    const {scrollY} = viewStore;
    const {mode} = settingsStore;
    const [top, setTop] = useState<number>(0);

    useEffect(() => {
        handleScroll()
    },[scrollY])

    function handleScroll() {
        setTop((scrollY*.1) * -1);
    }

    return (
        <div className={"scrolling-background-container" + ((mode === 'darkmode')?(" dark-mode"):(""))}>
            <div className="scrolling-background-img"  style={{backgroundPositionY: top+"px"}}></div>
        </div>
        
    )
})
