import React, { ReactNode, useEffect, useRef } from 'react';
import './../../SASS/ScrollOn.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/Store';

interface Props {
    children: ReactNode | ReactNode[];
    offsetBy: number;
    offsetByIs?: "px" | "%";
    addClasses?: string;
}

export default observer(function ScrollOn({children, offsetBy, offsetByIs = "px", addClasses = ""}:Props){
    const {viewStore} = useStore();
    const {scrollY, viewHeight} = viewStore;
    const scrollOnElement = useRef<HTMLDivElement>(null);

    function handleScrollOn(){
        if (scrollOnElement.current !== null) {
            let topOfElement = scrollOnElement.current.offsetTop;
            let scrollOnAt = 0;
            if (offsetByIs === "px") {
                scrollOnAt = topOfElement + offsetBy;
            } else {
                if (offsetBy <= 1) {
                    scrollOnAt = topOfElement + (viewHeight * offsetBy);
                } else {
                    scrollOnAt = topOfElement + (viewHeight * (offsetBy / 100));
                    //console.log('the topOfElement is ' + topOfElement + "px and the scrollOnAt is " + scrollOnAt + "px");
                }
            }
            
            let scrollYBottom = scrollY + viewHeight;
            if (scrollYBottom > scrollOnAt || scrollYBottom === scrollOnAt) {
                if (!scrollOnElement.current.classList.contains('has-scrolled-on')) {
                    scrollOnElement.current.classList.add('has-scrolled-on');
                }
            }
        }
    }

    useEffect(() =>{
        if (scrollY >= 0) {
            handleScrollOn();
        }
    },[scrollY])

    let classList = 'scroll-on-container' + ((addClasses !=="") ? (" " + addClasses):(""));

    return(
        <div className={classList} ref={scrollOnElement}>
            {children}
        </div>
    );
})

