import React, { useEffect, useRef, useState } from 'react';
import './../../SASS/Brain.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/Store';

export default observer(function Brain(){
    const {viewStore, brainStore, settingsStore} = useStore();
    const {scrollY, viewWidth, viewHeight} = viewStore;
    const {brainCloseAt} = brainStore;
    const {mode} = settingsStore;
    const theBrain = useRef<HTMLDivElement>(null);
    let brainStartHeight = 0;
    let brainStartWidth = 0;
    let brainStartLeft = 0;
    let brainStartTop = 0;
    let brainHeight = 0;
    let brainWidth = 0;
    let brainLeft = 0;
    let brainTop = 0;
    let brainInPos = viewHeight / 2;

    useEffect(() => {
        brainInPos = viewHeight / 2;
    },[viewHeight])

    let brainMaxHeight = (viewHeight - (56 * 2));
    let brainMaxWidth = viewWidth;
    let brainMaxLeft = 0;
    let brainMaxTop = 56;

    if (viewWidth > 1536){
        //we're in the large breakpoint
        brainStartHeight = 800;
        brainStartWidth = 800;
        brainStartLeft = (((viewWidth - 344) / 12) * 6) + 184;
        brainStartTop = 464;
    } else if (viewWidth > 1200){
        //we're in the large breakpoint
        brainStartHeight = 640;
        brainStartWidth = 640;
        brainStartLeft = (((viewWidth - 344) / 12) * 6) + 184;
        brainStartTop = 464;
    } else if (viewWidth > 900){
        //we're in the large breakpoint
        brainStartHeight = 554;
        brainStartWidth = 554;
        brainStartLeft = (((viewWidth - 344) / 12) * 6) + 184;
        brainStartTop = 464;
    } else if (viewWidth > 600) {
        //we're in the medium breakpoint
        brainStartHeight = 560;
        brainStartWidth = 560;
        brainStartLeft = (((viewWidth - 344) / 12) * 3) + 112;
        brainStartTop = 616;
    }


    if (scrollY === 0) {
        brainHeight = brainStartHeight;
        brainWidth = brainStartWidth;
        brainLeft = brainStartLeft;
        brainTop = brainStartTop;
        theBrain.current?.classList.remove('is-apart');
    } else if (scrollY <= brainInPos && scrollY !== 0) {
        brainHeight = ((scrollY / brainInPos)*(brainMaxHeight - brainStartHeight)) + brainStartHeight;
        brainWidth = ((scrollY / brainInPos)*(brainMaxWidth - brainStartWidth)) + brainStartWidth;
        brainLeft = ((scrollY / brainInPos)*(brainMaxLeft - brainStartLeft)) + brainStartLeft;
        brainTop = ((scrollY / brainInPos)*(brainMaxTop - brainStartTop)) + brainStartTop;
        theBrain.current?.classList.remove('is-apart');
    } else if (scrollY > brainInPos && scrollY < brainCloseAt) {
        brainHeight = brainMaxHeight;
        brainWidth = brainMaxWidth;
        brainLeft = brainMaxLeft;
        brainTop = brainMaxTop;
        theBrain.current?.classList.add('is-apart');
    } else {
        brainHeight = brainMaxHeight;
        brainWidth = brainMaxWidth;
        brainLeft = brainMaxLeft;
        brainTop = brainMaxTop;
        theBrain.current?.classList.remove('is-apart');
    }

    return(
        (mode === "darkmode")?(
            <div
                id='brain'
                className='home-brain-group'
                style={{ height: brainHeight + "px", top: brainTop + "px", left: brainLeft + "px", width: brainWidth + "px" }}
                ref={theBrain}>
                <div className='home-brain-left' ><img className='brain-img' src='./IMGS/Brain/LeftBrain-Dark.webp' alt='Expression of an analytical brain' /></div>
                <div className='home-brain-gap' ></div>
                <div className='home-brain-right' ><img className='brain-img' src='./IMGS/Brain/RightBrain-Dark.webp' alt='Expression of a creative brain' /></div>
            </div>
        ):(
            <div
                id='brain'
                className='home-brain-group'
                style={{ height: brainHeight + "px", top: brainTop + "px", left: brainLeft + "px", width: brainWidth + "px" }}
                ref={theBrain}>
                <div className='home-brain-left' ><img className='brain-img' src='./IMGS/Brain/LeftBrain.webp' alt='Expression of an analytical brain' /></div>
                <div className='home-brain-gap' ></div>
                <div className='home-brain-right' ><img className='brain-img' src='./IMGS/Brain/RightBrain.webp' alt='Expression of a creative brain' /></div>
            </div>
        )
    );
});