import React, { useEffect, useState } from "react";
import '../../SASS/NavBar.scss';
import '../../SASS/Util/_allUtils.scss';
import { useStore } from "../stores/Store";
import { observer } from "mobx-react-lite";
import { NavLink, useLocation } from "react-router-dom";
import NavItem from "../ui/NavItem";
import Alert from "../ui/Alert";
import ReactGA from 'react-ga4';



export default observer(function NavBar() {
    const {viewStore, navStore, modalStore, loginStore, snackbarStore, settingsStore} = useStore();
    const {scrollY, viewWidth} = viewStore;
    const {isOpen, setIsOpen} = navStore;
    const {setModalVariant} = modalStore;
    const {isLoggedIn, setLogin, isAdmin, setIsAdmin} = loginStore;
    const {setSnackbar} = snackbarStore;
    const {mode, contentLevel} = settingsStore;
    // let location = useLocation();
    const [alert, setAlert] = useState<boolean>(false);
    // const [alertUserClosed, setAlertUserClosed] = useState<boolean>(false);

    // useEffect(() => {
    //     if (location.pathname.startsWith('/work') && !alertUserClosed && contentLevel ==='summary') {
    //         setAlert(true);
    //     } else {
    //         setAlert(false);
    //     }
    // },[location])
    

    // function handleAlertClose(){
    //     setAlert(false);
    //     setAlertUserClosed(true);
    // }

    //this handles all of the scroll behavior for the primary nav
    let scroll = scrollY / 112;

    let navBKG = -56;
    let logoHeight = 48;
    let padding = 16;

    if (scroll > 1) {
        scroll = 1
    } 

    navBKG = navBKG + (scroll * 56);
    logoHeight = logoHeight - (scroll * 8);
    padding = padding - (scroll * 12);

    //this interfaces with the NavStore to set whether the nav is open or closed at smaller breakpoints
    //also watches the window resize to handle when you open the menu and then resize the screen
    const htmlTag = document.getElementById('body');
    function handleSetMenuOpenSM(toggleOff:boolean) {
        if (toggleOff) {
            setIsOpen(false);
            htmlTag && htmlTag.classList.remove('stop-scroll');
        } else {
            setIsOpen(true);
            htmlTag && htmlTag.classList.add('stop-scroll');
        }
    }

    // window.addEventListener('resize', () => setWindowWidth(window.innerWidth),  { passive: true });
    if (viewWidth > 900) {
        setIsOpen(false);
        htmlTag && htmlTag.classList.remove('stop-scroll');
    }

    //this checks if the user is logged in and switches the login to logout and handles the log out experience.
    function handleLogOut() {
        setLogin(false);
        setIsAdmin(false);
        setSnackbar("success", "You've successfully logged out! Goodbye!");
    }

    function handleLoginButtonClick() {
        setModalVariant('login');
    }
    function handleContactButtonClick() {
        setModalVariant('contact');
    }
    function handleSettingsButtonClick() {
        setModalVariant('settings');
    }
    function handleDownloadButtonClick() {
        ReactGA.event({
            category: "download",
            action: "download resume",
            label: "from nav",
          });
    }

    let adminLinks = <>
        <NavItem text="Design System" type="link-internal" to={'/nmcd-designsystem'} onClick={() => handleSetMenuOpenSM(true)} />
    </>;


    return (
        <>
            {/* {(alert)?(
                <Alert 
                text="You are viewing summaries of case studies. You can change your Level of Detail preference in Settings." 
                variant="info" 
                level="global" 
                isDismissable
                onClose={handleAlertClose}
                />
            ):(<></>)} */}
            <header className={"nmcd-site-header" + ((mode === 'darkmode')?(' dark-mode'):(""))} style={{paddingTop: padding+'px', paddingBottom: padding+'px', top:((alert)?("72px"):("0"))}}>
                <div className="header-bkg" style={{top: navBKG+'px'}}></div>
                <div className="header-content-container">
                    <div className="logoitem-container">
                        <NavLink to={"/"} className="navitem-logo" aria-labelledby="logo-label" onClick={() => handleSetMenuOpenSM(true)}>
                            {(mode === 'darkmode')?(
                                <>
                                    <img src="/IMGS/Brand/New Logo-Light@2x.webp" style={{height: logoHeight+'px'}} alt="NMCD Logo" />
                                    <img src="/IMGS/Brand/New Logo-light rainbow@2x.webp" style={{height: logoHeight+'px'}} alt="Rainbow NMCD Logo" />
                                </>
                            ):(
                                <>
                                    <img src="/IMGS/Brand/New Logo@2x.webp" style={{height: logoHeight+'px'}} alt="NMCD Logo" />
                                    <img src="/IMGS/Brand/New Logo-dark rainbow@2x.webp" style={{height: logoHeight+'px'}} alt="Rainbow NMCD Logo" />
                                </>
                            )}
                            <span hidden id="logo-label">NMChivers Design</span>
                        </NavLink>
                    </div>
                    <div className="menu-button-container">
                        <button className={"menu-button " + (isOpen && " close")} aria-labelledby="menu-button-label" onClick={() => handleSetMenuOpenSM(isOpen)}>
                            <div className="dash-1"></div>
                            <div className="dash-2"></div>
                            <div className="dash-3"></div>
                            <span hidden id="menu-button-label">Menu</span>
                        </button>
                    </div>
                    <div id="navitems" className={"navitems-container " + (isOpen && " is-open")}>
                        {isAdmin ? (adminLinks):(<></>)}
                        <NavItem text="Work" type="link-internal" to={'/work'} onClick={() => handleSetMenuOpenSM(true)}/>
                        <NavItem text="Nick" type="link-internal" to={'/nick'} onClick={() => handleSetMenuOpenSM(true)}/>
                        <NavItem text="Resume" type="download" to={'/Resume/NickChiversResume' + ((mode === 'darkmode')?('-Dark.pdf'):('.pdf'))} download="NMChivers-Resume.pdf" icon="bi-file-earmark-arrow-down" onClick={()=>handleDownloadButtonClick()}/>
                        <NavItem text="Contact" type="button" onClick={() => handleContactButtonClick()} />
                        <NavItem text="Settings" type="button" onClick={() => handleSettingsButtonClick()} />
                        {isLoggedIn ? (
                            <NavItem text="Logout" type="button" onClick={handleLogOut} />
                        ):(
                            <NavItem text="Login" type="button" onClick={() => handleLoginButtonClick()} />
                        )}
                    </div>
                </div>
            </header>
        </>
    )
})