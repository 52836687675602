import React, { AnchorHTMLAttributes, ReactNode } from "react";
import "../../SASS/NLink.scss";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useStore } from "../stores/Store";

interface LinkProps extends NavLinkProps {
    content: string;
    variant?: "default" | "locked" | "external" | "button-filled" | "button-outline" | "linkedin" | "coming-soon" | "download-button-filled";
    addClasses?: string;
}

function NLink({content = "I'm a link!", variant = "default", addClasses = "", ...props}:LinkProps) {
    const {loginStore, modalStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setModalVariant} = modalStore;

    let nLink = <></>;

    if (variant === (undefined || "default")) {
        return (
            <NavLink className={"link-default " + ((addClasses != "")?(addClasses):(""))} {...props}>
                {content}
                <i className="bi-arrow-right"></i>
            </NavLink>
        )
    } else if (variant === "locked") {
        if (isLoggedIn) {
            return (
                <NavLink className={"link-locked " + ((addClasses != "")?(addClasses):(""))} {...props}>
                    <i className="bi-unlock"></i>
                    {content}
                    <i className="bi-arrow-right"></i>
                </NavLink>
            )
        } else {
            return (
                <button className={"link-locked " + ((addClasses != "")?(addClasses):(""))} onClick={() => setModalVariant('login')} >
                    <i className="bi-lock"></i>
                    {content}
                    <i className="bi-box-arrow-in-up-right"></i>
                </button>
                // <NavLink className={"link-locked " + ((addClasses != "")?(addClasses):(""))} {...props}>
                //     <i className="bi-lock"></i>
                //     {content}
                //     <i className="bi-arrow-right"></i>
                // </NavLink>
            )
        }
    } else if (variant === "external") {
        return (
            <NavLink className={"link-external " + ((addClasses != "")?(addClasses):(""))} {...props}>
                {content}
                <i className="bi-box-arrow-up-right"></i>
            </NavLink>
        )
    } else if (variant === "button-filled") {
        return (
            <NavLink className={"link-button-filled " + ((addClasses != "")?(addClasses):(""))} {...props}>
                {content}
            </NavLink>
        )
    } else if (variant === "button-outline") {
        return (
            <NavLink className={"link-button-outline " + ((addClasses != "")?(addClasses):(""))} {...props}>
                {content}
            </NavLink>
        )
    } else if (variant === "linkedin") {
        return (
            <a className={"link-linkedin icon-only" + ((addClasses != "")?(addClasses):(""))} title={content} target="_blank" href={props.to.toString()}>
                <i className="bi-linkedin"></i>
            </a>
        )
    } else if (variant === "coming-soon") {
        return (
            <p className={"link-coming-soon " + ((addClasses != "")?(addClasses):(""))}>
                <i className="bi-clock-history"></i>
                {content}
            </p>
        )
    } else if (variant === "download-button-filled") {
        nLink = 
            <a className={"link-button-filled has-icon" + ((addClasses != "")?(addClasses):(""))} target="_blank" download={props.download} href={props.to.toString()} onClick={props.onClick}>
                {content}
                <i className="bi-file-earmark-arrow-down"></i>
            </a>
    }

    return(nLink)
}

export default NLink;