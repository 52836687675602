import React from 'react';
import { Recommendation } from '../models/Recommendation';
import RecommendationCard from './RecommendationCard';

interface Props {
    recs: Recommendation[];
    addClasses?: string;
}

export default function RecommendationList({recs, addClasses}: Props){
    return (
        <>
            {recs.map(rec => (
                <RecommendationCard 
                    key={rec.key}
                    rec={rec}
                    addClasses={addClasses && addClasses}
                />
            ))}
        </>
    )
}