import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import './../../SASS/SettingsForm.scss';
import { observer } from "mobx-react-lite";
import RadioGroup from "../ui/RadioGroup";
import RadioButton from "../ui/RadioButton";
import Button from "../ui/Button";
import { useStore } from "../stores/Store";
import ReactGA from 'react-ga4';

interface Props {
    returnLink?: string;
    isModal?: boolean;
}

export default observer(function SettingsForm({returnLink=undefined, isModal=true}:Props){
    const {modalStore, snackbarStore, settingsStore} = useStore();
    const {setModalVariant} = modalStore;
    const {setSnackbar} = snackbarStore;
    const {mode, contentLevel, setMode, setContentLevel} = settingsStore;
    
    const [modeRadio, setModeRadio] = useState<string>("");
    const [contentLevelRadio, setContentLevelRadio] = useState<string>("");

    useEffect(() => {
        setModeRadio(mode);
        setContentLevelRadio(contentLevel);
        ReactGA.event({
            category: "settings",
            action: 'open settings',
          });
    },[])

    function handleRadioChange(event: ChangeEvent<HTMLInputElement>){
        const {value, name} = event.target;
        if (name === "mode") {
            setModeRadio(value);
        } else if (name === "contentlevel") {
            setContentLevelRadio(value);
        }
    }

    function handleCancel(isInModal:boolean) {
        if (isInModal) {
            setModalVariant(undefined)
        }

        ReactGA.event({
            category: "settings",
            action: 'cancel settings',
          });

        //do something else if the form isn't in a modal
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setMode(modeRadio);
        setContentLevel(contentLevelRadio);
        if (isModal) {
            setModalVariant(undefined)
        }
        setSnackbar('success', "Your settings have been updated!");
        ReactGA.event({
            category: "settings",
            action: 'settings submitted',
          });
    }

    return(
        <div className='grid-x-has-gutters' >
            <div className='small-12'>
                <h2 className='type-heading-2 margin-b-2'>Settings</h2>
                <p className="type-body margin-b-3">Customize your experience by changing the settings below.</p>
            </div>
            <form className="small-12 settings-form" name='contactForm' onSubmit={(e) => handleSubmit(e)}>
                <RadioGroup id="mode" label="Mode" onChange={handleRadioChange} isRequired addClasses="settings-mode-radio-group">
                    <RadioButton 
                        id="darkmode" 
                        name="mode" 
                        label="Dark" 
                        value="darkmode" 
                        checked={modeRadio === 'darkmode'}
                        autoFocus />
                    <RadioButton 
                        id="lightmode" 
                        name="mode" 
                        label="Light" 
                        value="lightmode" 
                        checked={modeRadio === 'lightmode'} />
                </RadioGroup>

                <RadioGroup id="contentlevel" label="Level of detail" explanation="Some of my case studies can be viewed at different levels of detail to help you get what you need from them." onChange={handleRadioChange} isRequired addClasses="settings-content-radio-group">
                    <RadioButton 
                        id="summary" 
                        name="contentlevel" 
                        label="Summary only" 
                        explanation="At this level, you will have a bulleted summary of the project which supports the images and screen shots for the piece." 
                        value="summary" 
                        checked={contentLevelRadio === 'summary'} />
                    <RadioButton 
                        id="detail" 
                        name="contentlevel" 
                        label="All of the detail"
                        explanation="This option gives you the full case study, broken down by phase, with images and screen shots to support the copy." 
                        value="detail" 
                        checked={contentLevelRadio === 'detail'} />
                </RadioGroup>

                <div className="button-group">
                    <Button text='Submit' type='submit' variant='filled' />
                    <Button text='Cancel' type='button' variant='outline' onClick={() => handleCancel(isModal)}/>
                </div>
            </form>
        </div>
    )
})