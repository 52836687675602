import React, { useEffect, useState } from 'react';
//import '../../SASS/FoundationStyles.scss';
import '../../SASS/GlobalStyles.scss';
import { useStore } from '../stores/Store';
import { observer } from 'mobx-react-lite';
import NavBar from './NavBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import ScrollingBackground from './ScrollingBackground';
import Modals from '../components/Modals';
import SnackBar from '../ui/Snackbar';
import ReactGA from 'react-ga4';


function App() {
  const {viewStore, modalStore, snackbarStore, settingsStore} = useStore();
  const {setScrollY, setViewHeight, setViewWidth} = viewStore;
  const {modalVariant} = modalStore;
  const {variant} = snackbarStore;
  const {mode, setBy} = settingsStore;

  function handleScrollResize() {
    setScrollY();
    setViewHeight();
    setViewWidth();
  }

  window.addEventListener("scroll", () => handleScrollResize(), { passive: true });
  window.addEventListener('resize', () => handleScrollResize(),  { passive: true });

  useEffect(()=>{
    // if (process.env.REACT_APP_GA_ID != undefined) {
    //   ReactGA.initialize(process.env.REACT_APP_GA_ID);
    if (setBy === 'system') {
      ReactGA.event({
        category: "mode",
        action: 'mode set',
        label: "initial " + mode,
      });
    }
  },[]);

  return (
    <>
      <ScrollingBackground />
      <NavBar />
      <div className={'nmcd-page-container' + ((mode === 'darkmode')?(' dark-mode'):(''))}>
        <Outlet />
      </div>
      <Footer />
      {(modalVariant != undefined)?(
        <Modals selectedModalVariant={modalVariant} />
      ):(<></>)}
      {(variant != undefined)?(
        <SnackBar />
      ):(<></>)}
      
    </>
  );
}

export default observer(App);
