import React from 'react';
import './../../SASS/Loader.scss';

interface Props {
    size: "small" | "large";
    text?: string;
    color: string;
}

export default function Loader({size = "small", text = "", color = "neutral-100"}: Props){
    let loader = <></>;

    if (size === "small") {
        let classes = "loader-small loader-" + color;
        loader = <span className={classes}></span>
    }

    return (loader)
}