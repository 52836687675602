import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function PipelineImages({imageGroup}: Props){
    let exploration1:Image[] = [{
        key: "1.1",
        imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline OG Dec 21 Actual.png",
        imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline OG Dec 21 Actual.png",
        imgAltText: "The Original Estimate Board Design"
    }];
    if (imageGroup === "exploration1") {
        return(<ImageGrid size='medium' images={exploration1}/>)
    }

    let exploration2:Image[] = [{
        key: "2.1",
        imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline workflow competitve analysis.png",
        imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline workflow competitve analysis.png",
        imgAltText: "A Competitive Analysis Focused on Workflows"
    }];
    if (imageGroup === "exploration2") {
        return(<ImageGrid size='medium' images={exploration2}/>)
    }

    let exploration3:Image[] = [{
        key: "3.1",
        imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Workflow Comparison Graphic.png',
        imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Workflow Comparison Graphic.png',
        imgAltText:'A Comparison of the Original Workflow and Mine'
    }];
    if (imageGroup === "exploration3") {
        return(<ImageGrid size='medium' images={exploration3}/>)
    }

    let allExploration:Image[] = exploration1.concat(exploration2).concat(exploration3);
    if (imageGroup === "allExploration") {
        return(<ImageGrid size='small' images={allExploration}/>)
    }



    let wireframe1:Image[] = [
        {
            key: '1.1',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Wires Exploration A.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Wires Exploration A.png',
            imgAltText:'Wireframe Exploration A'
        },
        {
            key: '1.2',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Wires Exploration B.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Wires Exploration B.png',
            imgAltText:'Wireframe Exploration B'
        },
        {
            key: '1.3',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Wires Exploration C.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Wires Exploration C.png',
            imgAltText:'Wireframe Exploration C'
        },
        {
            key: '1.4',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Wires Exploration F.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Wires Exploration F.png',
            imgAltText:'Wireframe Exploration F'
        },
    ];
    if (imageGroup === "wireframe1") {
        return(<ImageGrid size='medium' images={wireframe1}/>)
    }

    let wireframe2:Image[] = [
        {
            key: '2.1',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Pair.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Pair.png',
            imgAltText:'Options Used in the Preference Test'
        },
        {
            key: '2.2',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Gif.gif',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Gif.gif',
            imgAltText:'The Navigation Preference Test'
        },
    ];
    if (imageGroup === "wireframe2") {
        return(<ImageGrid size='small' images={wireframe2}/>)
    }

    let wireframe3:Image[] = [
        {
            key: '3.1',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results 2.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results 2.png',
            imgAltText:'Navigation Results for Option A (Primary Nav Consolidation)'
        },
        {
            key: '3.2',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results 3.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results 3.png',
            imgAltText:'Intuitiveness Results for Option A (Primary Nav Consolidation)'
        },
        {
            key: '3.3',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results 4.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results 4.png',
            imgAltText:'Navigation Results for Option B (On Screen Drop Down)'
        },
        {
            key: '3.4',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results 5.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results 5.png',
            imgAltText:'Intuitiveness Results for Option B (On Screen Drop Down)'
        },
        {
            key: '3.5',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results 6.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results 6.png',
            imgAltText:'Navigation Preference Results'
        },
        {
            key: '3.6',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Navigation Usability Test w Maze Results Summary.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Navigation Usability Test w Maze Results Summary.png',
            imgAltText:'Navigation Preference Test Summary'
        },
    ];
    if (imageGroup === "wireframe3") {
        return(<ImageGrid size='small' images={wireframe3}/>)
    }

    let wireframe4:Image[] = [
        {
            key: '4.1',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Preference Test w Maze Card Content 1.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Card Preference Test w Maze Card Content.png',
            imgAltText:'Card Data Elements'
        },
        {
            key: '4.2',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Preference Test w Maze Card Options 1.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Card Preference Test w Maze Card Options.png',
            imgAltText:'Information Architecture Options'
        },
        {
            key: '4.3',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Preference Test w Maze Gif.gif',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Card Preference Test w Maze Gif.gif',
            imgAltText:'Card Preference Test'
        },
        {
            key: '4.4',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Preference Test w Maze Results 1 1.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Card Preference Test w Maze Results 1.png',
            imgAltText:'Card Preference Test Results'
        },
        {
            key: '4.5',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Preference Test w Maze Results 3 1.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Card Preference Test w Maze Results 3.png',
            imgAltText:'Data Relevance Results'
        },
    ];
    if (imageGroup === "wireframe4") {
        return(<ImageGrid size='small' images={wireframe4}/>)
    }

    let wireframe5:Image[] = [
        {
            key: '5.1',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Schedule Quick Action.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Schedule Quick Action.png',
            imgAltText:'Schedule a Job Quick Action Dialog'
        },
        {
            key: '5.2',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Unschedule Quick Action.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Unschedule Quick Action.png',
            imgAltText:'Unschedule a Job Quick Action Dialog'
        },
        {
            key: '5.3',
            imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Send Invoice Quick Action.png',
            imgLarge: './../IMGS/Projects/HCPPipeline/Pipeline Send Invoice Quick Action.png',
            imgAltText:'Send an Invoice Quick Action Dialog'
        },
    ];
    if (imageGroup === "wireframe5") {
        return(<ImageGrid size='small' images={wireframe5}/>)
    }

    let allWireframe:Image[] = wireframe1.concat(wireframe2).concat(wireframe3).concat(wireframe4).concat(wireframe5);
    if (imageGroup === "allWireframe") {
        return(<ImageGrid size='small' images={allWireframe}/>)
    }



    let visd1:Image[] = [
        {
            key: "1.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/PipelineOGNovDecComparisonActual.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/PipelineOGNovDecComparisonActual.png",
            imgAltText: "Incrimental Updates to the Alpha Phase Estimate Board",
        },
    ];
    if (imageGroup === "visd1") {
        return(<ImageGrid size='medium' images={visd1}/>)
    }

    let visd2:Image[] = [
        {
            key: "2.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline visual competitve analysis.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline visual competitve analysis.png",
            imgAltText: "Visual Inspiration and Competitive Analysis",
        },
    ];
    if (imageGroup === "visd2") {
        return(<ImageGrid size='medium' images={visd2}/>)
    }

    let visd3:Image[] = [
        {
            key: "3.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Exploration A.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Exploration A.png",
            imgAltText: "Visual Design Exploration A",   
        },
        {
            key: "3.2",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Exploration B.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Exploration B.png",
            imgAltText: "Visual Design Exploration B",  
        },
        {
            key: "3.3",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Exploration C.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Exploration C.png",
            imgAltText: "Visual Design Exploration C",
        },
    ];
    if (imageGroup === "visd3") {
        return(<ImageGrid size='medium' images={visd3}/>)
    }

    let visd4:Image[] = [
        {
            key: "4.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Exploration Preferred.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Exploration Preferred.png",
            imgAltText: "Preferred Visual Design",
        },
    ];
    if (imageGroup === "visd4") {
        return(<ImageGrid size='medium' images={visd4}/>)
    }

    let visd5:Image[] = [
        {
            key: "5.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Final Base.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Final Base.png",
            imgAltText: "Final Design for Pipeline Boards",
        },
        {
            key: "5.2",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Final Scrolled Up.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Final Scrolled Up.png",
            imgAltText: "Final Design Scrolled Up State",
        },
        {
            key: "5.3",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Final Scrolled Right.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Final Scrolled Right.png",
            imgAltText: "Final Design Scrolled Right State",
        },
        {
            key: "5.4",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Final Drop Zones.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Final Drop Zones.png",
            imgAltText: "Final Design of the Drop Zones",
        },
        {
            key: "5.5",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Quick Actions Scheduling.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Quick Actions Scheduling.png",
            imgAltText: "Final Design for Scheduling Quick Action Dialog",
        },
        {
            key: "5.6",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Drag and Drop Prototype Gif.gif",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Drag and Drop Prototype Gif.gif",
            imgAltText: "Final Design for the Drag and Drop Functionality",
        },
        {
            key: "5.7",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Visual Design Board Red Line.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Visual Design Board Red Line.png",
            imgAltText: "Red Line of the Final Board Design",
        },
    ];
    if (imageGroup === "visd5") {
        return(<ImageGrid size='small' images={visd5}/>)
    }

    let visd6:Image[] = [
        {
            key: "6.1",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline component library 1.png",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline component library.png",
            imgAltText: "Pipeline Design System Library",
        },
    ];
    if (imageGroup === "visd6") {
        return(<ImageGrid size='medium' images={visd6}/>)
    }

    let allvisd:Image[] = visd1.concat(visd2).concat(visd3).concat(visd4).concat(visd5).concat(visd6);
    if (imageGroup === "allvisd") {
        return(<ImageGrid size='small' images={allvisd}/>)
    }


    let usability1:Image[] = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPPipeline/UsabilityTesting Screen Shot.png",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/UsabilityTesting Screen Shot.png",
            imgAltText: "Usability Testing Session",
        },
    ];
    if (imageGroup === "usability1") {
        return(<ImageGrid size='medium' images={usability1}/>)
    }
    if (imageGroup === "allusability") {
        return(<ImageGrid size='small' images={usability1}/>)
    }


    let desqa1:Image[] = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Card Design Review.png",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Card Design Review 1.png",
            imgAltText: "Design QA of the Card Component",
        },
        {
            key: "1.2",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Quick Action Design Review.png",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Quick Action Design Review.png",
            imgAltText: "Design QA of a Quick Action Dialog",
        },
        {
            key: "1.3",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline Drag and Drop Actual Gif.gif",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline Drag and Drop Actual Gif.gif",
            imgAltText: "Functional Test of the Drag and Drop Functionality",
        }
    ];
    if (imageGroup === "desqa1") {
        return(<ImageGrid size='small' images={desqa1}/>)
    }
    if (imageGroup === "alldesqa") {
        return(<ImageGrid size='small' images={desqa1}/>)
    }


    let launch1:Image[] = [
        {
            key: "1.1",
            imgLarge: "./../IMGS/Projects/HCPPipeline/Pipeline with VoIP.gif",
            imgThumb: "./../IMGS/Projects/HCPPipeline/Thumbs/Pipeline with VoIP.gif",
            imgAltText: "Pipeline Functionality with Voice Solution Integration",
        },
    ];
    if (imageGroup === "launch1") {
        return(<ImageGrid size='medium' images={launch1}/>)
    }
    if (imageGroup === "alllaunch") {
        return(<ImageGrid size='small' images={launch1}/>)
    }


    return(<></>)
}


    // let visd1 = [
    //     {
            
    //     },
    // ]

    // [
    //     {
    //         key: '',
    //         imgThumb: './../IMGS/Projects/HCPPipeline/Thumbs/THIS.png',
    //         imgLarge: './../IMGS/Projects/HCPPipeline/THIS.png',
    //         imgAltText:''
    //     },
    // ]