import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function SyncUpsImages({imageGroup}: Props){
    let challenge1: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Old Create Form.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Old Create Form.png",
            imgAltText: "Original Sync-Ups Create Form",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Old Landing Page.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Old Landing Page.png",
            imgAltText: "Original Sync-Ups Card",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Old Details Page.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Old Details Page.png",
            imgAltText: "Original Sync-Up Details Page",
        },
    ];

    let challenge2: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Competitive Analysis Slide 1.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Competitive Analysis Slide 1.png",
            imgAltText: "Competitive Analysis Summary",
        },
    ];

    let redesign1: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Card Exploration Group.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Card Exploration Group.png",
            imgAltText: "Sync-Ups Card Exploration",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Card on Page.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Card on Page.png",
            imgAltText: "Sync-Ups Card Design",
        },
    ];

    let redesign2: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Google Cal Comparison.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Google Cal Comparison.png",
            imgAltText: "Sync-Ups Form Compared to Google Calendar",
        },
    ];

    let redesign3: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Agenda Topic Exploration.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Agenda Topic Exploration.png",
            imgAltText: "Agenda Input Exploration",
        },
    ];

    let redesign4: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps New RT Editor Final.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps New RT Editor Final.png",
            imgAltText: "Rich Text Editor Figma Component",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps New RT Editor Subcomponents.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps New RT Editor Subcomponents.png",
            imgAltText: "Rich Text Editor Figma Subcomponents",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Red Lined Rich Text Field.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Red Lined Rich Text Field.png",
            imgAltText: "Rich Text Editor's Red Line",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Design System Page for Text Inputs.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Design System Page for Text Inputs.png",
            imgAltText: "Text Input Design System Page",
        },
    ];

    let redesign5: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Agenda Topics.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Agenda Topics.png",
            imgAltText: "Agenda Topics Figma Components",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Agenda Items on Create Form.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Agenda Items on Create Form.png",
            imgAltText: "Agenda Topics on the Create Form",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Agenda Items on Card.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Agenda Items on Card.png",
            imgAltText: "Agenda Topics on the Sync-Ups Card",
        },
    ];

    let redesign6: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps File Attachment Component.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps File Attachment Component.png",
            imgAltText: "Attachment Figma Component",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Attachments Experience.gif",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Attachments Experience.gif",
            imgAltText: "Attachment Prototype",
        },
    ];

    let redesign7: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Attendee Detailed View.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Attendee Detailed View.png",
            imgAltText: "Attendee's Sync-Up Details Page",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Attendee Detailed View adding to agenda.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Attendee Detailed View adding to agenda.png",
            imgAltText: "Attendees Updating the Agenda",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Attendee Detailed View adding attachements.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Attendee Detailed View adding attachements.png",
            imgAltText: "Attendees Adding Attachments",
        },
    ];

    let redesign8: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Past SU Widget.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Past SU Widget.png",
            imgAltText: "Past Sync-Ups Card",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps History Empty.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps History Empty.png",
            imgAltText: "Sync-Ups History Page Empty State",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps History Full.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps History Full.png",
            imgAltText: "Sync-Ups History Page",
        },
    ];

    let design1: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Notes States.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Notes States.png",
            imgAltText: "States of the Notes Section",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Create Notes.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Create Notes.png",
            imgAltText: "Creating a Note on the Details Page",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps View Notes.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps View Notes.png",
            imgAltText: "Viewing Multiple Notes on the Details Page",
        },
    ];

    let design2: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Future State Action Items Exploration.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Future State Action Items Exploration.png",
            imgAltText: "Exploration for Future Action Items Module",
        },
    ];

    let design3: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Follow Up States.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Follow Up States.png",
            imgAltText: "States of the Follow-Up Section",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Add Follow Ups on Details Page.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Add Follow Ups on Details Page.png",
            imgAltText: "Creating a Follow-Up on the Details Page",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps View Follow Ups on Details Page.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps View Follow Ups on Details Page.png",
            imgAltText: "Viewing Multiple Follow-Ups on the Details Page",
        },
    ];

    let design4: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Empty FollowUps Widget.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Empty FollowUps Widget.png",
            imgAltText: "Follow-Ups Widget Empty State",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps One FollowUps.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps One FollowUps.png",
            imgAltText: "Follow-Ups Widget with an Item",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Five FollowUps.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Five FollowUps.png",
            imgAltText: "Follow-Ups Widget at Max Capacity",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps FollowUps Home Empty.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps FollowUps Home Empty.png",
            imgAltText: "Follow-Ups Empty Landing Page",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps FollowUps Home Full.png",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps FollowUps Home Full.png",
            imgAltText: "Follow-Ups Landing Page",
        },
    ];

    let prototype1: Image[] = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Full Create Flow.gif",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Full Create Flow.gif",
            imgAltText: "Sync-Ups Creation Flow",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Attendee Flow.gif",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Attendee Flow.gif",
            imgAltText: "Attendee's Sync-Up Experience",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/SyncUps/SyncUps Owner Attendee Flow.gif",
            imgThumb: "./../IMGS/Projects/SyncUps/Thumbs/SyncUps Owner Attendee Flow.gif",
            imgAltText: "Owner's Sync-Up Experience",
        },
    ];
    

    if (imageGroup === "challenge1") {
        return (<ImageGrid size='small' images={challenge1} />)
    }
    if (imageGroup === "challenge2") {
        return (<ImageGrid size='medium' images={challenge2} />)
    }

    let allchallenge: Image[] = challenge1.concat(challenge2);
    if (imageGroup === "allchallenge") {
        return (<ImageGrid size='small' images={allchallenge} />)
    }


    if (imageGroup === "redesign1") {
        return (<ImageGrid size='small' images={redesign1} />)
    }
    if (imageGroup === "redesign2") {
        return (<ImageGrid size='medium' images={redesign2} />)
    }
    if (imageGroup === "redesign3") {
        return (<ImageGrid size='medium' images={redesign3} />)
    }
    if (imageGroup === "redesign4") {
        return (<ImageGrid size='small' images={redesign4} />)
    }
    if (imageGroup === "redesign5") {
        return (<ImageGrid size='small' images={redesign5} />)
    }
    if (imageGroup === "redesign6") {
        return (<ImageGrid size='small' images={redesign6} />)
    }
    if (imageGroup === "redesign7") {
        return (<ImageGrid size='small' images={redesign7} />)
    }
    if (imageGroup === "redesign8") {
        return (<ImageGrid size='small' images={redesign8} />)
    }

    let allredesign: Image[] = redesign1.concat(redesign2).concat(redesign3).concat(redesign4).concat(redesign5).concat(redesign6).concat(redesign7).concat(redesign8);
    if (imageGroup === "allredesign") {
        return (<ImageGrid size='small' images={allredesign} />)
    }


    if (imageGroup === "design1") {
        return (<ImageGrid size='small' images={design1} />)
    }
    if (imageGroup === "design2") {
        return (<ImageGrid size='medium' images={design2} />)
    }
    if (imageGroup === "design3") {
        return (<ImageGrid size='small' images={design3} />)
    }
    if (imageGroup === "design4") {
        return (<ImageGrid size='small' images={design4} />)
    }

    let alldesign: Image[] = design1.concat(design2).concat(design3).concat(design4);
    if (imageGroup === "alldesign") {
        return (<ImageGrid size='small' images={alldesign} />)
    }


    if (imageGroup === "prototype1") {
        return (<ImageGrid size='small' images={prototype1} />)
    }

    if (imageGroup === "allprototype") {
        return (<ImageGrid size='small' images={prototype1} />)
    }

    return (<></>);
}