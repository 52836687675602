import { makeAutoObservable } from "mobx";

export default class LoginStore {
    isLoggedIn:boolean = (localStorage.getItem('isLoggedIn') === 'true') || false;
    isAdmin: boolean = (localStorage.getItem('isA') === 'true') || false;

    constructor() {
        makeAutoObservable(this)
    }

    setLogin = (loggedIn:boolean) => {
        this.isLoggedIn = loggedIn;
        localStorage.setItem('isLoggedIn', String(this.isLoggedIn))
    }

    setIsAdmin = (admin:boolean) => {
        this.isAdmin = admin;
        localStorage.setItem('isA', String(this.isAdmin))
    }
}