import React, { useEffect } from "react";
import NLink from "../ui/NLink";
import './../../SASS/AboutMe.scss';
import EducationCard from "../components/EducationCard";
import ImageGrid from "../components/ImageGrid";
import { Image } from "../models/Image";
import { Recommendations } from "../data/Recommendations";
import RecommendationCard from "../components/RecommendationCard";
import AwardCardList from "../components/AwardCardList";
import SkillCardList from "../components/SkillCardList";
import { Helmet } from "react-helmet";
import FeaturedWork from "../components/FeaturedWork";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/Store";
import ReactGA from 'react-ga4';

export default observer(function AboutMe(){
    const {settingsStore} = useStore();
    const {mode} = settingsStore;

    useEffect(() => {
        window.scrollTo(0, 0);
        // ReactGA.send({ hitType: "pageview", page: "/nick", title: "About Nick" });
      }, [])
      
    let images: Image[] = [
        {
            key: 'interest1',
            imgThumb: './IMGS/Interests/Thumbs/beingoutside.webp',
            imgLarge: './IMGS/Interests/beingoutside.jpg',
            imgAltText: 'I love being outside!'
        },
        {
            key: 'interest2',
            imgThumb: './IMGS/Interests/Thumbs/hank2.webp',
            imgLarge: './IMGS/Interests/hank2.jpg',
            imgAltText: 'This is my cat Hank'
        },
        {
            key: 'interest3',
            imgThumb: './IMGS/Interests/Thumbs/remy1.webp',
            imgLarge: './IMGS/Interests/remy1.jpg',
            imgAltText: 'This is my cat Remy'
        },
        {
            key: 'interest4',
            imgThumb: './IMGS/Interests/Thumbs/traveling1.webp',
            imgLarge: './IMGS/Interests/traveling1.jpg',
            imgAltText: 'I really enjoy traveling!'
        },
        {
            key: 'interest5',
            imgThumb: './IMGS/Interests/Thumbs/pokemon.webp',
            imgLarge: './IMGS/Interests/pokemon.jpg',
            imgAltText: "Gotta Catch'em All!"
        },
        {
            key: 'interest6',
            imgThumb: './IMGS/Interests/Thumbs/workingout.webp',
            imgLarge: './IMGS/Interests/workingout.jpg',
            imgAltText: 'Working out is a new passion of mine'
        }
    ]

    function handleDownloadButtonClick() {
        ReactGA.event({
            category: "download",
            action: "download resume",
            label: "from about hero",
          });
    }


    return(
        <>
            <Helmet>
                <title>About Nick | NMChivers Design</title>
                <meta name="og:title" content="About Nick | NMChivers Design" />
                <meta name="description" content="Hi, I'm Nick Chivers, a multifaceted, product designer and creative leader that blends the boundaries between logical analysis and creative thinking to deliver exceptional work for top Fortune 500 companies and startups alike." />
                <meta name="og:description" content="Hi, I'm Nick Chivers, a multifaceted, product designer and creative leader that blends the boundaries between logical analysis and creative thinking to deliver exceptional work for top Fortune 500 companies and startups alike." />
                <meta name="og:url" content="https://nmchivers.design/nick" />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Headshot/nick.webp" />
            </Helmet>
            <div className="grid-x-has-gutters about-me-hero-block">
                <div className="hide-for-small medium-1 large-1"></div> 

                <div className="small-12 medium-10 large-6 about-me-hero-content">
                    <h1 className="type-heading-1">Hi, I'm Nick!</h1>
                    <p className="type-subhead-2">As a product designer, my approach is a more wholistic one which incorporates all aspects of user research, product strategy, UX design, UI design, and front-end development.</p>
                    <p className="type-subhead-2">As a leader of design teams, I focus on growing the individual, looking for ways they can help each other improve, and creating an inclusive environment that enables them to be their best selves in order to do their best work.</p>
                    <NLink content="Download My Resume" variant="download-button-filled" to={'../Resume/NickChiversResume' + ((mode === 'darkmode')?('-Dark.pdf'):('.pdf'))} download="NMChivers-Resume.pdf" onClick={()=>handleDownloadButtonClick()}/>
                </div>

                <div className="medium-1 hide-on-large-up"></div>

                
                <div className="medium-2 hide-on-large-up"></div>

                <div className="small-12 medium-8 large-4">
                    <img src="./IMGS/Headshot/nick@0.5x.webp" alt="Nick's Headshot" />
                </div>

                <div className="hide-for-small medium-2 large-1"></div> 
            </div>
            <div className="grid-x-has-gutters">
                <div className="hide-for-small medium-1"></div>
                <div className="small-12 medium-10">
                    <h2 className="type-heading-2 margin-b-2">My Backstory</h2>
                </div>
                <div className="hide-for-small medium-1"></div>

                <div className="hide-for-small medium-1 large-1"></div>
                <div className="small-12 medium-10 large-6">
                    <p className="type-body margin-b-3">I grew up in a small town in central Illinois where I sang in school musicals, played the trumpet in band, and competed in speech tournaments. I had a lot of great experiences growing up there. But being out as a gay individual in rural America wasn't always easy. This contrast of good experiences and challenging ones pushed me to seek out a more inclusive and vibrant environment.</p>
                    <p className="type-body margin-b-3">So, after college, I made the leap and moved to Chicago. It was the first time I felt like I could really spread my wings and be me. And, for over a decade, Chicago was home. I made lifelong friends and had a ton of amazing experiences that helped mold me into who I am today. But, as much as I loved the city, I couldn't quite cozy up to its frigid winters.</p>
                    <p className="type-body">In search of milder climes and a fresh new chapter, I packed up my two cats and we followed the call of the west coast and landed in beautiful San Diego. Now, surrounded by sun-soaked beaches and a relaxed atmosphere, I'm eagerly embracing new horizons and continuing to grow and evolve.</p>
                    <div className="hide-on-large-up education-section minor-content-section">
                        <h3 className="type-heading-3 margin-b-2">Education</h3>
                        <EducationCard variant="millikin" />
                        <EducationCard variant="design-lab" />
                    </div>
                    <h3 className="type-heading-3 margin-b-2 minor-content-section">Interests & Hobbies</h3>
                    <p className="type-body margin-b-4">My interests span a wide spectrum. I’m a huge fan of the Pokémon universe so its not uncommon for me to spend the afternoon chatting with friends while exploring and battling in the latest games.  I also find joy in exploring the world around me with friends and family, trying new foods, and traveling to new places. I’m also very committed to furthering my holistic well-being through a rigorous fitness routine which sees me engaging in regular workouts. I enjoy coding and building things I have designed so I usually have a side project going in my spare time. But, at the end of a long day there is nothing better than relaxing with my two amazing kitties, Hank and Remy.</p>
                    <ImageGrid size="small" images={images} />
                </div>
                <div className="hide-on-medium-down large-4 education-section">
                    <h3 className="type-heading-3 margin-b-2">Education</h3>
                    <EducationCard variant="millikin" />
                    <EducationCard variant="design-lab" />
                </div>
                <div className="hide-for-small medium-1 large 1"></div>
            </div>
            <div className="grid-x-has-gutters major-content-section">
                <div className="hide-for-small medium-1"></div>
                <div className="small-12 medium-10">
                    <h2 className="type-heading-2 margin-b-2">Career</h2>
                    <p className="type-body margin-b-3">I hold a degree in accounting and started my career as a project manager. Not the typical path for a designer, right?</p>
                    <p className="type-body margin-b-3">When I graduated college, I knew I had no desire to prepare taxes or financial statement for the rest of my life. I had learned important skills though. Skills like data analysis, attention to detail, and investigation. These skills served me well as I started managing projects. At that early stage, most of the projects I was managing I was also serving as the business analyst, solution designer, and sometimes the solution developer. In this space I honed my organizational skills, leadership abilities, and became adept at navigating complex challenges and solving complicated problems.</p>
                    <p className="type-body margin-b-3">What I loved about my job was the investigation of the problem, the strategizing ways to solve it, and then creating a solution to improve people’s lives. What I didn’t realize was that that was design. I was doing product design before I even knew what it was. Thankfully, I had some amazing managers and colleagues that supported me as I took classes through General Assembly and Design Lab to learn about design. This marked a pivotal moment in my career journey, as I embraced the opportunity to blend my analytical background with a creative and human-centered approach ultimately leading me to an intensive six month bootcamp that change the course of my career.</p>
                    <p className="type-body margin-b-3">Now an established designer and creative leader, I’ve had the opportunity to create amazing experiences that delighted users and delivered real value to companies such as Housecall Pro, UnitedHealthcare, and BlueCross BlueShield. I’ve also had the privilege to work with, mentor, and even manage some amazingly talented designers. But there's more to explore and I'm excited to see where my career will lead me next!</p>
                </div>
                <div className="hide-for-small medium-1"></div>

                <div className="hide-for-small medium-1"></div>
                <div className="small-12 medium-10 minor-content-section">
                    <h3 className="type-heading-3 margin-b-2">What People Are Saying</h3>
                    <div className="grid-x-has-gutters recs-group-container">
                        {Recommendations.map(rec => (
                            <div key={rec.key} className="medium-12 large-6 recs-container">
                                <RecommendationCard
                                    rec={rec}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="hide-for-small medium-1"></div>

                <div className="hide-for-small medium-1"></div>
                <div className="small-12 medium-10 minor-content-section">
                    <h3 className="type-heading-3 margin-b-2">Awards and Recognition</h3>
                    <AwardCardList />
                </div>
                <div className="hide-for-small medium-1"></div>

                <div className="hide-for-small medium-1"></div>
                <div className="small-12 medium-10 large-7 minor-content-section">
                    <h3 className="type-heading-3 margin-b-2">Primary Skills</h3>
                    <SkillCardList darkMode={(mode === 'darkmode')}/>
                </div>

                <div className="hide-on-small hide-on-large-up medium-1"></div>
                <div className="hide-on-small hide-on-large-up medium-1"></div>

                <div className="small-12 medium-10 large-3 minor-content-section">
                    <h3 className="type-heading-3 margin-b-2">Preferred Tools</h3>
                    <p className="type-body margin-b-1">Here are a few of the tools I am experienced in using in my design and development work:</p>
                    <ul className="type-ul type-body">
                        <li>Figma</li>
                        <li>Sketch</li>
                        <li>Invision</li>
                        <li>UXPin</li>
                        <li>Adobe XD</li>
                        <li>Illustrator</li>
                        <li>Photoshop</li>
                        <li>Maze</li>
                        <li>Usability Hub</li>
                        <li>User Zoom</li>
                        <li>Miro</li>
                        <li>Lucid Chart</li>
                        <li>VS Code</li>
                    </ul>

                    <h3 className="type-heading-3 margin-b-2 minor-content-section">Principle Techniques</h3>
                    <p className="type-body margin-b-1">Below is a sampling of my go-to, tried and tested techniques I am experienced at applying:</p>
                    <ul className="type-ul type-body">
                        <li>User Centered Design</li>
                        <li>User Interviews</li>
                        <li>Competitive Analysis</li>
                        <li>User Story Definition</li>
                        <li>User Flow Definition</li>
                        <li>Information Architecture Definition</li>
                        <li>Accessible Design (WCAG)</li>
                        <li>Android Mobile Design</li>
                        <li>iOS Mobile Design</li>
                        <li>Usability Testing</li>
                        <li>A/B Testing Preference Testing</li>
                        <li>Heuristic Evaluation</li>
                        <li>Design System Development</li>
                        <li>Design Implementation QA</li>
                        <li>Design Documentation</li>
                    </ul>
                </div>
                <div className="hide-for-small medium-1"></div>
            </div>
            <FeaturedWork />
        </>
    )
})