import React, { FieldsetHTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';
import './../../SASS/Inputs.scss';
import RadioButton from './RadioButton';

interface Props {
    id:string;
    label: string;
    explanation?: string;
    isRequired?:boolean;
    errorMessage?:string;
    isInvalid?:boolean;
    addClasses?:string;
    children: ReactNode[];
    onChange: Function;
}

export default function RadioGroup({id, isRequired = false, errorMessage="", isInvalid=false, label="", explanation="", addClasses="", children, onChange}: Props){

    let validationClasses = "";
    if (isInvalid) {
        validationClasses = " error-state";
    }

    let labelElement = <></>;
    if (label !== "" && !isRequired) {
        labelElement = <label className={'type-subhead-2 input-radio-group-label' + validationClasses} htmlFor={id} id={id + "label"}>{label}<span>(optional)</span></label>;
    } else if (label !== "") {
        labelElement = <label className={'type-subhead-2 input-radio-group-label' + validationClasses} htmlFor={id} id={id + "label"}>{label}</label>;
    }

    let explanationElement = <></>;
    if (explanation != "") {
        explanationElement = <p className='type-caption input-radio-group-explanation'>{explanation}</p>
    }

    let errorMessageElement = <></>;
    if (errorMessage !== "") {
        errorMessageElement = <p className={'input-text-errormessage'+ validationClasses}>{errorMessage}</p>;
    }

    return (
        <div className={addClasses}>
            {labelElement}
            {explanationElement}
            <div className='input-radio-group-options-container' role="radiogroup" id={id} aria-labelledby={id + "label"} onChange={(e) => onChange(e)}>
                {children}
            </div>
            {errorMessageElement}
        </ div>
    )
}