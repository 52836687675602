import React from 'react';
import ProjectHighlight from './ProjectHighlight';
import { ProjectInfo } from '../models/ProjectInfo';
import ScrollOn from '../ui/ScrollOn';

interface Props {
    projects: ProjectInfo[];
    home?: boolean;
}

export default function ProjectHighlightList({projects, home}: Props) {
    return (
       <>
            {home ? (
                projects.map(project => (
                    <div key={project.key} className='home-project-block'>
                        <ScrollOn offsetBy={15} offsetByIs='%' >
                            <ProjectHighlight 
                                project={project}
                            />
                        </ScrollOn>
                    </div>
                ))
            ):(
                <div className='grid-x-has-gutters'>
                    {projects.map(project => (
                        <div key={project.key} className='small-12 medium-6 margin-b-4'>
                            <ProjectHighlight 
                                project={project}
                            />
                        </div>
                    ))}
                </div>
                
            )}
       </>
    )
}

//This is another way to build the functional component. When importing it though, you have to put it in curly brackets like this: { ProjectHighlightList }
// export const ProjectHighlightList:React.FC<IProps> = ({projects}) => {
//     return (
//        <div className='container-project-section'>
//            {projects.map(project => (
//                 <ProjectHighlight key={project.key}
//                     project={project}
//                 />
//            ))}
//        </div>
//     )
// }