import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function SocialRetailAppImages({imageGroup}: Props){
    let req1 = [
      {
        key: "1",
        imgLarge: "./../IMGS/Projects/Mavely/MavelyExcelUS.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/MavelyExcelUS.webp",
        imgAltText: "User Stories Created for Mavely",
      },
    ];

    let wires1 = [
      {
        key: "1.01",
        imgLarge: "./../IMGS/Projects/Mavely/0-Home-A.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/0-Home-A.webp",
        imgAltText: "Home Screen Wireframe",
      },
      {
        key: "1.02",
        imgLarge: "./../IMGS/Projects/Mavely/1-My-Team.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/1-My-Team.webp",
        imgAltText: "My Team Screen Wireframe A",
      },
      {
        key: "1.03",
        imgLarge: "./../IMGS/Projects/Mavely/2-Add-To-My-Team.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/2-Add-To-My-Team.webp",
        imgAltText: "Add to My Team Wireframe",
      },
      {
        key: "1.04",
        imgLarge: "./../IMGS/Projects/Mavely/4-My-Team-New-Team-Members.png",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/4-My-Team-New-Team-Members.webp",
        imgAltText: "My New Team Members Wireframe A",
      },
      {
        key: "1.05",
        imgLarge: "./../IMGS/Projects/Mavely/6-My-Team.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/6-My-Team.webp",
        imgAltText: "My Team Wireframe",
      },
      {
        key: "1.06",
        imgLarge: "./../IMGS/Projects/Mavely/7-Grow-My-Team.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/7-Grow-My-Team.webp",
        imgAltText: "Grow My Team Wireframe",
      },
      {
        key: "1.07",
        imgLarge: "./../IMGS/Projects/Mavely/9-My-Team-New-Team-Member.png",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/9-My-Team-New-Team-Member.webp",
        imgAltText: "My New Team Members Wireframe B",
      },
      {
        key: "1.08",
        imgLarge: "./../IMGS/Projects/Mavely/10-My-Account-A.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/10-My-Account-A.webp",
        imgAltText: "My Account Wireframe Version A",
      },
      {
        key: "1.09",
        imgLarge: "./../IMGS/Projects/Mavely/11-My-Account-B.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/11-My-Account-B.webp",
        imgAltText: "My Account Wireframe Version B",
      },
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/Mavely/12-My-Account-C.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/12-My-Account-C.webp",
        imgAltText: "My Account Wireframe Version C",
      },
    ];

    let prototyping1 = [
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/Mavely/WireframesGamification1.gif",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/WireframesGamification1.gif",
        imgAltText: "Prototype Gamification Flow Version A",
      },
      {
        key: "1.2",
        imgLarge: "./../IMGS/Projects/Mavely/WireframesGamification2.gif",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/WireframesGamification2.gif",
        imgAltText: "Prototype Gamification Flow Version B",
      },
    ];

    let prototyping2 = [
      {
        key: "2.1",
        imgLarge: "./../IMGS/Projects/Mavely/WireframesEditProfile.gif",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/WireframesEditProfile.gif",
        imgAltText: "Prototype Edit Profile Interaction",
      },
    ];

    let visd1 = [
      {
        key: "1.01",
        imgLarge: "./../IMGS/Projects/Mavely/Daily Rewards - 1.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Daily Rewards - 1.webp",
        imgAltText: "Check-In Gamification Screen Pre-Check-In",
      },
      {
        key: "1.02",
        imgLarge: "./../IMGS/Projects/Mavely/Daily Rewards - 2.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Daily Rewards - 2.webp",
        imgAltText: "Check-In Gamification Screen Post Check-In",
      },
      {
        key: "1.03",
        imgLarge: "./../IMGS/Projects/Mavely/Daily Rewards - 3.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Daily Rewards - 3.webp",
        imgAltText: "Check-In Gamification Screen Minimized",
      },
      {
        key: "1.04",
        imgLarge: "./../IMGS/Projects/Mavely/DailyRewards.gif",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/DailyRewards.gif",
        imgAltText: "Prototype of Check-In Gamification Experience",
      },
      {
        key: "1.05",
        imgLarge: "./../IMGS/Projects/Mavely/Goal Setting - 0.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Goal Setting - 0.webp",
        imgAltText: "Goal Setting Experience Start",
      },
      {
        key: "1.06",
        imgLarge: "./../IMGS/Projects/Mavely/Goal Setting - 1.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Goal Setting - 1.webp",
        imgAltText: "Set Your Goals Screen",
      },
      {
        key: "1.07",
        imgLarge: "./../IMGS/Projects/Mavely/Goal Setting - 2.png",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/Goal Setting - 2.webp",
        imgAltText: "Goal Progress on My Account Screen",
      },
      {
        key: "1.08",
        imgLarge: "./../IMGS/Projects/Mavely/GoalSetting.gif",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/GoalSetting.gif",
        imgAltText: "Prototype of Goal Setting Experience",
      },
      {
        key: "1.09",
        imgLarge: "./../IMGS/Projects/Mavely/Plaid Integration - 0.png",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/Plaid Integration - 0.webp",
        imgAltText: "Plaid Integration Experience Starting Screen",
      },
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/Mavely/Plaid Integration - 1.png",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/Plaid Integration - 1.webp",
        imgAltText: "Plaid Integration Experience Explanation Screen",
      },
      {
        key: "1.11",
        imgLarge: "./../IMGS/Projects/Mavely/Plaid Integration - 3A.png",
        imgThumb:
          "./../IMGS/Projects/Mavely/Thumb/Plaid Integration - 3A.webp",
        imgAltText: "Plaid Integration Experience Login Screen",
      },
      {
        key: "1.12",
        imgLarge: "./../IMGS/Projects/Mavely/PlaidIntegration.gif",
        imgThumb: "./../IMGS/Projects/Mavely/Thumb/PlaidIntegration.gif",
        imgAltText: "Prototype of Plaid Integration Experience",
      },
    ];

    if (imageGroup === "req1") {
        return (<ImageGrid size='small' images={req1} />)
    }
    if (imageGroup === "allreq") {
        return (<ImageGrid size='small' images={req1} />)
    }
    
    if (imageGroup === "wires1") {
        return (<ImageGrid size='small' images={wires1} />)
    }
    if (imageGroup === "allwires") {
        return (<ImageGrid size='small' images={wires1} />)
    }
    
    if (imageGroup === "prototyping1") {
        return (<ImageGrid size='medium' images={prototyping1} />)
    }
    if (imageGroup === "prototyping2") {
        return (<ImageGrid size='medium' images={prototyping2} />)
    }
    if (imageGroup === "allprototyping") {
        return (<ImageGrid size='small' images={prototyping1.concat(prototyping2)} />)
    }

    if (imageGroup === "visd1") {
        return (<ImageGrid size='small' images={visd1} />)
    }
    if (imageGroup === "allvisd") {
        return (<ImageGrid size='small' images={visd1} />)
    }

    
    return(<></>);
}