import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function InsuranceShoppingImages({imageGroup}: Props){
    let research1 = [
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/1 Plan Type.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/1 Plan Type.webp",
        imgAltText: "Previous Experience Plan Type",
      },
      {
        key: "1.2",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/2 Basic Info.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/2 Basic Info.webp",
        imgAltText: "Previous Experience Basic Information",
      },
      {
        key: "1.3",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/3 Search Results.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/3 Search Results.webp",
        imgAltText: "Previous Experience Search Results",
      },
      {
        key: "1.4",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/4 Choose a Plan.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/4 Choose a Plan.webp",
        imgAltText: "Previous Experience for Plan Selection",
      },
      {
        key: "1.5",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/5 Continue Shopping.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/5 Continue Shopping.webp",
        imgAltText: "Previous Experience for Continue Shopping",
      },
      {
        key: "1.6",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/6 My Account.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/6 My Account.webp",
        imgAltText: "Previous Experience for My Account",
      },
    ];

    let research2 = [
        {
            key: "2.1",
            imgLarge: "./../IMGS/Projects/BCBSInsShopExp/KanbanBoard.png",
            imgThumb:"./../IMGS/Projects/BCBSInsShopExp/Thumbs/KanbanBoard.webp",
            imgAltText: "Redesigned Plan Type Selection",
        }
    ]

    let design1 = [
      {
        key: "1.01",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/7 RSC_MVP_L_Q1_Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/7 RSC_MVP_L_Q1_Base.webp",
        imgAltText: "Redesigned Plan Type Selection",
      },
      {
        key: "1.02",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/7 Mobile RSC_MVP_L_Q1_Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/7 Mobile RSC_MVP_L_Q1_Base.webp",
        imgAltText: "Redesigned Mobile Plan Type Selection",
      },
      {
        key: "1.03",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/7.1 RSC_MVP_L_Q1_U65 Filled.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/7.1 RSC_MVP_L_Q1_U65 Filled.webp",
        imgAltText: "Redesigned Learn More Example",
      },
      {
        key: "1.04",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/7.1 Mobile RSC_MVP_L_Q1_U65 Filled.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/7.1 Mobile RSC_MVP_L_Q1_U65 Filled.webp",
        imgAltText: "Redesigned Mobile Learn More Example",
      },
      {
        key: "1.05",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/8 RSC_MVP_U65_Census.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/8 RSC_MVP_U65_Census.webp",
        imgAltText: "Redesigned Information Gathering Page",
      },
      {
        key: "1.06",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/8 Mobile RSC_MVP_U65_Census.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/8 Mobile RSC_MVP_U65_Census.webp",
        imgAltText: "Redesigned Mobile Information Gathering Page",
      },
      {
        key: "1.07",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/9 RSC_MVP_Loading.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/9 RSC_MVP_Loading.webp",
        imgAltText: "Redesigned Plan Results Loading Screen",
      },
      {
        key: "1.08",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/9 Mobile RSC_MVP_Loading.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/9 Mobile RSC_MVP_Loading.webp",
        imgAltText: "Redesigned Mobile Plan Results Loading Screen",
      },
      {
        key: "1.09",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/10 RSC_MVP_L_PlanResults_U65 Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/10 RSC_MVP_L_PlanResults_U65 Base.webp",
        imgAltText: "Redesigned Plan Search Results",
      },
      {
        key: "1.1",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/10 Mobile RSC_MVP_L_PlanResults_U65 Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/10 Mobile RSC_MVP_L_PlanResults_U65 Base.webp",
        imgAltText: "Redesigned Mobile Plan Search Results",
      },
      {
        key: "1.11",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/11 RSC_MVP_Plan Details_U65 Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/11 RSC_MVP_Plan Details_U65 Base.webp",
        imgAltText: "Redesigned Plan Details Page",
      },
      {
        key: "1.12",
        imgLarge:
          "./../IMGS/Projects/BCBSInsShopExp/11 Mobile RSC_MVP_Plan Details_U65 Base.png",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/11 Mobile RSC_MVP_Plan Details_U65 Base.webp",
        imgAltText: "Redesigned Mobile Plan Details Page",
      },
    ];

    let design2 = [
      {
        key: "2.01",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 1.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 1.webp",
        imgAltText: "Life-Size User Flow Section 1",
      },
      {
        key: "2.02",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 2.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 2.webp",
        imgAltText: "Life-Size User Flow Section 2",
      },
      {
        key: "2.03",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 3.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 3.webp",
        imgAltText: "Life-Size User Flow Section 3",
      },
      {
        key: "2.04",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 4.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 4.webp",
        imgAltText: "Life-Size User Flow Section 4",
      },
      {
        key: "2.05",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 5.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 5.webp",
        imgAltText: "Life-Size User Flow Section 5",
      },
      {
        key: "2.06",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 6.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 6.webp",
        imgAltText: "Life-Size User Flow Section 6",
      },
      {
        key: "2.07",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 7.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 7.webp",
        imgAltText: "Life-Size User Flow Section 7",
      },
      {
        key: "2.08",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 8.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 8.webp",
        imgAltText: "Life-Size User Flow Section 8",
      },
      {
        key: "2.09",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 9.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 9.webp",
        imgAltText: "Life-Size User Flow Section 9",
      },
      {
        key: "2.1",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 10.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 10.webp",
        imgAltText: "Life-Size User Flow Section 10",
      },
      {
        key: "2.11",
        imgLarge: "./../IMGS/Projects/BCBSInsShopExp/User Flow 11.jpg",
        imgThumb:
          "./../IMGS/Projects/BCBSInsShopExp/Thumbs/User Flow 11.webp",
        imgAltText: "Life-Size User Flow Section 11",
      },
    ];

    if (imageGroup === "research1") {
        return (<ImageGrid size='small' images={research1} />)
    }
    if (imageGroup === "research2") {
        return (<ImageGrid size='small' images={research2} />)
    }
    if (imageGroup === "allresearch") {
        return (<ImageGrid size='small' images={research1.concat(research2)} />)
    }

    if (imageGroup === "design1") {
        return (<ImageGrid size='small' images={design1} />)
    }
    if (imageGroup === "design2") {
        return (<ImageGrid size='small' images={design2} />)
    }
    if (imageGroup === "alldesign") {
        return (<ImageGrid size='small' images={design1.concat(design2)} />)
    }
    return(<></>);
}