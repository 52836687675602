import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useStore } from '../stores/Store';
import Button from './Button';
import './../../SASS/Modal.scss';

interface Props {
    children: ReactNode | ReactNode[];
    size: "small" | "large";
    onClose: () => void;
}

export default observer(function Modal({size, children, onClose}: Props) {
    const {modalStore, settingsStore} = useStore();
    const {setModalVariant} = modalStore;
    const {mode} = settingsStore;

    //if (!modalIsOpen) {return(<></>)}

    if (size === 'small') {
        return(
            <div className={'modal-container grid-x-has-gutters'+((mode === 'darkmode')?(" dark-mode"):(""))}>
                <div className='modal-overlay' onClick={() => setModalVariant(undefined)}></div>
                <div className='modal-block'>
                    <Button text='Close Modal' iconOnly icon='bi-x-lg' variant='hollow' addClasses='modal-close' onClick={() => onClose()}/>
                    {children}
                </div>
            </div>
        )
    } else {
        return(
            <div className={'modal-container'+((mode === 'darkmode')?(" dark-mode"):(""))}>
                <div className='modal-block-full'>
                    <Button text='Close Modal' iconOnly icon='bi-x-lg' variant='hollow' addClasses='modal-close' onClick={() => onClose()}/>
                    {children}
                </div>
            </div>
        )
    }
})