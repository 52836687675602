import React from "react";
import './../../SASS/SkillCardList.scss';
import { Skills } from "../data/Skills";
import Card from "../ui/Card";

interface Props{
    darkMode:boolean;
}

export default function SkillCardList({darkMode = false}:Props) {
    return(
        <div className="grid-x-has-gutters skill-card-list">
            {Skills.map(skill => (
                <div key={skill.key} className="medium-12 large-6 skill-card-cell">
                    <Card >
                        <div className="skill-name-container">
                            <img src={(darkMode)?(skill.iconDark):(skill.icon)} alt={skill.name + " icon"} />
                            <p className="type-subhead-1">{skill.name}</p>
                        </div>
                        <p className="type-body">{skill.description}</p>
                    </Card>
                </div>
            ))}
        </div>
    )
}