import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function MarketingSiteBCBSImages({imageGroup}: Props){
    let wireframing1 = [
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Sketch 1.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Sketch 1.webp",
        imgAltText: "Early Sketch of the Main Home Page",
      },
      {
        key: "1.2",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Sketch 2.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Sketch 2.webp",
        imgAltText: "Early Sketch of the Multipath Home Page",
      },
      {
        key: "1.3",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Sketch 3.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Sketch 3.webp",
        imgAltText: "Early Sketch of Retail Section Home Page",
      },
    ];

    let wireframing2 = [
      {
        key: "2.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/RetailHomeWire1.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/RetailHomeWire1.webp",
        imgAltText: "First Early Wireframe of Retail Home Page",
      },
      {
        key: "2.2",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/RetailHomeWire2.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/RetailHomeWire2.webp",
        imgAltText: "Second Early Wireframe of Retail Home Page",
      },
      {
        key: "2.3",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/RetailHomeWire3.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/RetailHomeWire3.webp",
        imgAltText: "Third Early Wireframe of Retail Home Page",
      },
      {
        key: "2.4",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/RetailHomeWire4.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/RetailHomeWire4.webp",
        imgAltText: "Fourth Early Wireframe of Retail Home Page",
      },
      {
        key: "2.5",
        imgLarge:
          "./../IMGS/Projects/BCBSPublicSite/Mobile RetailHomeWire4.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/Mobile RetailHomeWire4.webp",
        imgAltText:
          "Fourth Early Wireframe of Retail Home Page at Mobile Breakpoint",
      },
    ];

    let wireframing3 = [
      {
        key: "3.01",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/InfoArch1.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/InfoArch1.webp",
        imgAltText: "Initial Public Home Page Information Architecture",
      },
      {
        key: "3.02",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire1.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire1.webp",
        imgAltText: "First Early Wireframe of the Main Home Page",
      },
      {
        key: "3.03",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire2.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire2.webp",
        imgAltText: "Second Early Wireframe of the Main Home Page",
      },
      {
        key: "3.04",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire3.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire3.webp",
        imgAltText: "Third Early Wireframe of the Main Home Page",
      },
      {
        key: "3.05",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire4.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire4.webp",
        imgAltText: "Fourth Early Wireframe of the Main Home Page",
      },
      {
        key: "3.06",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/InfoArch2.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/InfoArch2.webp",
        imgAltText: "Refined Public Home Page Information Architecture",
      },
      {
        key: "3.07",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire5.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire5.webp",
        imgAltText: "Refined Wireframe of the Main Home Page",
      },
      {
        key: "3.08",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire6.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire6.webp",
        imgAltText: "Second Refined Wireframe of the Main Home Page",
      },
      {
        key: "3.09",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire7.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire7.webp",
        imgAltText: "Third Refined Wireframe of the Main Home Page",
      },
      {
        key: "3.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire8.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire8.webp",
        imgAltText: "Fourth Refined Wireframe of the Main Home Page",
      },
      {
        key: "3.11",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/PublicHomeWire9.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/PublicHomeWire9.webp",
        imgAltText: "Fifth Refined Wireframe of the Main Home Page",
      },
    ];

    let visd1 = [
      {
        key: "1.01",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 1.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 1.webp",
        imgAltText: "Story Board Section 1",
      },
      {
        key: "1.02",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 2.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 2.webp",
        imgAltText: "Story Board Section 2",
      },
      {
        key: "1.03",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 3.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 3.webp",
        imgAltText: "Story Board Section 3",
      },
      {
        key: "1.04",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 4.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 4.webp",
        imgAltText: "Story Board Section 4",
      },
      {
        key: "1.05",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 5.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 5.webp",
        imgAltText: "Story Board Section 5",
      },
      {
        key: "1.06",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 6.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 6.webp",
        imgAltText: "Story Board Section 6",
      },
      {
        key: "1.07",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 7.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 7.webp",
        imgAltText: "Story Board Section 7",
      },
      {
        key: "1.08",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 8.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 8.webp",
        imgAltText: "Story Board Section 8",
      },
      {
        key: "1.09",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 9.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 9.webp",
        imgAltText: "Story Board Section 9",
      },
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/Board 10.jpg",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/Board 10.webp",
        imgAltText: "Story Board Section 10",
      },
    ];

    let secnav1 = [
      {
        key: "1.01",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav1.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav1.webp",
        imgAltText: "Secondary Nav Design Idea 1",
      },
      {
        key: "1.02",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav2.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav2.webp",
        imgAltText: "Secondary Nav Design Idea 2",
      },
      {
        key: "1.03",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav3.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav3.webp",
        imgAltText: "Secondary Nav Design Idea 3",
      },
      {
        key: "1.04",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav4.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav4.webp",
        imgAltText: "Secondary Nav Design Idea 4",
      },
      {
        key: "1.05",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav5.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav5.webp",
        imgAltText: "Secondary Nav Design Idea 5",
      },
      {
        key: "1.06",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav6.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav6.webp",
        imgAltText: "Secondary Nav Design Idea 6",
      },
      {
        key: "1.07",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav7.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav7.webp",
        imgAltText: "Secondary Nav Design Idea 7",
      },
      {
        key: "1.08",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav8.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav8.webp",
        imgAltText: "Secondary Nav Design Idea 8",
      },
      {
        key: "1.09",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav9.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav9.webp",
        imgAltText: "Secondary Nav Design Idea 9",
      },
      {
        key: "1.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav10.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav10.webp",
        imgAltText: "Secondary Nav Design Idea 10",
      },
      {
        key: "1.11",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav11.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav11.webp",
        imgAltText: "Secondary Nav Design Idea 11",
      },
      {
        key: "1.12",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNav12.png",
        imgThumb: "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNav12.webp",
        imgAltText: "Secondary Nav Design Idea 12",
      },
    ];

    let secnav2 = [
      {
        key: "2.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNavFinalDesign1.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNavFinalDesign1.webp",
        imgAltText: "Final Secondary Navigation Design Closed State",
      },
      {
        key: "2.2",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNavFinalDesign2.png",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNavFinalDesign2.webp",
        imgAltText: "Final Secondary Navigation Design Open State",
      },
      {
        key: "2.3",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNavUXPin.gif",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNavUXPin.gif",
        imgAltText: "Final Secondary Navigation Design in UXPin",
      },
    ];

    let secnav3 = [
      {
        key: "3.1",
        imgLarge: "./../IMGS/Projects/BCBSPublicSite/SecNavLive.gif",
        imgThumb:
          "./../IMGS/Projects/BCBSPublicSite/Thumb/SecNavLive.gif",
        imgAltText: "Final Live Version of Secondary Navigation for BCBS",
      },
    ];

    if (imageGroup === "wireframing1") {
        return (<ImageGrid size='small' images={wireframing1} />)
    }
    if (imageGroup === "wireframing2") {
        return (<ImageGrid size='small' images={wireframing2} />)
    }
    if (imageGroup === "wireframing3") {
        return (<ImageGrid size='small' images={wireframing3} />)
    }
    if (imageGroup === "allwireframing") {
        return (<ImageGrid size='small' images={wireframing1.concat(wireframing2.concat(wireframing3))} />)
    }

    if (imageGroup === "visd1") {
        return (<ImageGrid size='small' images={visd1} />)
    }
    if (imageGroup === "allvisd") {
        return (<ImageGrid size='small' images={visd1} />)
    }

    if (imageGroup === "secnav1") {
        return (<ImageGrid size='small' images={secnav1} />)
    }
    if (imageGroup === "secnav2") {
        return (<ImageGrid size='small' images={secnav2} />)
    }
    if (imageGroup === "secnav3") {
        return (<ImageGrid size='small' images={secnav3} />)
    }
    if (imageGroup === "allsecnav") {
        return (<ImageGrid size='small' images={secnav1.concat(secnav2.concat(secnav3))} />)
    }
    return(<></>);
}