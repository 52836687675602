import { Award } from "../models/Award";

export const Awards:Award[] = [
    {
        key: 1,
        title: 'YMTPB',
        year: '2023',
        company: 'Housecall Pro',
        companyLogo: './IMGS/Awards/hcp.png',
        description: 'The You Make This Place Better (YMTPB) award is given to those who spark joy in others and makes work more than a 9-5.',
        color: 'blue',
    },
    {
        key: 2,
        title: 'Do the Work',
        year: '2022',
        company: 'Housecall Pro',
        companyLogo: './IMGS/Awards/hcp.png',
        description: 'Given to those who show the grit to get things done in challenging situations.',
        color: 'blue',
    },
    {
        key: 3,
        title: 'Gold Awards',
        year: '2014',
        company: 'Northern Trust',
        companyLogo: './IMGS/Awards/tnt.png',
        description: 'Awarded for excellence in project execution and leadership.',
        color: 'green',
    },
    {
        key: 4,
        title: 'Quality Medallion',
        year: '2013',
        company: 'Northern Trust',
        companyLogo: './IMGS/Awards/tnt.png',
        description: 'Awarded for excellence in project execution and leadership.',
        color: 'green',
    },
]