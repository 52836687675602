import { makeAutoObservable } from "mobx";

export default class NavStore {
    isOpen:boolean = false;

    constructor() {makeAutoObservable(this)}

    setIsOpen = (navState: boolean) => {
        this.isOpen = navState;
    }
}