import React from "react";
import './../../SASS/Alert.scss';
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/Store";
import Button from "./Button";

interface Props {
    variant: 'success' | 'warning' | 'info' | 'error';
    text: string;
    level: 'global' | 'in-page' | 'in-content';
    isDismissable?: boolean;
    addClasses?: string;
    onClose?: () => void;
}

export default observer(function Alert({variant, text="Alert!", level = 'in-page', isDismissable=false, addClasses="", onClose}: Props){
    const {settingsStore} = useStore();
    const {mode} = settingsStore;
    let alert = <></>;
    let alertClass = "alert-container alert-" + variant;
    let alertIcon = "bi-";
    let buttonColor = ""
    
    if (mode === 'darkmode') {
        addClasses = addClasses + " dark-mode";
    }

    if (level === 'global') {
        alertClass = alertClass + " alert-global";
    }

    if (isDismissable) {
        alertClass = alertClass + " alert-is-dismissable";
    }
    
    if (variant === 'success') {
        alertIcon = alertIcon + "check2-circle";
        buttonColor = "button-color-green";
    } else if (variant === 'warning') {
        alertIcon = alertIcon + "exclamation-triangle";
        buttonColor = "button-color-yellow";
    } else if (variant === 'info') {
        alertIcon = alertIcon + "info-circle";
        buttonColor = "button-color-blue";
    } else if (variant === 'error') {
        alertIcon = alertIcon + "exclamation-octagon";
        buttonColor = "button-color-red";
    }

    alert = 
    <div className={alertClass + ((addClasses != "")?(" " + addClasses):(""))}>
        <div className="alert-content-container">
            <i className={alertIcon}></i>
            <p className="type-body">{text}</p>
        </div>
        {(isDismissable)?(
            <Button variant="hollow" text="Close alert" icon="bi-x" iconOnly addClasses={buttonColor} onClick={onClose}/>
        ):(<></>)}
    </div>


    return(alert);
})