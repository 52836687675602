import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alert from '../../ui/Alert';
import SocialRetailAppImages from '../../data/projects/SocialRetailAppImages';

export default observer(function SocialRetailApp(){
    const {loginStore, snackbarStore, settingsStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setSnackbar} = snackbarStore;
    const {contentLevel} = settingsStore;
    const navigate = useNavigate();
    
    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'socialshopping')


    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        if (projectinfo?.requiresLogin) {
            if (isLoggedIn) {
                window.scrollTo(0, 0);
                handleContentSections();
            } else {
                navigate('/work');
                setSnackbar('error', "You need to be logged in to view that case study.")
            }
        } else {
            window.scrollTo(0, 0);
            handleContentSections();
        }
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/Mavely/MavelyHero.webp" />
            </Helmet>

            {((contentLevel === 'summary') && (projectinfo.hasSummary === false))?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(<></>)}

            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>
            
            {((contentLevel === 'summary') && (projectinfo.hasSummary))?(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Mavely is a platform geared towards helping users make money by selling brands and products they love to their social networks. In early 2019, prior to launch, I was tapped by their head of design to collaborate on the design of this new Android and iOS mobile app.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>I wrote user stories based on requirements gathered verbally or via brief emails and validated them with the head of design to ensure I was working on the right experience and delivering value.</li>
                                    <li>I wireframed and prototyped the gamification of the user growing their in-app network.</li>
                                    <li>I ran a usability test on two versions of the gamification experience to help determine the best experience to move forward with.</li>
                                    <li>I designed and prototyped the account editing experience.</li>
                                    <li>I created the high fidelity designs and prototypes for a daily reward check-in system, a goal setting experience, and the payment provider (Plaid) integration experience.</li>
                                </ul>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Requirements">
                                <h2 className='type-heading-2 margin-b-2'>Requirements</h2>
                                <SocialRetailAppImages imageGroup='allreq' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframes">
                                <h2 className='type-heading-2 margin-b-2'>Wireframes</h2>
                                <SocialRetailAppImages imageGroup='allwires' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Prototyping & Testing">
                                <h2 className='type-heading-2 margin-b-2'>Prototyping & Testing</h2>
                                <SocialRetailAppImages imageGroup='allprototyping' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <SocialRetailAppImages imageGroup='allvisd' />
                            </div>
                        </div>
                    </div>
                </div>
            ):(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Mavely is a platform geared towards helping users make money by selling brands and products they love to their social networks. In early 2019, prior to launch, I was tapped by their head of design to collaborate on the design of this new Android and iOS mobile app.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body'>Designing a product from zero to launch is really exciting. In partnering with Mavely’s head of design, we had a lot of freedom in determining what we wanted in the app and how we wanted it to look and feel. That freedom though also meant there were a lot of avenues we could go down and get lost on. Thankfully, the head of design was a seasoned pro and was able to set clear objectives for us to work towards.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Requirements">
                                <h2 className='type-heading-2 margin-b-2'>Requirements</h2>
                                <p className='type-body margin-b-3'>The very first experience I was assigned to design was the gamification of the user growing their in-app network. This amounted to creating an experience where the user could invite friends, family, and social contacts to join the app and become part of their in-app network of influencers. The idea being that as members of a user’s network succeeded, they would succeed as well and share in the rewards.</p>
                                <p className='type-body margin-b-4'>In this early stage, requirements were either verbal or one sentence in a late night email. To ensure I didn’t waste time designing an experience that was not in line with what was asked for, I drafted some simple user stories and epics to clearly articulate what I understood to be the experience we were driving towards. I then set up a quick chat with the head of design to review, ask questions, and make any updates. This created a solid base for me to start designing and established a reliable communication framework with a remote team.</p>
                                <SocialRetailAppImages imageGroup='req1' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframes">
                                <h2 className='type-heading-2 margin-b-2'>Wireframes</h2>
                                <p className='type-body margin-b-4'>With these user stories defined and vetted, I began creating wireframes that would eventually become my low-fi prototype. At this point, the head of design hadn’t settled on which design tool we would be using so I used UXPin to create these wireframes due to the ease it would afford me in quickly turning them into an interactive prototype. Since the head of design was working on the navigation bar and tab bar, I created very simple wireframes of these components and worked my design for the gamification experience from there. This included a way to view the user’s team, a way to invite new team members to the app, and a way to view and track the success of a member of their team.</p>
                                <SocialRetailAppImages imageGroup='wires1' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Prototyping & Testing">
                                <h2 className='type-heading-2 margin-b-2'>Prototyping & Testing</h2>
                                <p className='type-body margin-b-3'>With the wireframes created, I quickly connected them together in an interactive low-fi prototype. At this point though I had created two versions of this influence growing gamification experience. So, I set about doing a usability test to help me understand which one would best accomplish the goal of this feature.</p>
                                <p className='type-body margin-b-4'>At the time, I worked in an office in downtown Chicago with a lot of people that fell into the target demographic for this app. So, I made use of the resources available to me and walked around asking a few of them if they would do a quick test of an app I was helping to design. Many said yes and I sat down with them one at a time, explained a bit about the app and the experiences I wanted them to try, and handed them my phone with the prototype loaded up. I watched as they completed the tasks in the two versions I had created and made note of any stumbling points they encountered. Once they were done, we had a conversation in which I collected additional feedback about the two experiences, their preference, and why.</p>
                                <SocialRetailAppImages imageGroup='prototyping1' />
                                <p className='type-body margin-b-3 margin-t-4'>With this feedback in hand, I set up a call with the head of design to discuss my findings and offer my opinion as to which of the two experiences we should move forward with.</p>
                                <p className='type-body margin-b-4'>During one of my syncs with the head of design, he expressed wanting to get further on the visual identity of the company and app but that he needed to get some work done on the account editing flow. I stepped up and offered to work on that so he could focus on the branding work. This was a simple interaction where the user would be able to update elements of their account such as email address. I quickly created a low-fi prototype of the experience and shared it with him.</p>
                                <SocialRetailAppImages imageGroup='prototyping2' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <p className='type-body margin-b-3'>By taking that work off of the head of design’s plate, he was able to quickly get the visual identity work to a place where I could begin creating high fidelity designs. At this point, we moved to working in Adobe XD and sharing files via Google Drive.</p>
                                <p className='type-body margin-b-4'>The next three features I worked on included a daily check-in reward system, a goal setting experience, and an integration with the payment provider, Plaid. The timeline for these experiences was tighter than the invite or account editing experiences because the developer was slated to begin work on them in the next few weeks. Faced with this I knew I wouldn’t have time to work back through a full low-fi to high-fi design process. Instead, I worked in our new design language and applied common patterns used in Android and iOS apps to create these three new experiences.</p>
                                <SocialRetailAppImages imageGroup='visd1' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Outcome">
                                <h2 className='type-heading-2 margin-b-2'>Outcome</h2>
                                <p className='type-body'>I moved on to a new opportunity shortly after I completed the design of the payment provider integration but I kept in touch with the head of design and was happy to hear the app launched successfully and has grown significantly.</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>
            )}
            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE">
//<h2 className='type-heading-2 margin-b-2'></h2>
//</div>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />