import React, { useEffect, useState } from 'react';
import './../../SASS/ProjectScrollBar.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/Store';

interface Props {
    names: string[];
    sizes: number[];
    projectColor: string;
}

export default observer(function ProjectScrollBar({names, sizes, projectColor}: Props) {
    const {viewStore} = useStore();
    const {scrollY, viewHeight} = viewStore;
    const [percentScrolled, setPercentScrolled] = useState<number>(0);
    let scrollBarSegments = <></>;
    let totalHeight = 0;

    for (let index = 0; index < sizes.length; index++) {
        totalHeight = totalHeight + sizes[index]
    }

    totalHeight = totalHeight - (4*sizes.length);

    for (let index = 0; index < names.length; index++) {
        scrollBarSegments = 
            <>
                {scrollBarSegments}
                {(index != names.length-1) ? (
                    <>
                        <div className='project-scroll-bar-segment' style={{height:"calc("+((sizes[index] / totalHeight)*100)+"%"+" - 4px)"}}>
                        </div>
                        <div className='project-scroll-bar-segment-separator'></div>
                    </>
                ) :(
                    <div className='project-scroll-bar-segment' style={{height:((sizes[index] / totalHeight)*100)+"%"}}>
                    </div>
                )}
            </>
    }

    //Nick - need to sort out this math - it's not working right just yet.
    useEffect(()=>{
        setPercentScrolled(((scrollY - (viewHeight * .5)) / (window.document.body.offsetHeight - (viewHeight * 1.75))) * 100);
    },[scrollY])

    function handleBackToTopClick() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }


    return (
        <div className='project-scroll-bar-container'>
            <div id='backtotop' className={'project-scroll-bar-home project-'+projectColor} role='button' onClick={() => handleBackToTopClick()}>
                {/* <i className='bi-house-fill'></i> */}
                <i className='bi-arrow-up-square'></i>
                <span className='project-scroll-bar-home-label'>Back to top</span>
            </div>
            <div className='project-scroll-bar-segments-container'>
                {scrollBarSegments}
                <div className={'project-scroll-bar-scroll-indicator project-'+projectColor} style={{height:percentScrolled+"%"}}></div>
                <div className='project-scroll-bar-scroll-indicator-bkg' ></div>
            </div>
        </div>
    )
})