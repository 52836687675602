import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import ProjectHighlightList from '../components/ProjectHighlightList';
import { AllProjects } from "../data/ProjectHighlights";
import { ProjectInfo } from "../models/ProjectInfo";
import '../../SASS/Home.scss';
import '../../SASS/HomeHero.scss';
import { Recommendation } from "../models/Recommendation";
import { Recommendations } from "../data/Recommendations";
import RecommendationList from "../components/RecommendationList";
import NLink from "../ui/NLink";
import { Helmet } from "react-helmet";
import Button from "../ui/Button";
import Brain from "../components/Brain";
import { useStore } from "../stores/Store";
import ScrollOn from "../ui/ScrollOn";
import ReactGA from 'react-ga4';


export default observer(function Home(){
    useEffect(() => {
      window.scrollTo(0, 0);
      // ReactGA.send({ hitType: "pageview", page: "/", title: "NMChivers Design" });
    }, [])
    const {modalStore, brainStore, viewStore, settingsStore} = useStore();
    const {setModalVariant} = modalStore;
    const {setBrainCloseAt} = brainStore;
    const {viewHeight} = viewStore;
    const {mode} = settingsStore;

    const highlightedProjects: ProjectInfo[] = AllProjects.filter(project => {
        return project.featured
      });
    const highlightedRecs: Recommendation[] = Recommendations.slice(0,3);

    const closeBrainAtSection = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (closeBrainAtSection.current != null) {
        setBrainCloseAt(closeBrainAtSection.current.offsetTop,viewHeight);
      } else {
        setBrainCloseAt((window.document.body.offsetHeight - (viewHeight * 1.5)), viewHeight)
      }
    },[])

    function handleContactButtonClick() {
      setModalVariant('contact');
    }

    return (
        <>
          <Helmet>
              <title>NMChivers Design</title>
              <meta name="og:title" content="NMChivers Design" />
              <meta name="description" content="Hi, I'm Nick Chivers, a multifaceted, product designer and creative leader that blends the boundaries between logical analysis and creative thinking to deliver exceptional work for top Fortune 500 companies and startups alike." />
              <meta name="og:description" content="Hi, I'm Nick Chivers, a multifaceted, product designer and creative leader that blends the boundaries between logical analysis and creative thinking to deliver exceptional work for top Fortune 500 companies and startups alike." />
              <meta name="og:url" content="https://nmchivers.design" />
              <meta name="og:image" content="https://nmchivers.design/IMGS/Brain/BrainWBKG@0.5x.png" />
          </Helmet>
          <div className="grid-x-has-gutters home-hero-block">
            <div className="hide-at-small medium-1 large-1"></div>
            <div id='HomeHeroContent' className="small-12 medium-10 large-7">
                <h1 className='margin-b-3 home-hero-type-group'>
                    <span className='home-hero-type-intro'>Hi, I'm Nick,</span>
                    <div className='home-hero-type-statement'>a multifaceted product designer and creative leader out to push boundaries and create amazing things.</div>
                </h1>
                <p className='margin-b-5 home-hero-subhead'>I blur the line between logical analysis and creative thinking to deliver exceptional work and lead even more exceptional designers.</p>
                <Button text='Get In Touch' variant='filled' onClick={() => handleContactButtonClick()} />
            </div>
            <div className="hide-at-small medium-1 large-4"></div>
            <div className="home-brain-small">
              {(mode === 'darkmode')?(
                <img className="brain-small" src="./IMGS/Brain/Brain-Dark.webp" alt="Analytical and Creative Brain Expression"/>
              ):(
                <img className="brain-small" src="./IMGS/Brain/Brain.webp" alt="Analytical and Creative Brain Expression"/>
              )}
              
            </div>
            <Brain />
          </div>
          <div className="grid-x-has-gutters home-project-block">
            <div className="hide-at-small medium-2 large-3"></div>
            <div className="small-12 medium-8 large-6">
              <ProjectHighlightList 
                projects={highlightedProjects}
                home
              />
            </div>
            <div className="hide-at-small medium-2 large-3"></div>
          </div>
          <div ref={closeBrainAtSection} className="grid-x-has-gutters see-more-home">
            <div className="hide-at-small medium-2 large-3"></div>
            <div className="small-12 medium-8 large-6">
                <ScrollOn offsetBy={15} offsetByIs='%'>
                    <div id='seemore' className='see-more-frame'>
                        <h2 className='type-heading-2 margin-b-5'>Want to see more of my work?</h2>
                        <div className='button-group'>
                            <NLink content='Check It Out' variant='button-filled' to={'/work'} />
                            <Button text='Get In Touch' variant='outline' onClick={()=>handleContactButtonClick()}/>
                        </div>
                    </div>
                </ScrollOn>
            </div>
            <div className="hide-at-small medium-2 large-3"></div>
          </div>
          <div className="home-about-me major-content-section">
            <div className="grid-x-has-gutters">
              <div className="small-12 medium-10 medium-offset-left-1">
                <h2 className="type-heading-2 margin-b-2">About Me</h2>
                <div className="grid-x-has-gutters">
                  <div className="small-12 medium-12 large-6">
                    <p className="type-body margin-b-3">I grew up in a small town in central Illinois where I sang in school musicals, played the trumpet in band, and competed in speech tournaments. After college though, I made the leap and moved to Chicago where I lived for over a decade. But, as much as I loved the city, I couldn't quite cozy up to its frigid winters. So, in search of milder climes and a fresh new chapter, I packed up my two cats and we followed the call of the west coast. Now, surrounded by sun-soaked beaches and a relaxed atmosphere, I'm eagerly embracing new horizons and continuing to grow and evolve.</p>
                    <p className="type-body margin-b-3">Career wise, I hold a degree in accounting and started my career as a project manager. Not the typical path for a designer, right? But, it’s exactly this blend of left and right brain, analytical and creative, that makes me such an impactful designer. With almost a decade of experience as a designer and creative leader, I’ve had the opportunity to create amazing experiences that delighted users and delivered real value to companies such as Housecall Pro,  UnitedHealthcare, and BlueCross BlueShield. I’ve also had the privilege to work with, mentor, and even manage some amazingly talented designers.</p>
                    <NLink content="Learn more about me" variant="default" to={'/nick'} addClasses="margin-b-5" />
                    <img className="home-headshot" src="./IMGS/Headshot/nick.webp" alt="Headshot of Nick" loading="lazy"/>
                  </div>
                  <div className="small-12 medium-12 large-6 home-about-me-recs">
                    <h3 className="type-heading-3 margin-b-2">What people are saying</h3>
                    <RecommendationList
                      recs={highlightedRecs}
                      addClasses="margin-b-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        
    )
})