import React, { OptionHTMLAttributes, SelectHTMLAttributes, useRef, useState, MouseEvent, ChangeEvent, ChangeEventHandler, InputHTMLAttributes, useEffect } from 'react';
import './../../SASS/Inputs.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id:string;
    placeholder:string;
    label?:string;
    isRequired?:boolean;
    errorMessage?:string;
    isInvalid?:boolean;
    addClasses?:string;
    options: SelectOption[];
    valueUpdate: Function;
}

export interface SelectOption {
    value: string,
    text: string,
    selected: boolean,
}

export default function NSelect({id, placeholder=" ", label="", isInvalid=false, addClasses="", isRequired=true, errorMessage = "", options, valueUpdate, ...props}: Props){
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [displayNone, setDisplayNone] = useState<boolean>(true);
    //const [selectedValue, setSelectedValue] = useState<string>("");
    const [selectedOpt, setSelectedOption] = useState<SelectOption>();
    let input = useRef<HTMLInputElement>(null);
    let selectDiv = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if (options.find(opt => opt.selected)?.selected != undefined) {
            setSelectedOption(options.find(opt => opt.selected));
        }
    },[])

    function handleFieldOnClick () {
        if (isOpen) {
            setIsOpen(!isOpen);
            setTimeout(() => {
                setDisplayNone(true);
            }, 200)
        } else {
            setDisplayNone(false);
            setTimeout(() => {
                setIsOpen(!isOpen);
            }, 50)
        }
    }
    function handleOptionOnClick (opt: SelectOption ){
        setSelectedOption(opt);
        //setSelectedValue(opt.value);
        opt.selected = true;
        valueUpdate(opt);
        selectDiv.current?.focus();
        setIsOpen(false);
        setTimeout(() => {
            setDisplayNone(true);
        }, 200)
    }
    function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>){
        if (event.code === "Enter") {
            selectDiv.current?.click();
        }
        if (event.code === "Space") {
            event.preventDefault();
            selectDiv.current?.click();
        }
    }
    function handleOptionKeyUp(event: React.KeyboardEvent<HTMLDivElement>, opt: SelectOption){
        if (event.code === "Enter") {
            handleOptionOnClick(opt);
        }
        if (event.code === "Space") {
            handleOptionOnClick(opt);
        }
    }
    
    let validationClasses = "";
    if (isInvalid) {
        validationClasses = " error-state";
    }

    let labelElement = <></>;
    if (label !== "" && !isRequired) {
        labelElement = <label className={'input-text-label' + validationClasses} htmlFor={id}>{label}<span>(optional)</span></label>;
    } else if (label !== "") {
        labelElement = <label className={'input-text-label' + validationClasses} htmlFor={id}>{label}</label>;
    }

    let errorMessageElement = <></>;
    if (errorMessage !== "") {
        errorMessageElement = <p className={'input-text-errormessage'+ validationClasses}>{errorMessage}</p>;
    }

    if (addClasses !== "") {
        addClasses = " " + addClasses;
    }

    return(
        <div className={'input-select-container' + addClasses}>
            {labelElement}
            <input ref={input} id={id} name={id} value={selectedOpt?.value} {...props} aria-hidden="true" ></input>
            <div className='select-container'>
                <div ref={selectDiv} className={'select-field-element' + ((isOpen)?(" is-open"):(""))} onClick={() => handleFieldOnClick()} onKeyUp={(e) => handleKeyUp(e)} role="button" aria-expanded={isOpen} aria-haspopup="listbox" tabIndex={0}>
                    <p className='select-value-text'>{selectedOpt?.text}</p>
                    <p className='select-placeholder' style={{display:((selectedOpt != undefined)?("none"):("unset"))}}>{placeholder}</p>
                </div>
                <div className={'select-option-container' + ((isOpen)?(" is-open"):(""))} style={{display:((displayNone)?("none"):("unset"))}}>
                    {options.map(opt => (
                        <div className={'select-option' + ((opt.value === selectedOpt?.value)?(" is-selected"):(""))} key={opt.value} onClick={()=>handleOptionOnClick(opt)} onKeyUp={(e) => handleOptionKeyUp(e, opt)} tabIndex={0}>{opt.text}</div>
                    ))}
                </div>
            </div>
            {errorMessageElement}
        </div>
    )
}