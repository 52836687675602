import React from 'react';
import { Image } from '../../models/Image';
import ImageGrid from '../../components/ImageGrid';

interface Props {
    imageGroup:string;
}

export default function ChoiceImages({imageGroup}: Props){
    let userflow1 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/Choice Rewards User Flows.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/Choice Rewards User Flows.webp",
            imgAltText: "Choice Rewards User Flows",
        },
    ];
    let visd1 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/0 Marketplace Home.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/0 Marketplace Home.webp",
            imgAltText: "Choice Rewards Home Page MVP",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/0 Mobile Marketplace Home.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/0 Mobile Marketplace Home.webp",
            imgAltText: "Mobile Choice Rewards Home Page MVP",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/1 Shop Page.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/1 Shop Page.webp",
            imgAltText: "Category Shopping Page",
        },
        {
            key: "4",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/1.2 Shop Page + Selected Filters.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/1.2 Shop Page + Selected Filters.webp",
            imgAltText: "Filtered Category Shopping Page",
        },
        {
            key: "5",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/1.2 Mobile Shop Page + Selected Filters.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/1.2 Mobile Shop Page + Selected Filters.webp",
            imgAltText: "Filtered Category Mobile Shopping Page",
        },
        {
            key: "6",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Details Page - HSA Contribution.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Details Page - HSA Contribution.webp",
            imgAltText: "Reward Details Page for HSA Contribution",
        },
        {
            key: "7",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Mobile Details Page - HSA Contribution.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Mobile Details Page - HSA Contribution.webp",
            imgAltText: "Mobile Reward Details Page for HSA Contribution",
        },
        {
            key: "8",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Details Page - Premium Reduction.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Details Page - Premium Reduction.webp",
            imgAltText: "Reward Details Page for Medical Premium Reduction",
        },
        {
            key: "9",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Mobile Details Page - Premium Reduction.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Mobile Details Page - Premium Reduction.webp",
            imgAltText: "Reward Details Mobile Page for Medical Premium Reduction",
        },
        {
            key: "10",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Details Page - Physical or Digital Gift Card.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Details Page - Physical or Digital Gift Card.webp",
            imgAltText: "Reward Details Page for a Gift Card",
        },
        {
            key: "11",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/2 Mobile Details Page - Physical or Digital Gift Card.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/2 Mobile Details Page - Physical or Digital Gift Card.webp",
            imgAltText: "Mobile Reward Details Page for a Gift Card",
        },
        {
            key: "12",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/3.1 Cart - Full.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/3.1 Cart - Full.webp",
            imgAltText: "Choice Rewards Cart",
        },
        {
            key: "13",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/3.1 Mobile Cart - Full.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/3.1 Mobile Cart - Full.webp",
            imgAltText: "Mobile Choice Rewards Cart",
        },
        {
            key: "14",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/4 Confirm Modal.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/4 Confirm Modal.webp",
            imgAltText: "Choice Rewards Checkout Confirmation",
        },
        {
            key: "15",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/5 Success Modal.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/5 Success Modal.webp",
            imgAltText: "Choice Rewards Checkout Success Modal",
        },
    ];

    let qa1 = [
        {
            key: "1",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/QA Banner Page.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/QA Banner Page.webp",
            imgAltText: "QA Findings for Shopping Cart Banner",
        },
        {
            key: "2",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/QA Details Page.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/QA Details Page.webp",
            imgAltText: "QA Findings for Reward Details Page",
        },
        {
            key: "3",
            imgLarge: "./../IMGS/Projects/ChoiceRewards/QA Reward Option.png",
            imgThumb: "./../IMGS/Projects/ChoiceRewards/Thumbs/QA Reward Option.webp",
            imgAltText: "QA Findings for Reward Option Card",
        },
    ];

    if (imageGroup === "userflow1") {
        return (<ImageGrid size='small' images={userflow1} />)
    }
    if (imageGroup === "alluserflow") {
        return (<ImageGrid size='small' images={userflow1} />)
    }

    if (imageGroup === "visd1") {
        return (<ImageGrid size='small' images={visd1} />)
    }
    if (imageGroup === "allvisd") {
        return (<ImageGrid size='small' images={visd1} />)
    }

    if (imageGroup === "qa1") {
        return (<ImageGrid size='small' images={qa1} />)
    }
    if (imageGroup === "allqa") {
        return (<ImageGrid size='small' images={qa1} />)
    }
    return(<></>);
}