import React from 'react';
import Card from '../ui/Card';
import { Recommendation } from '../models/Recommendation';
import NLink from '../ui/NLink';
import "../../SASS/RecommendationCard.scss";

interface Props {
    rec: Recommendation;
    addClasses?: string;
}

export default function RecommendationCard({rec, addClasses}: Props){

    return (
        <Card addClasses={"recommendation-card" + ((addClasses !== undefined) ? (" " + addClasses) : (""))}>
            <h4 className='type-subhead-2 margin-b-2'>{rec.recText}</h4>
            <div>
                <div className='margin-b-1 recommender'>
                    <img src={rec.imageURL} alt={rec.name} />
                    <div>
                        <p className='type-body margin-b-1'>{rec.name}</p>
                        <p className='type-caption'>{rec.role}</p>
                    </div>
                </div>
                <p className='type-caption margin-b-2'>{rec.relationship}</p>
                <NLink content='Read it in full on LinkedIn' variant='external' to={rec.url} target='_blank' rel="noopener noreferrer" />
            </div>
        </Card>
    )
}