import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import Alert from '../../ui/Alert';
import DesignSystemHCPImages from '../../data/projects/DesignSystemHCPImages';

export default observer(function DesignSystemHCP(){
    const {loginStore, snackbarStore, settingsStore} = useStore();
    const {isLoggedIn} = loginStore;
    const {setSnackbar} = snackbarStore;
    const {contentLevel} = settingsStore;
    const navigate = useNavigate();
    
    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'dshcp')


    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        if (projectinfo?.requiresLogin) {
            if (isLoggedIn) {
                window.scrollTo(0, 0);
                handleContentSections();
            } else {
                navigate('/work');
                setSnackbar('error', "You need to be logged in to view that case study.")
            }
        } else {
            window.scrollTo(0, 0);
            handleContentSections();
        }
      }, [projectinfo]);

    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        page = 
        <>
            <Helmet>
                <title>{projectinfo.title} | NMChivers Design</title>
                <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                <meta name="description" content={projectinfo.pageDesc} />
                <meta name="og:description" content={projectinfo.pageDesc} />
                <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/HCPDesignSystem/HCPDesignSystemHero.webp" />
            </Helmet>

            {((contentLevel === 'summary') && (projectinfo.hasSummary === false))?(
                <Alert 
                text='This case study does not support Summary only at this time. Apologies! You can update your Level of Detail preference in Settings.'
                variant='warning'
                level='in-page'
                addClasses='project-page-alert'
                />
            ):(<></>)}

            <div className='grid-x-has-gutters project-page-hero'>
                <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                    <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                </div>
                <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                    <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                    <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                </div>
                <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                    <ProjectPageInfoCard project={projectinfo} />
                </div>
            </div>
            
            {((contentLevel === 'summary') && (projectinfo.hasSummary))?(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Housecall Pro is a technology solution for small to midsize home service companies founded in 2013. Their robust feature set spans a web application, an Android application, and an iOS application. As the company and platform grew over the years, a shortcut of sorts was taken in regards to the design and development of these applications. Mainly, they relied on pre-built UI frameworks and toolkits for each application that were modified ad hoc to speed up their design and development cycles. These frameworks were made available to designers via a system of five different Figma libraries that supported common elements such as color, typography, and iconography and more app specific components for the web, Android, and iOS applications. The web library was derived from the Material UI V4 framework. The Android library was populated with copies of some components from the web library. And the iOS library was derived from an iOS UI toolkit from a few years back. In time, an effort was made to evolve the design language of the web application which then introduced a new library for this new design system called H2.0 but it was never fully implemented or widely adopted. Another iOS library was introduced shortly after this new web library in an attempt to merge a newer stock iOS toolkit with visual style of H2.0 but it was never completed nor was it ever fully implemented or adopted.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>Housecall Pro had a set of reusable component libraries in Figma but was incomplete and lacked documentation.</li>
                                    <li>This system also did not align with the code libraries engineers were using to build the web, Android, and iOS applications.</li>
                                    <li>Additionally, this system was derived from generic frameworks and toolkits making the experience feel more like a Google experience than a Housecall Pro experience.</li>
                                    <li>Once the decision was made to form a true design system team, I was chosen to be the lead product designer in addition to managing a team of four designers across three continents.</li>
                                    <li>I used a research plan template I had created as part of my work with the company’s Research Guild to document what I wanted to learn and how I was going to go about learning it in Confluence.</li>
                                    <li>I created three unique surveys: one for our Housecall Pro users, one for designers, and one for engineers. I then launched these surveys to their audiences and began collecting information.</li>
                                    <li>I learned that users were generally satisfied with the look and feel of Housecall Pro but admitted that it felt a bit dated.</li>
                                    <li>I learned that users expected the experience to look and feel the same across the web, Android, and iOS applications.</li>
                                    <li>Designers responded that they were not satisfied with web, Android, or iOS Figma libraries and they did not feel they were easy to use nor did they feel they were effective in helping them produce quality designs efficiently.</li>
                                    <li>Designers highlighted a lack of documentation, components being difficult to work with, missing, or broken, and general confusion as the reasons behind these ratings.</li>
                                    <li>Web engineers rated more neutral in terms of satisfaction with their front-end libraries while iOS rated less so and Android rated rather dissatisfied with them. This was mirrored in their feeling towards the effectiveness of their front-end libraries to help them produce quality work efficiently.</li>
                                    <li>Engineers commented on the lack of documentation, components being outdated, difficult to use, broken, or missing, and a general sense of confusion supporting their ratings.</li>
                                    <li>Both designers and engineers felt it was difficult to use their respective libraries to build what was designed to a high level of quality.</li>
                                    <li>I created an inventory of all Figma styles and components from the available libraries and evaluated their accessibility against AA level requirements in the WCAG.</li>
                                    <li>This inventory evolved as the team grew and engineers were added. This allowed us to align design components to their code counterparts and highlight gaps.</li>
                                    <li>This inventory helped us plan what to tackle now, next, and in the future.</li>
                                    <li>I analyzed competitor color palettes, inspirational color palettes, our current color palette, the color palette used in the H2.0 system, and Material UI’s available colors.</li>
                                    <li>I defined a new color palette complete with a robust set of color combinations that met or exceeded level AA compliance with the WCAG.</li>
                                    <li>I redesigned and simplified the typography system and tested it in existing experiences to gauge the impact it would have on spacing, alignment, and overall content readability.</li>
                                    <li>I defined a core level token system for the base color and typography styles.</li>
                                    <li>I enlisted three additional designers from the department to fast track the next set of components: buttons, text inputs, checkboxes, switches, and radio buttons.</li>
                                    <li>I assigned one or two components to each designer and facilitated design reviews with the team to guide their work and ensure we were creating a cohesive system.</li>
                                    <li>I then redlined the components for my engineers before I walked them through the designs during grooming.</li>
                                    <li>I led the work to perform design reviews/design QA on each ticket either by assigning it to the other designer on the team or taking ownership of it. This process ensured components were implemented to design specifications across the web, Android, and iOS.</li>
                                    <li>Android began to lag behind web and iOS work due to the coded component continually not meeting design specifications. I took ownership of Android design reviews and adjusted how we did them to include one-on-one meetings with the engineer to discuss defects and address them together. This greatly improved the quality of the work and moved Android in the right direction.</li>
                                    <li>After each sprint we completed a team evaluation and sprint retrospective which lead to improvements in our processes such as breaking down components into more granular pieces of work in order to decrease the time needed for design reviews/design QA.</li>
                                    <li>During a few of our sprint retrospectives I was personally thanked by my team for the thoughtful designs I was putting forward, the care and attention I put into design reviews, and the positive attitude I approached each hurdle with.</li>
                                    <li>I led the effort to create the new Figma libraries. Ensuring that we addressed the confusion and frustration expressed by designers, I kept the number of libraries to two and flattened the structure of each.</li>
                                    <li>I used newer Figma features such as boolean, text, and instance swap properties to build the components and ensure they were flexible enough to meet even the most complex use cases.</li>
                                    <li>I worked with my product manager and lead engineer to build a business case for a subscription to Supernova so that we could build our documentation and integrate it with Figma and Storybook.</li>
                                    <li>I defined the structure of our documentation to support designers and engineers creating a single source of truth for both departments.</li>
                                    <li>I wrote the introduction and design portions of the documentation for most of the components we designed and built.</li>
                                    <li>At the end of June we launched our first page template along with its component atoms, molecules, and organisms into beta.</li>
                                </ul>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <DesignSystemHCPImages imageGroup='allchallenge' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Research & Analysis">
                                <h2 className='type-heading-2 margin-b-2'>Research & Analysis</h2>
                                <DesignSystemHCPImages imageGroup='allresearch' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Planning & Prioritizing">
                                <h2 className='type-heading-2 margin-b-2'>Planning & Prioritizing</h2>
                                <DesignSystemHCPImages imageGroup='allplanning' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design">
                                <h2 className='type-heading-2 margin-b-2'>Design</h2>
                                <DesignSystemHCPImages imageGroup='alldesign' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA">
                                <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                                <DesignSystemHCPImages imageGroup='alldqa' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Building Components & Documentation">
                                <h2 className='type-heading-2 margin-b-2'>Building Components & Documentation</h2>
                                <DesignSystemHCPImages imageGroup='allbuilding' />
                            </div>
                            {/* PUT OTHER MAJOR SECTIONS HERE */}
                        </div>
                    </div>
                </div>
            ):(
                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Housecall Pro is a technology solution for small to midsize home service companies founded in 2013. Their robust feature set spans a web application, an Android application, and an iOS application. As the company and platform grew over the years, a shortcut of sorts was taken in regards to the design and development of these applications. Mainly, they relied on pre-built UI frameworks and toolkits for each application that were modified ad hoc to speed up their design and development cycles. These frameworks were made available to designers via a system of five different Figma libraries that supported common elements such as color, typography, and iconography and more app specific components for the web, Android, and iOS applications. The web library was derived from the Material UI V4 framework. The Android library was populated with copies of some components from the web library. And the iOS library was derived from an iOS UI toolkit from a few years back. In time, an effort was made to evolve the design language of the web application which then introduced a new library for this new design system called H2.0 but it was never fully implemented or widely adopted. Another iOS library was introduced shortly after this new web library in an attempt to merge a newer stock iOS toolkit with visual style of H2.0 but it was never completed nor was it ever fully implemented or adopted.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body margin-b-3'>When I started at Housecall Pro there was not much of a design system. A design system is often described as a set of reusable components and clear guidelines that can be used to design and build applications at scale. Yes, Housecall Pro had a set of reusable components. But, this set of components had several issues, not the least of which was that they could not be used to build anything without having to create many components from scratch to fill in the system’s gaps.</p>
                                <p className='type-body margin-b-3'>Another challenge with this system was that the design components and code components did not align. There were components in our design libraries that did not exist in the code libraries and there were components in the code libraries that did not exist in the design libraries. For example, the Android Figma library was mostly empty but did define one color for the platform while the Android code library boasted a plethora of components including over 240 named colors, most of which were duplicates.</p>
                                <p className='type-body margin-b-3'>Aesthetically speaking, each application looked a little different from the others but none of them stood out as inherently Housecall Pro. The web application had been built using Material UI V4 with all colors, aside from the brand’s blue, coming from Material’s available palette. The Android application largely took its design aesthetic from this framework as well since Material UI is based on Android’s Material Design. In fact, much of the Android Figma library stood empty so designers would use components from the web library or create new elements to fill in the gaps. The iOS application was built using a stock iOS UI toolkit with a few color modifications to ‘brand’ it and some adjustments to buttons and input fields. The problem with this branding effort though was that it was not our branding it was drawing from. Material UI follows Google’s branding meaning that our web, Android, and iOS experiences followed that branding as well.</p>
                                <p className='type-body margin-b-4'>Additionally, the system lacked guidelines of any kind. Rather, it was left up to the design manager to provide these guidelines on an as needed basis. This worked while the team was smaller but over the course of three years the design department went from three designers and one manager to over twenty designers, five managers, and a VP.</p>
                                <DesignSystemHCPImages imageGroup='challenge1' />
                                <p className='type-body margin-b-3 margin-t-4'>As the team grew in size, some designers, myself included, attempted to document guidelines as we introduced new components to the system. The challenge we faced here was in getting designers to refer back to the documentation weeks or months later when they were ready to use that new component. What often happened was a designer would get feedback from their manager to talk to the creator of the component. The creator would then have to send the documentation to the designer and respond to questions they had. This meant a manager needed to remember who created each component and a bottleneck would form if the creator was unavailable.</p>
                                <p className='type-body margin-b-3'>Finally, the CEO gave the department the green light to create a dedicated team focused on addressing our design system and improving it. The challenge here though was that we had to do it without going up in headcount.</p>
                                <p className='type-body'>After much discussion and planning, I was tasked with leading the new design system team which we later called the Digital User Experience Team or DUET for short. At the time though, I had three designers reporting to me and I was responsible for the design of a major feature that had just launched a month beforehand. To provide me the bandwidth to focus on the design system, we moved a senior product designer to the feature I was designing for and had them begin reporting to me. Two teams were consolidated into one and the designer that freed up replaced the designer that had moved to my team.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Research & Analysis">
                                <h2 className='type-heading-2 margin-b-2'>Research & Analysis</h2>
                                <p className='type-body margin-b-4'>Upon becoming the lead designer for the new design system team, I immediately dove into research mode. Since leadership was still working on assigning engineering resources and a product manager, this felt like the best place to start. I used a research plan template I had created as part of my work with the company’s Research Guild to document what I wanted to learn and how I was going to go about learning it in Confluence. This included defining all of my questions, the techniques I would use to get answers to them, and a timeline I would manage this effort to.</p>
                                <DesignSystemHCPImages imageGroup='research1' />
                                <p className='type-body margin-b-3 margin-t-4'>The main things I wanted to learn boiled down to a few key points. First, I wanted to understand how user’s perceived our design aesthetic. Second, I wanted to understand if it was important for us to design a system that unified the Android, iOS, and web experiences or if it was better to have platform specific design systems. Finally, I wanted to quantify how designers and engineers felt towards the current systems. Much of what I was hearing anecdotally was not positive but I wanted to understand how that looked analytically so that we could measure changes in sentiment as we changed the system.</p>
                                <p className='type-body margin-b-3'>From there I created three unique surveys: one for our Housecall Pro users, one for designers, and one for engineers. I reviewed the user survey with our product marketing team and then published it out to the user community on Facebook. I reviewed the designer survey with my manager before publishing it to the team via Slack. And, before I published the engineering survey to the team via Slack, I reviewed it with an engineer, an engineering manager, and the CTO.</p>
                                <p className='type-body margin-b-3'>As the survey results came in there were some very clear themes. First, of the users that responded to our survey, most said they were satisfied with the look and feel of the web, Android, and iOS apps. What was more insightful here though were the comments they provided. Users expressed that the experience felt dated. Given that Material UI V4 was the basis of our design language and it was based on Android’s Material Design from the mid 2010’s, that was not altogether surprising.</p>
                                <p className='type-body margin-b-4'>Additionally, 45.5% of user respondents agreed or strongly agreed that the Android, iOS, and web experiences should look and feel the same vs only 18.2% that disagreed or strongly disagreed. This was supported by the responses from designers as 57% agreed or strongly agreed with this sentiment as well.</p>
                                <DesignSystemHCPImages imageGroup='research2' />
                                <p className='type-body margin-b-3 margin-t-4'>The survey responses from designers and engineers did not surprise me but they did help to quantify much of the pain and concerns that had been raised. Designers were most familiar with the web library but they were not satisfied with it, the Android library, or the iOS library. Additionally, designers did not feel they were easy to use nor did they feel they were effective in helping them produce quality designs efficiently. Designers highlighted a lack of documentation, components being difficult to work with, missing, or broken, and general confusion as the reasons behind these ratings. They did agree that it was better to have something rather than nothing though.</p>
                                <p className='type-body margin-b-3'>Overall, the engineers that responded to the survey also rated their libraries on the lower side. Web engineers rated more neutral in terms of satisfaction with their front-end libraries while iOS rated less so and Android rated rather dissatisfied with them. This was mirrored in their feeling towards the effectiveness of their front-end libraries to help them produce quality work efficiently. In the comments from the team, they highlighted a lack of documentation, components being outdated, difficult to use, broken, or missing, and a general sense of confusion.</p>
                                <p className='type-body margin-b-4'>In looking at the partnership between design and engineering I asked both groups how easy or difficult it was to implement a design using the existing Figma libraries and front-end code libraries. The overall response was noticeably low across all platforms and between both groups with average ratings falling between neutral and difficult. Additionally, all front-end code libraries and the Figma based design libraries received NPS ratings at or below -50 with the lowest being the front-end web library at -76.</p>
                                <DesignSystemHCPImages imageGroup='research3' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Planning & Prioritizing">
                                <h2 className='type-heading-2 margin-b-2'>Planning & Prioritizing</h2>
                                <p className='type-body margin-b-3'>At this point, I needed to focus on inventorying the design system as that would give us an understanding of where to begin in the process of redesigning the Housecall Pro Design System. But, I was the only resource on the team and I was managing a team of four other designers across three continents. To handle this, I descoped the interviews I had planned and reached out to our analytics team to ask for help in compiling additional system and user analytics. These analytics would help us in tracking changes in user behavior and sentiment over time as we rolled out changes.</p>
                                <p className='type-body margin-b-4'>Moving on to the inventory, I began sifting through all our Figma libraries for web, Android, and iOS as well as our common libraries for colors, typography, and icons. Because there had been an attempt to create a new web design system and a new iOS design system prior to my team being formed I ended up sifting through seven different libraries to compile this inventory. I logged where each component lived, the path to find it, whether there were any accessibility issues with the component or not, what those issues were, and how many uses and detachments the component had in the last ninety days.</p>
                                <DesignSystemHCPImages imageGroup='planning1' />
                                <p className='type-body margin-b-4 margin-t-4'>While I was compiling this inventory, the team grew as a front-end developer, a part-time designer, and a product manager were added to the team. To help us gel as a newly formed team, I planned and facilitated an introduction and planning workshop. I used Figjam to put the materials together and then facilitated the conversation as we reviewed our team’s charter, our OKRs for the quarter, introduced ourselves to each other, decided on a name for our team, and agreed how we would operate.</p>
                                <DesignSystemHCPImages imageGroup='planning2' />
                                <p className='type-body margin-b-3 margin-t-4'>With these new members on the team, the inventory was able to evolve to include information about each design component’s code counterpart. This gave us insight into some quick wins to improve the existing experience as well as which components we should tackle in our redesign efforts first in order to have the greatest impact in the shortest amount of time. Unfortunately, we were still missing an Android and an iOS engineer so our inventory was incomplete until they joined the team a few months later.</p>
                                <p className='type-body margin-b-4'>Going off of our inventory, we decided to tackle the basic, common elements of the design system first. This included color and typography. Because these two elements would impact almost every other component in the system, if we defined them first we would minimize future rework. We discussed this with senior leadership and while they agreed this approach, they impressed on us their desire to see a large impact, quickly. To do this, we agreed to focus on working towards creating page templates based on the atomic design principle. This was largely in line with how we were already approaching the system redesign so we organized our plan around the templates we were driving towards.</p>
                                <DesignSystemHCPImages imageGroup='planning3' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design">
                                <h2 className='type-heading-2 margin-b-2'>Design</h2>
                                <p className='type-body margin-b-4'>With a plan in place, I began diligently working on the color and typographic system for our new Housecall Pro Design System. To do this, I started by evaluating competitor color palettes, inspirational color palettes, our current color palette and the color palette used in the H2.0 system. Leadership was adamant about continuing to leverage Material UI in this redesign so I also included their color system in my analysis. With these colors in mind and my knowledge of the system's needs based on my previous experience designing features on it, I dove in and began exploring different color combinations. I paid particularly close attention to contrast between colors to ensure we had ample combinations to use that met or exceeded level AA compliance with the WCAG.</p>
                                <DesignSystemHCPImages imageGroup='design1' />
                                <p className='type-body margin-b-4 margin-t-4'>From there I explored typography. The web platform used a combination of Roboto and Open Sans, Android used Roboto, and our iOS system used SF Pro. Sizes across the platforms varied as did line height and weight. To find common ground, I lined all of the styles up and began exploring different sizes, line heights, and weights until I was happy with the system. I needed to test it though. To accomplish this, I grabbed screenshots from the Android and iOS applications and design frames for the web application and began plugging the new styles in to see how they felt and gauge their impact on existing designs. A few tweaks later and the typography set was ready to move forward. By this time, the team had grown to include an Android and an iOS engineer so I shared these new styles with them to ensure they could be implemented. After they did a bit of proof of concept work, we were ready to review these new styles with leadership and move them forward!</p>
                                <DesignSystemHCPImages imageGroup='design2' />
                                <p className='type-body margin-b-4 margin-t-4'>After I had reviewed and gotten approval on the new color palette and typography, those elements moved into development with all three platforms. At this point, I began exploring token systems for our new design system and assigned each color and typography style a core token value.</p>
                                <DesignSystemHCPImages imageGroup='design3' />
                                <p className='type-body margin-b-3 margin-t-4'>We then moved on to designing the next set of components from our plan. I chose which components I would focus on and assigned others to the part-time designer on the team. Our engineers moved faster than expected though and quickly ran out of work. To increase the throughput of the design work, I enlisted three additional designers from the department to fast track this next set of components. This included buttons, text inputs, checkboxes, switches, and radio buttons. I assigned one or two components to each designer and facilitated design reviews with them every few days to guide their work and ensure we were creating a cohesive system.</p>
                                <p className='type-body margin-b-4'>Once these components were at a point that we could move them forward, I refined them a bit and then redlined them for my engineers. I then walked my team through these redlines and discussed the ins and outs of each component during grooming. This allowed us to define tickets and point them ahead of each sprint.</p>
                                <DesignSystemHCPImages imageGroup='design4' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA">
                                <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                                <p className='type-body margin-b-3'>As we progressed with building the new design system, the other designer on the team and I worked with our team of engineers to ensure each component was implemented to design specifications. For my front-end engineer, this meant pulling code from Github to evaluate their implementation of components in a local instance of Storybook.</p>
                                <p className='type-body margin-b-3'>For Android and iOS though we had to be a bit more crafty. Storybook does not readily support displaying Android or iOS code out of the box. Instead, we initially used a tool called Appetize to surface their work in Storybook alongside the web components. The challenge here was that Appetize is a SaaS platform based on usage. And, since we were using the free tier, we often ran out of usage time after only a few days each period. The engineers were already building a version of Storybook screens in front-end preview apps though so we charted a new course for design reviews. Instead of them building these screens to show the components in Storybook via Appetize, they helped set myself and the other designer on the team up with Android and iPhone emulators. Then, each time they were ready for a design review, they would send us fresh builds of these preview apps that we would install on the emulators.</p>
                                <p className='type-body margin-b-4'>With this figured out, the team and I continued working on the new system. Each time an engineer completed work on a story, they would move it into a special column on our Jira board for design review and assign the ticket to me. I would then keep or reassign the ticket to the other designer on the team based on bandwidth and familiarity with the component the ticket related to. From there we would test the component thoroughly, annotate any defects, and then post our results to the ticket in the comments section before moving it back to the In Progress status and reassigning it to the appropriate engineer. If an engineer had any questions as they were working on a ticket, before or after a design review, I encouraged them to ping me or the other designer on the team and hop on a Zoom call to discuss. This would cycle as many times as necessary until the component matched the approved design.</p>
                                <DesignSystemHCPImages imageGroup='dqa1' />
                                <p className='type-body margin-b-3 margin-t-4'>This process was incredibly effective with my iOS and web engineers. My Android engineer struggled a bit though. Some tickets did not get closed for multiple sprints because they were not meeting the approved design. This really took a toll on our team. Android began lagging behind web and iOS and eventually put a drain on designer time when we needed to be moving forward with working on new components to stay ahead of iOS and web. To handle this, I took over all Android design reviews and implemented a slightly different design review process for them. Instead of just posting comments to the ticket and waiting for the engineer to reach out with questions, I would set up a thirty minute call with the engineer after each review and walk through the feedback. Often these calls turned into paired programming sessions with me guiding the engineer through each change to make. This proved effective though and we began seeing real progress on the Android system.</p>
                                <p className='type-body margin-b-3'>After each sprint we would do our team evaluation and retrospective and from that we would agree adjustments to our process. One such adjustment was in how we broke down tickets. The pain we were feeling was in the time it took to complete the initial design reviews. This often had to be completed when a designer had large swaths of available time which was difficult to come by. To reduce this review time, we began breaking down the components into more granular requirements in order to create smaller tickets. With this adjustment we drastically reduced the amount of time it took a designer to complete an initial design review of a ticket and allowed the engineers to iterate much more quickly.</p>
                                <p className='type-body margin-b-4'>On a personal note, during a few of our sprint retrospectives I was personally thanked by the team for the thoughtful designs I was putting forward, the care and attention I put into design reviews, and the positive attitude I approached each hurdle with.</p>
                                <DesignSystemHCPImages imageGroup='dqa2' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Building Components & Documentation">
                                <h2 className='type-heading-2 margin-b-2'>Building Components & Documentation</h2>
                                <p className='type-body margin-b-3'>With the design of the components set and development of them underway, it was time to turn these individual components into an actual design system. Remember, a design system is the set of reusable components and clear guidelines that can be used to design and build applications at scale. Now it was time to put these components into a library for reuse and define their guidelines.</p>
                                <p className='type-body margin-b-4'>Because we had designed the system to look and feel the same across the web, Android, and iOS platforms, we consolidated the number of libraries a designer would need to build their designs. Paying particular attention to the feedback we had received from our surveys, I also flattened the structure of the libraries to ensure designers could quickly and easily find and implement a component in their designs. Additionally, I used newer Figma features such as boolean, text, and instance swap properties to build the components and ensure they were flexible enough to meet even the most complex use cases.</p>
                                <DesignSystemHCPImages imageGroup='building1' />
                                <p className='type-body margin-b-3 margin-t-4'>For the system’s guidelines, I advocated for our documentation to support design and development in one place creating a single source of truth for both departments. My team was onboard with this, as was leadership, but the implementation proved to be the biggest challenge. We began by evaluating different tools we could use to document our new design system quickly narrowing the list to two: zeroheight and Supernova. Both offered a wealth of features but we liked the Storybook integration experience of Supernova better and began working on a business case for purchasing a license to that platform.</p>
                                <p className='type-body margin-b-4'>In the meantime, we didn’t want to wait to create our documentation as this was one of the biggest complaints of the existing system that our designers and engineers had raised. So, we turned to a tool we already had access to to begin drafting documentation: Confluence. Each component was given its own page and organized based on its function in the left nav. The content of each page generally consisted of an introduction to the component, details on the design and use of the component, and implementation guidelines for engineering. Reviewing this with other designers and engineers proved how much of an improvement this would be as they all expressed a desire to begin using it as soon as possible.</p>
                                <DesignSystemHCPImages imageGroup='building2' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Launch">
                                <h2 className='type-heading-2 margin-b-2'>Launch</h2>
                                <p className='type-body margin-b-3'>Just in time to check off our quarterly OKRs, we launched our first page template, and all of the atoms, molecules, and organisms that made it up, into beta! This was a monumental occasion as it was the first time the Housecall Pro Design System had the focus, care, and attention from a dedicated team. By pulling a dedicated group of individuals together we were able to dig in and focus on maturing the company’s design aesthetic, addressing key usability and accessibility issues, and greatly improving the design to engineering connection. Next up, the team will be finishing work on the next page template while the first one goes through beta testing.</p>
                                <p className='type-body'>Design systems are hard. Whether you are building one for a brand new application, rebuilding one for an existing experience, or maintaining an existing system, the care and attention to detail required for design system work is a special skill that not every designer has. You need to move fast in working through ideas, test and explore each idea deeply, document everything thoroughly, and work closely with engineering to ensure it is implemented correctly. Thankfully, my team and I were well equipped for the challenge and grew through the process as well!</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>
            )}
            <FeaturedWork activeProjectKey={projectinfo.key} />
        </>
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"} data-majorsectionname="SECTION TITLE">
//<h2 className='type-heading-2 margin-b-2'></h2>
//</div>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>

//<PROJECTImages imageGroup='launch1' />