import React, { useEffect, useRef, useState } from 'react';
import './../../../SASS/ProjectPages.scss';
import { observer } from 'mobx-react-lite';
import { ProjectInfo } from '../../models/ProjectInfo';
import { AllProjects } from '../../data/ProjectHighlights';
import { Helmet } from 'react-helmet';
import ProjectScrollBar from '../../ui/ProjectScrollBar';
import FeaturedWork from '../../components/FeaturedWork';
import PipelineImages from '../../data/projects/PipelineImages';
import ProjectPageInfoCard from '../../components/ProjectPageInfoCard';
import { useStore } from '../../stores/Store';

export default observer(function PipelineProjectPage(){
    const {settingsStore} = useStore();
    const {contentLevel} = settingsStore;
    const [detailedContentSectionNamesList, setDetailedContentSectionNamesList] = useState<string[]>([]);
    const [detailedContentSectionSizesList, setDetailedContentSectionSizesList] = useState<number[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        handleContentSections();
      }, []);

    const projectinfo:ProjectInfo | undefined = AllProjects.find(proj => proj.key === 'pipeline')
    let page = <></>;
    let contentSectionClasses = "small-12";

    const detailedContentSection = useRef<HTMLDivElement>(null);
    
    let detailedContentSectionNames:string[] = [];
    let detailedContentSectionSizes:number[] = [];
    
    function handleContentSections() {
        if (detailedContentSection.current !== null) {
            for (let index = 0; index < detailedContentSection.current.children.length; index++) {
                if (detailedContentSection.current.children[index].getAttribute('data-majorsectionname') != null) {
                    let holder = detailedContentSection.current.children[index].getAttribute('data-majorsectionname');
                    if (holder != null) {
                        detailedContentSectionNames.push(holder);
                        detailedContentSectionSizes.push(detailedContentSection.current.children[index].clientHeight);
                    }
                }
            }
            if (detailedContentSectionNames.length > 0) {
                setDetailedContentSectionNamesList(detailedContentSectionNames);
                setDetailedContentSectionSizesList(detailedContentSectionSizes);
            }
        }
    }

    if (projectinfo !== undefined) {
        if (contentLevel === 'summary') {
            page = 
            <>
                <Helmet>
                    <title>{projectinfo.title} | NMChivers Design</title>
                    <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                    <meta name="description" content={projectinfo.pageDesc} />
                    <meta name="og:description" content={projectinfo.pageDesc} />
                    <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                    <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/HCPPipeline/PipelineHero.webp" />
                </Helmet>
                <div className='grid-x-has-gutters project-page-hero'>
                    <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                        <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                    </div>
                    <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                        <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                        <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                    </div>
                    <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                        <ProjectPageInfoCard project={projectinfo} />
                    </div>
                </div>

                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-10 large-10 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Housecall Pro is a technology solution for small to mid-size home service companies. The home services industry includes a variety of company types such as HVAC, plumbing, and even carpet cleaners. Within the Housecall Pro web and mobile apps technicians can create estimates for work they are hoping to win from their customers, turn those estimates into jobs in the system once that work was approved, and then collect payment on invoices they digitally send to their customers. However, there was not a user friendly way to see all of a their estimates, jobs, or invoices or where they were in the process of being completed. This meant a user would have to look at each individual estimate, job, or invoice to understand where it was in its life cycle and what needed to happen next. For most users, this wasn't getting done and a lot potential revenue was getting missed because estimates weren't being followed up on. To solve this, we set out to create a Kanban-style workflow experience that we named Pipeline. It would give the user the ability to see all of their work, understand each item's status, and take action on them from one central view.</p>
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Summary">
                                <h2 className='type-heading-2 margin-b-2'>Summary</h2>
                                <ul className='summary-list type-body'>
                                    <li>When I started at Housecall Pro, the team was running a proof of concept pilot of a simple Kanban experience for estimates only.</li>
                                    <li>As I ramped up on the project I dove into research mode to learn about the platform at large, the various industries Housecall Pro served, and what the current pilot experience was like.</li>
                                    <li>I did a competitive analysis to review the other tools user’s were trying to use to solve the problem such as Trello, Chirpp, and Monday.com.</li>
                                    <li>I developed a full, end-to-end workflow that spanned the lifecycles of estimate, jobs, and invoices to understand the simplest use case and where complexity most often gets introduced.</li>
                                    <li>I created a variety of wireframes exploring how a multi-board workflow experience could be embedded into the Housecall Pro platform.</li>
                                    <li>I ran preference and time-study tests on clickable prototypes to guide design decisions on navigation within the feature. This led to a new standard for secondary navigation across the platform.</li>
                                    <li>I conducted usability tests to validate aspects of the design such as information architecture on the Kanban cards.</li>
                                    <li>I solved key UX problems through exploration and validation that unlocked necessary features such as the ability to drag and drop cards across the boards.</li>
                                    <li>I created an aesthetically pleasing and highly usable visual design for the Pipeline that iterated on current styles to fit into the platform but still feel fresh, new, and modern.</li>
                                    <li>I developed a feature level design system full of reusable components such as the cards on the boards, the quick action dialogs, and even the board’s columns themselves. Many components were later extrapolated for use in other experiences in the platform.</li>
                                    <li>I facilitated moderated usability testing with users on the UX and UI design of the Pipeline experience. The outcome validated my design decisions with more than one user responding “Love it!” upon seeing the very first screen in the prototype.</li>
                                    <li>I conducted design QA on all front-end tickets each sprint to ensure our end product in code matched my designs.</li>
                                    <li>Pipeline was launched to general availability as an add-on to any paid plan level on Sept 22, 2022.</li>
                                    <li>Within 6 months, the annual recurring revenue from the Pipeline add-on alone reached over $1.9 million!</li>
                                </ul>
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Exploration & Research">
                                <h2 className='type-heading-2 margin-b-3'>Exploration & Research</h2>
                                <PipelineImages imageGroup='allExploration' />
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframing">
                                <h2 className='type-heading-2 margin-b-2'>Wireframing</h2>
                                <PipelineImages imageGroup='allWireframe' />
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <PipelineImages imageGroup='allvisd' />
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Usability Testing">
                                <h2 className='type-heading-2 margin-b-2'>Usability Testing</h2>
                                <PipelineImages imageGroup='allusability' />
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA">
                                <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                                <PipelineImages imageGroup='alldesqa' />
                            </div>

                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Launch & Response">
                                <h2 className='type-heading-2 margin-b-2'>Launch & Response</h2>
                                <PipelineImages imageGroup='alllaunch' />
                            </div>
                        </div>
                    </div>
                </div>
                <FeaturedWork activeProjectKey={projectinfo.key} />
            </>
        } else {
            page = 
            <>
                <Helmet>
                    <title>{projectinfo.title} | NMChivers Design</title>
                    <meta name="og:title" content={projectinfo.title + " | NMChivers Design"} />
                    <meta name="description" content={projectinfo.pageDesc} />
                    <meta name="og:description" content={projectinfo.pageDesc} />
                    <meta name="og:url" content={"https://nmchivers.design" + projectinfo.pageUrl} />
                    <meta name="og:image" content="https://nmchivers.design/IMGS/Projects/HCPPipeline/PipelineHero.webp" />
                </Helmet>
                <div className='grid-x-has-gutters project-page-hero'>
                    <div className='small-12 medium-10 medium-offset-left-1 medium-offset-right-1' >
                        <h1 className='type-heading-1 page-title'>{projectinfo.title}</h1>
                    </div>
                    <div className='small-12 medium-7 large-6 medium-offset-left-1'>
                        <p className='type-subhead-2 page-subtitle'>{projectinfo.blurb}</p>
                        <img className='hero-img-large' src={"./." + projectinfo.heroImg.imgLarge} alt={projectinfo.heroImg.imgAltText} />
                    </div>
                    <div className='small-12 medium-10 large-4 medium-offset-left-1 medium-offset-right-1 large-offset-left-0 large-offset-right-1'>
                        <ProjectPageInfoCard project={projectinfo} />
                    </div>
                </div>

                <div className='grid-x-has-gutters'>
                    <div className='small-12 medium-9 large-8 medium-offset-left-1'>
                        <div className='grid-x-has-gutters project-page-content' ref={detailedContentSection}>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Background">
                                <h2 className='type-heading-2 margin-b-2'>Background</h2>
                                <p className='type-body'>Housecall Pro is a technology solution for small to mid-size home service companies. The home services industry includes a variety of company types such as HVAC, plumbing, and even carpet cleaners. Within the Housecall Pro web and mobile apps technicians can create estimates for work they are hoping to win from their customers, turn those estimates into jobs in the system once that work was approved, and then collect payment on invoices they digitally send to their customers. However, there was not a user friendly way to see all of a their estimates, jobs, or invoices or where they were in the process of being completed. This meant a user would have to look at each individual estimate, job, or invoice to understand where it was in its life cycle and what needed to happen next. For most users, this wasn't getting done and a lot potential revenue was getting missed because estimates weren't being followed up on. To solve this, we set out to create a Kanban-style workflow experience that we named Pipeline. It would give the user the ability to see all of their work, understand each item's status, and take action on them from one central view.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Challenge">
                                <h2 className='type-heading-2 margin-b-2'>Challenge</h2>
                                <p className='type-body margin-b-3'>When I started at Housecall Pro, the team was running an alpha phase pilot of a simple kanban experience for estimates only. In the world of home service technicians, many start their engagements with their customers by creating an estimate. They may book an appointment, visit the home, create an estimate, send it to the customer, and work on revisions with the goal being to get the estimate approved and converted into a booked job. For these straightforward, simple flows, the current experience met the requirements.</p>
                                <p className='type-body'>However, the estimate process changes from industry to industry and even from company to company. Some home service technicians such as home organizers don’t even worry about creating an estimate for new customers and jump right into creating jobs on the schedule. Additionally, many Housecall Pro users were leveraging other applications such as Monday.com, Chirpp, and Trello because they needed more features such as the ability to track how many times they followed up on an estimate. The current experience couldn’t support that, wasn’t designed for flexibility, and didn’t provide a way to track and manage jobs or invoices in a similar way. These shortcomings were supported by the analytics as well showing minimal page views by the companies participating in the alpha pilot.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Exploration & Research">
                                <h2 className='type-heading-2 margin-b-2'>Exploration & Research</h2>
                                <p className='type-body margin-b-4'>As I was ramping up on the project, my predecessor on the team was finishing up an improvement to the design of the board which mainly consisted of adjusting where the metrics section lived on the page.</p>
                                <PipelineImages imageGroup='exploration1' />
                                <p className='type-body margin-b-4 margin-t-4'>As they were working with the engineers to implement these changes, I took the opportunity to explore the current experience, review the documentation that had been created, and begin exploring competitor’s experiences. My boss provided me with which of our direct competitors had similar experiences to what we were building. That isn’t where I stopped though. I explored beyond this list and included more functionally competitive experiences such as Monday.com and Trello.</p>
                                <PipelineImages imageGroup='exploration2' />
                                <p className='type-body margin-b-3  margin-t-4'>As I was exploring and learning the ins and outs of the experience and comparing it to our direct and indirect competitors, I noticed a flaw in the current experience; you couldn’t move a card between the columns. The design and experience practically screamed for the user to interact with it in a drag and drop manner but you couldn’t. The exception was the check and close buttons on the card. Clicking the check button would move it to the Accepted column while clicking the close would remove it from the board completely. I investigated why this was the route they chose to go in from an interaction design perspective and learned that they needed to collect information or perform an action for the estimate to move to any of the statuses. For example, the estimate only moves into the scheduled status if the user assigns it to themselves or another user and sets the date and time of the appointment.</p>
                                <p className='type-body margin-b-4'>Because I was new to the home services industry, I sought out pre-existing materials and found a workflow that had been previously created for this project that attempted to make sense of the steps in an estimate’s lifecycle. As I talked to other designers and product managers, I realized this workflow was just scratching the surface. It only focused on the actions a user was taking in the system for the estimate and even at that, it was incomplete. It didn’t include the revision cycle on an estimate or the phone calls to the customer to follow up on an estimate, and it didn’t provide any information on the job’s lifecycle at all. I quickly determined we could add much more value by more fully understanding the needs of our users as well as their experience interacting with the application, so I set about learning the ins and outs of the estimate to job to invoice process and created my own end to end workflow.</p>
                                <PipelineImages imageGroup='exploration3' />
                                <p className='type-body margin-t-4'>Going through that process, I discovered that  we needed to go beyond estimates and support estimates, jobs, and invoices. In addition, we needed to enable interactivity from start to finish on this experience. This would bring us in line with other tools in the market. Moreover, to compete with the likes of Monday.com and Trello, we needed to improve the visual design of the experience. Something that felt modern, was grounded in Housecall’s design language, and would clearly convey the interactivity we needed in the feature.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Wireframing">
                                <h2 className='type-heading-2 margin-b-2'>Wireframing</h2>
                                <p className='type-body margin-b-3'>Moving into wireframing, I began reworking the experience from the wireframing stage. This almost seems like heresy to most designers. Here is a fully designed experience in alpha testing and I am stripping it back to wireframing in generic, non-brand colors and chalkboard font. Thankfully, my boss agreed with my approach and it only took minimal convincing to get my product manager and product director to understand stepping back was the best move at this point.</p>
                                <p className='type-body margin-b-4'>I quickly realized as I was wireframing that one of the first challenges we needed to solve was how to support different boards for the different object types (estimates, jobs, and invoices) without overrunning the primary navigation. Working with the product team to understand the long-term strategy for this feature showed me future requirements such as the ability to add columns, create automations, and even support a new object type called leads would also impact navigation within this feature. To support the current needs and the known future requirements, I set out wireframing various in-feature navigation options to use in collecting feedback.</p>
                                <PipelineImages imageGroup='wireframe1' />
                                <p className='type-body margin-b-3  margin-t-4'>You might notice that I explored these patterns at a larger breakpoint as well as an extra small breakpoint. I design web experiences to be fully responsive from the very beginning. This time it really helped avoid future usability issues. As I showed these explorations to my team, future use cases were raised that increased how scalable the navigation would be. At one point the idea of having multiple pipelines with multiple boards within each was raised which made my explorations with tabs on the page above the board less feasible.</p>
                                <p className='type-body margin-b-3'>Collaborating with my boss and product partners, I narrowed the options down to two. The experience leveraged a secondary navigation list on the left side of the screen at medium and larger breakpoints but at small and x-small breakpoints the options diverged. One collapsed the secondary nav’s options into the primary nav while the other consolidated them into a drop down on the page. Within the web experience, there were other instances of this left-side secondary navigation but none of them were fully responsive. Instead, they either minimized the screen real estate the page’s contents could use or forced the user to scroll horizontally to view content. This upped the stakes of this design because it became apparent that whichever direction I went, others would follow.</p>
                                <p className='type-body margin-b-3'>To make it more interesting, I preferred one option while some stakeholders preferred the other and we reached something of a standstill. To help make the decision on which path to follow, I created a preference test and engaged our alpha pilot partners to participate. I used Maze.co and created two versions of the test. One version had my preferred option going first and the other had it going last to counter any first time user errors with the system and any initial impression bias the user may encounter.</p>
                                <p className='type-body margin-b-4'>In this version of the test, the primary nav consolidation approach was option A and the on screen drop down approach was option B. In both tasks, I used a Figma prototype embedded in the Maze.co test and asked the user to first navigate to the leads board and then to the jobs board.</p>
                                <PipelineImages imageGroup='wireframe2' />
                                <p className='type-body margin-b-4 margin-t-4'>Of the 11 participants that completed the test, 6 preferred the on screen drop down experience and fewer participants gave up on the flow during the test. What really sealed the deal for this approach though was the average time to complete the tasks I gave them. Initially, the on screen drop down experience lost this race with an average of about 64 seconds to complete versus the other options, which clocked in at 41 seconds. However, one tester in group 2 spent over five minutes on the last step of the prototype creating a clear outlier. Removing them from the calculation showed the on screen drop down had a clear benefit over the primary navigation with an adjusted average completion time of only 23 seconds.</p>
                                <PipelineImages imageGroup='wireframe3' />
                                <p className='type-body margin-b-3 margin-t-4'>At this point, I secured agreement with the team that we would move forward with consolidating the secondary navigation at small and x-small breakpoints into the page in a drop down. With my project’s needs met, I also wrote up a proposal to make this the pattern used universally across the application. I reviewed this proposal with other senior designers and the department’s director, ultimately getting it approved to move forward as a global pattern.</p>
                                <p className='type-body margin-b-4'>I also took care in this stage of the design process to determine the right amount and level of information to provide on the cards that would represent the estimates, jobs, and eventually leads on the boards. To determine this, I worked closely with the product manager and lead engineer to understand what data points existed for each entity type. From this main list, I selected a subset of data points based on what I had learned thus far about the users and what I had seen in competitor experiences. The order of these elements didn’t jump out to me right away though and because I was still very new to the organization and home services industry, I felt I needed some solid input to guide this decision.  I therefore created a quick preference test using Maze.co and distributed it to other members of the product org that worked specifically on the estimates and jobs features as well as some customer success contacts I had made.</p>
                                <PipelineImages imageGroup='wireframe4' />
                                <p className='type-body margin-b-3 margin-t-4'>Their input helped me refine the card design for the entities I knew our feature would support and allowed me to create a scalable pattern that could be applied to any future entities we wanted to support.</p>
                                <p className='type-body margin-b-4'>The next UX hurdle to clear was to enable drag and drop. The reason this functionality was not live on the current experience was because each status update required some action to be taken. For example, to move an estimate from Unscheduled to Scheduled, the user had to assign a tech to it and set the time and date for the estimate to be done. To enable these actions to be taken quickly from the Pipeline experience without having to navigate away to another screen, I created a set of dialogs that could be popped up whenever they were needed to facilitate the actions required to progress an estimate, job, or invoice through its lifecycle. This series of dialogs affectionately became known as our Quick Actions.</p>
                                <PipelineImages imageGroup='wireframe5' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Visual Design">
                                <h2 className='type-heading-2 margin-b-2'>Visual Design</h2>
                                <p className='type-body margin-b-4'>With many of the root user experience challenges ironed out, I worked on refining the visual design of the experience. The original design of the estimates board was actually being adjusted when I first started on the project. In this revision, the metrics section was moved over to the left side of the page and given more space. The thought being that the metrics were really important to the user so they needed to be left aligned to catch the user’s initial scan of the page. Additionally, the page title was being pulled out of the banner style because we were in the process of sunsetting that pattern across the application.</p>
                                <PipelineImages imageGroup='visd1' />
                                <p className='type-body margin-b-4 margin-t-4'>With those changes implemented, I approached refining the visual design of the experience with a very skeptical eye. For example, I did not accept that the metrics needed such a large amount of space or significant placement on the screen because we had no evidence that the metrics were paramount to the users’ ability to use the board, that they provided the right information to the users’ to make decisions, or whether users’ even cared about these metrics in the context of this experience at all. So, I went about looking for visual inspiration.</p>
                                <PipelineImages imageGroup='visd2' />
                                <p className='type-body margin-b-4 margin-t-4'>Next, I iterated over various design approaches focusing specifically on the board design.</p>
                                <PipelineImages imageGroup='visd3' />
                                <p className='type-body margin-b-4 margin-t-4'>I wanted to add the drag and drop interaction pattern into the experience which meant we would need clearly defined columns to help the user better understand where they were dropping a card they had dragged. So, as I iterated, I refined my designs down to simple gray columns that would allow the cards to stand out but give the user a clear sense of organization and interactivity.</p>
                                <PipelineImages imageGroup='visd4' />
                                <p className='type-body margin-b-4 margin-t-4'>I took this design and further flushed it out to account for the various breakpoints and scroll positions. I also designed a pattern to give the user a clear indicator for dragging and dropping cards in the experience by creating drop zones which would appear when a card was in the dragging state.</p>
                                <PipelineImages imageGroup='visd5' />
                                <p className='type-body margin-b-4 margin-t-4'>As the design of this experience took on increasing complexity, I created a design system for this feature which included configurable boards, cards, quick action dialogs, rules for use, etc to make it fast and easy to mock up different scenarios as the engineering team worked on building out the experience. This also came in handy when other designers wanted to leverage my dialogs and cards in their experiences or when I needed to create prototypes to present to our C-suite, our customer success teams, and even for an all-hands meeting.</p>
                                <PipelineImages imageGroup='visd6' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Usability Testing">
                                <h2 className='type-heading-2 margin-b-2'>Usability Testing</h2>
                                <p className='type-body margin-b-3'>At this point, we were ready to get some feedback from our users on our new Pipeline experience! Working with a new product manager that had been hired to take over our squad, we set up live, moderated usability tests with users from our alpha-stage pilot.</p>
                                <p className='type-body margin-b-3'>Over the course of a week we interviewed 9 users. We started by talking about the current alpha experience they had access to and asked a few baseline questions around how it was going, what were their overall impressions of it, what did they like the most about it, and how it could be improved. Most of the users felt favorably towards the estimates board they were testing but that it lacked functionality compared to tools like Monday.com or Tello which some were already using.</p>
                                <p className='type-body margin-b-3'>We then transitioned into looking at the new experience and every single user we interviewed felt it was a dramatic improvement on first sight. My very first instruction to each user was to take a moment and look over the new experience to see where things have moved, what had changed, and reorient themselves with this new look and feel.</p>
                                <p className='type-body margin-b-4'>“Love it!” more than one user said upon seeing the very first screen!</p>
                                <PipelineImages imageGroup='usability1' />
                                <p className='type-body margin-b-3 margin-t-4'>As we walked through the experience, I gave them real world scenarios and asked them to accomplish certain tasks, such as changing the status of an estimate, by instructing me on what to do with the UI. I followed up each task by asking about their experience vs expectations and how it felt as we interacted with the new Pipeline experience together. Their feedback validated a number of design decisions and helped my product manager better understand what would be necessary feature-wise for a go-live decision. In fact, the biggest change that came from these usability studies was not to the design but to the scope of our minimally viable product for launch. Session after session proved the design resonated with users, the interaction patterns were familiar and easy to understand but they needed additional functionality than we originally scoped.</p>
                                <p className='type-body'>With this information, I worked on designing that additional functionality while the rest of the team continued building out the new Pipeline experience.</p>
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Design QA" >
                                <h2 className='type-heading-2 margin-b-2'>Design QA</h2>
                                <p className='type-body margin-b-4'>While we tackled building the Pipeline experience sprint by sprint, I worked closely with my engineering team to review each front-end ticket. Once the ticket had been worked, the engineer would move it into our “In Review” status in Jira. This would alert me and my Product Manager that it was time for our reviews. I would focus on the design implementation while my PM would focus on the functionality. I would use different browsers on different devices to ensure the experience looked as intended across various scenarios and when I found defects, I would itemize them and pass the ticket back to the engineer for them to correct. This process worked very well and is one I typically establish early on with a new team.</p>
                                <PipelineImages imageGroup='desqa1' />
                            </div>
                            <div className={contentSectionClasses + " major-content-section"} data-majorsectionname="Launch & Response" >
                                <h2 className='type-heading-2 margin-b-2'>Launch & Response</h2>
                                <p className='type-body margin-b-4'>With the addition of a few more features such as the Quick View Dialogs and Automations, the new Pipeline experience was launched to general availability as an add-on to any plan level on Sept 22, 2022. The following month, Pipeline was included in a larger marketing push which included the launch of an integrated voice over IP solution, a new customer intake form, and the new Leads entity which were features designed by my direct reports.</p>
                                <PipelineImages imageGroup='launch1' />
                                <p className='type-body margin-t-4'>Within a few months of launching, the pipeline feature saw such tremendous adoption by our users that its financial forecast had to be revised up twice! Within 6 months, the annual recurring revenue from the Pipeline add-on alone reached over $1.9 million!</p>
                            </div>
                        </div>
                    </div>
                    <div className='hide-on-small medium-2 large-2 project-scroll-bar-sticky-container'>
                        <ProjectScrollBar names={detailedContentSectionNamesList} sizes={detailedContentSectionSizesList} projectColor={projectinfo.color} />
                    </div>
                </div>

                <FeaturedWork activeProjectKey={projectinfo.key} />
            </>
        }
    }
    return(page)
})

//<div className={contentSectionClasses + " major-content-section"}></div>
//<h2 className='type-heading-2 margin-b-2'></h2>
//<p className='type-body margin-b-3'></p>
//<p className='type-body margin-b-3 margin-t-4'></p>

//<p className='type-body margin-b-4'></p>
//<p className='type-body margin-b-4 margin-t-4'></p>
//<p className='type-body margin-t-4'></p>

//<p className='type-body'></p>
