import React from 'react';
import { ProjectInfo } from '../models/ProjectInfo';
import Card from '../ui/Card';
import NLink from '../ui/NLink';

interface Props{
    project: ProjectInfo;
}

export default function ProjectPageInfoCard({project}: Props) {
    return(
        <div className='project-page-info-card-container'>
            <img src={'./.' + project.heroImg.imgThumb} alt={project.heroImg.imgAltText} />
            <Card color={project.color}>
                <div className='project-page-info-card-content'>
                    {(project.product != "") ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>Product:</p>
                            <p className='data-value type-body'>{project.product}</p>
                        </div>
                    ):(<></>)}
                    {(project.company != "") ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>Company:</p>
                            <p className='data-value type-body'>{project.company}</p>
                        </div>
                    ):(<></>)}
                    {(project.externalURL != "" && project.externalURL != undefined) ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>Link:</p>
                            <NLink to={project.externalURL ?? ''} content={"Go see it in action"} variant='external' />
                        </div>
                    ):(<></>)}
                    {(project.category != "") ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>Category:</p>
                            <p className='data-value type-body'>{project.category}</p>
                        </div>
                    ):(<></>)}
                    {(project.myRoles != "") ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>My Role(s):</p>
                            <p className='data-value type-body'>{project.myRoles}</p>
                        </div>
                    ):(<></>)}
                    {(project.techniques != "") ? (
                        <div className='data-row'>
                            <p className='data-label type-body'>Techniques:</p>
                            <p className='data-value type-body'>{project.techniques}</p>
                        </div>
                    ):(<></>)}
                </div>
            </Card>
        </div>
    )
}